import { useEffect, useState } from 'react';
import birdImage from 'images/photos/bird-with-confetti.svg';
import { setCookie, getCookie } from 'common/utils/utils';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants/index';
import { StyledCongratsPopup } from './style';
import useTexts from './useText';

export const CongratsPopup = () => {
    const { title, textContent, textButton, link } = useTexts();
    const [showPopup, setShowPopup] = useState(false);
    const pendoTrackEvents = usePendoTrackEvents();
    const QUERY_PARAM = 'firsttime';

    useEffect(() => {
        const urlSerchParam = new URLSearchParams(window.location.search);
        let firstTimeParam = urlSerchParam.get(QUERY_PARAM);

        if (
            firstTimeParam &&
            firstTimeParam === 'true' &&
            !getCookie(QUERY_PARAM)) {
              setShowPopup(true);
            firstTimeParam = 'false';
            setCookie(QUERY_PARAM, 'false', { expires: 365 * 24 * 60 * 60 });
        }
    }, [showPopup]);

    const handleClickLink = () => {
      pendoTrackEvents(PENDO_TRACK_EVENTS.POPUP_LINK_CLIKED, {});
      setShowPopup(false);
  };

    const handleClose = () => {
        pendoTrackEvents(PENDO_TRACK_EVENTS.POPUP_BUTTON_CLICKED, {});
        setShowPopup(false);
      };

    const handleReplaceStringToElement = (formattedString, key, element) =>
      formattedString?.replace(key, element);

    const formatTextContent = () => {
      let formatText = textContent;
      formatText = handleReplaceStringToElement(formatText, '__new_line__', ' <br /> ');
      return formatText;
    };

    return (
      <>
        {showPopup && (
          <StyledCongratsPopup>
            <div className="congrats-popup-container" data-testid="congrats-popup-container">
              <div className="congrats-popup" data-testid="congrats-popup">
                <div className="congrats-popup-content">
                  <div className="text-container">
                    <h2 className="congrats-title">{title}</h2>
                    <div className="congrats-text" dangerouslySetInnerHTML={{ __html: formatTextContent() }} />
                    {' '}
                    <a className="congrats-text congrats-link" href="https://www.sysaid.com/it-service-management-software/sysaid-copilot" target="_blank" data-testid="chatbot-link" rel="noreferrer" onClick={handleClickLink}>{link}</a>
                    <div className="congrats-text">.</div>
                  </div>
                  <img src={birdImage} alt="bird with confetti" />
                </div>
                <button className="congrats-popup-btn" data-testid="congrats-popup-btn" onClick={handleClose}>{textButton}</button>
              </div>
            </div>
          </StyledCongratsPopup>
          )}
      </>
    );
};
