import { ReactComponent as Account } from 'images/icons/settings/account.svg';
import { ReactComponent as AiServiceDesk } from 'images/icons/settings/ai-service-desk.svg';
import { ReactComponent as AssetManagement } from 'images/icons/settings/asset-management.svg';
import { ReactComponent as AuditLog } from 'images/icons/settings/audit-log.svg';
import { ReactComponent as Automation } from 'images/icons/settings/automations.svg';
import { ReactComponent as Categories } from 'images/icons/settings/categories.svg';
import { ReactComponent as Integrations } from 'images/icons/settings/integrations.svg';
import { ReactComponent as Languages } from 'images/icons/settings/languages.svg';
import { ReactComponent as Monitoring } from 'images/icons/settings/monitoring.svg';
import { ReactComponent as NetworkDiscovery } from 'images/icons/settings/network-discovery.svg';
import { ReactComponent as Notifications } from 'images/icons/settings/notifications.svg';
import { ReactComponent as OperatingTimes } from 'images/icons/settings/operating-times.svg';
import { ReactComponent as PasswordServices } from 'images/icons/settings/password-services.svg';
import { ReactComponent as Reporting } from 'images/icons/settings/reporting.svg';
import { ReactComponent as SelfServicePortal } from 'images/icons/settings/self-service-portal.svg';
import { ReactComponent as ServiceDesk } from 'images/icons/settings/service-desk.svg';
import { ReactComponent as SLA } from 'images/icons/settings/SLA.svg';
import { ReactComponent as Templates } from 'images/icons/settings/templates.svg';
import { ReactComponent as Timers } from 'images/icons/settings/timers.svg';
import { ReactComponent as UserManagement } from 'images/icons/settings/user-management.svg';
import { ReactComponent as Cmdb } from 'images/icons/settings/cmdb.svg';
import { ReactComponent as Tasks } from 'images/icons/settings/tasks.svg';

export const icons = {
  91: Account,
  aIServiceDesk: AiServiceDesk,
  127: AssetManagement,
  117: AuditLog,
  62: Automation,
  59: Categories,
  120: Integrations,
  107: Languages,
  150: Monitoring,
  138: NetworkDiscovery,
  88: Notifications,
  110: OperatingTimes,
  101: PasswordServices,
  113: Reporting,
  78: SelfServicePortal,
  73: ServiceDesk,
  68: SLA,
  53: Templates,
  115: Timers,
  96: UserManagement,
  170: Cmdb,
  186: Tasks,
};
