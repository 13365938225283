import { useState, useEffect, memo } from 'react';
import MUICircularProgress from '@mui/material/CircularProgress';
import DropDownHeader from 'common/components/dropdownSelect/dropDownHeader';
import useFilteredOptions from '../context/useFilteredOptions';
import { InfoTypography } from '../InfoTypography';
import { useSelectContext } from '../context';
import { useTexts } from '../hooks';
import { VirtualizedScroll } from '../VirtualizedScroll';
import { OptionsContainer, StyledCircularWrapper } from './DropdownSelectGroup.styles';
import { CustomDate } from '../CustomDate';

export const DropdownSelectGroup = memo(
  ({
    id,
    fieldName,
    noDivider,
    isClickedAway,
    searchPlaceholder,
    autoInputFocus = true,
    handleClearValue,
    withTitle = true,
    isActionsHidden,
    isHeaderHidden,
    dropdownHeightExtension = 0,
    isGroupedHierarchy,
    isSelectAllHidden,
    columns
  }) => {
    const {
      changeHandler,
      filteredOptionsKeys,
      search,
      handleUncheckAll,
      handleSelectAll,
      clearSearch,
      isDirty,
      isRequestOnGoing,
    } = useFilteredOptions();
    const {
      selection,
      isMultiple,
      title,
      isDateType,
      additionalWrapperClassName,
      indexOffset,
      hasHierarchy,
      isWideDropdown,
    } = useSelectContext();
    const [isCustomSelectOpened, setIsCustomSelectOpened] = useState(false);
    const {
      clear: clearText,
      clearAll: clearAllText,
      selectAll: selectAllText,
      infoNoResults: noResultsText,
      infoNoValues: noValuesText,
      infoSearchPlaceholder: searchPlaceholderText,
    } = useTexts();

    useEffect(() => {
      if (isClickedAway) {
        clearSearch();
      }
    }, [clearSearch, isClickedAway]);

    const filteredOptionsLength = hasHierarchy
      ? Object.values(filteredOptionsKeys).flatMap((value) => value).length
      : filteredOptionsKeys?.length;

    if ((search === null || search === undefined || search === '') && !filteredOptionsLength) {
      return <InfoTypography message={noValuesText} />;
    }

    const toggleCustomDateSelect = () => {
      setIsCustomSelectOpened((prev) => !prev);
    };

    const clearValueHandler = () => {
      if (handleClearValue) {
        handleClearValue();
      }
    };

    const noSelection = hasHierarchy ? !selection.values.length && !selection.valueClasses.length : !selection.length;

    return (
      <>
        {!isHeaderHidden && (
          <DropDownHeader
            title={title}
            isSearchVisible={!isCustomSelectOpened}
            isDividerVisible={
              ((title !== null && title !== undefined) || handleClearValue || !withTitle) &&
              !isCustomSelectOpened &&
              !noDivider
            }
            isActionsVisible={!isActionsHidden}
            searchFieldProps={{
              value: search,
              placeholderText: searchPlaceholder || searchPlaceholderText,
              onChange: changeHandler,
              onClear: clearSearch,
              autoFocus: autoInputFocus,
            }}
            actionsProps={{
              isMultiSelect: isMultiple,
              isDirty,
              isClearHidden: isMultiple || !handleClearValue,
              isClearDisabled: noSelection,
              isSelectAllHidden,
              clearText,
              clearAllText,
              selectAllText,
              handleClearAll: handleUncheckAll,
              clearValueHandler,
              handleSelectAll,
            }}
          />
        )}
        {isRequestOnGoing ? (
          <StyledCircularWrapper>
            <MUICircularProgress size="1rem" />
          </StyledCircularWrapper>
        ) : (
          <OptionsContainer
            {...(id && { id: `${id}Options` })}
            hasHierarchy={hasHierarchy}
            hasResults={!!filteredOptionsLength}
            noDivider={noDivider}
            dropdownHeightExtension={dropdownHeightExtension}
            data-cy="options-container"
          >
            {!filteredOptionsLength ? (
              <InfoTypography message={noResultsText} />
            ) : (
              <>
                {!isCustomSelectOpened && (
                  <VirtualizedScroll
                    ids={filteredOptionsKeys}
                    isDateType={isDateType}
                    additionalWrapperClassName={additionalWrapperClassName}
                    indexOffset={indexOffset}
                    dropdownHeightExtension={dropdownHeightExtension}
                    hasHierarchy={hasHierarchy}
                    isWideDropdown={isWideDropdown}
                    isGroupedHierarchy={isGroupedHierarchy}
                    columns={columns}
                  />
                )}
                {isDateType && (
                  <CustomDate
                    handleChange={toggleCustomDateSelect}
                    isDateType={isDateType}
                    isCustomSelectOpened={isCustomSelectOpened}
                    fieldName={fieldName}
                  />
                )}
              </>
            )}
          </OptionsContainer>
        )}
      </>
    );
  },
);
