import { forwardRef, useImperativeHandle, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTicketsLockMap } from 'remote-state/ticketServiceHooks';
import Tooltip from 'common/components/tooltip';
import { Badge } from '@mui/material';
import { ReactComponent as CogWheel } from 'images/icons/cogWheel.svg';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import { BASIC_EDITION } from 'features/settings/constants';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { createPerformanceMarkWithData } from 'common/utils/performanceManager';
import { debounce } from 'lodash-es';
import { CUSTOM_FIELD_PREFIX } from 'features/srPanel/consts';
import {
  StyledCustomColumnHeader,
  StyledSortIcon,
  StyledContainer,
  StyledColumnLabel,
  StyledCheckBox,
  StyledFilterIcon,
} from './style';
import { useStylesBootstrap } from '../customSelectBox';
import {
  selectIsQuickFilterApplied,
  selectedQueueTicket,
  removeSelectedTicket,
  selectFullSortModel,
  selectFilterModel,
  selectMultiSort,
  customSortModelChanged,
} from '../../slice';
import useTexts from './useTexts';
import { CUSTOM_COLUMNS_SORT_FILTER_LIMIT } from './consts';

const CustomColumnHeader = forwardRef((props, ref) => {
  const { column, displayName, api: gridAPI } = props;
  const enforceSortModel = useSelector(selectFullSortModel);
  const [, setOpenColumnConfiguration] = useState(false);
  const isCheckboxCol = column.colId === 'checkbox';
  const isOpenTicket = column.colId === 'openticket'; //
  const isConfigurationCol = column.colId === 'configuration';
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const dispatch = useDispatch();
  const selectedTicket = useSelector(selectedQueueTicket);
  const { getColumnText } = useTexts();
  const tooltipClass = useStylesBootstrap();
  const isQuickFilterApplied = useSelector(selectIsQuickFilterApplied);
  const translatedDisplayName = getColumnText() || displayName;
  const ticketsPerPage = gridAPI?.paginationGetPageSize();
  const indeterminate = selectedTicket?.length > 0 && selectedTicket?.length < ticketsPerPage;
  const filterList = useSelector(selectFilterModel);
  const isMultiSort = useSelector(selectMultiSort);

  const { data: ticketsLockMap } = useTicketsLockMap();

  useImperativeHandle(ref, () => ({
    // init(params) - Optional method, gets called once with params (params: IHeaderParams)
    init() {},

    // Refresh(params) - Gets called when a new Column Definition has been set for this header.
    // If you handle the refresh of your header return true otherwise return false and the grid will re-create your header from scratch.
    refresh() {
      return true;
    },

    // Gets called once by grid when the component is being removed; if your component needs to do any cleanup, do it here
    destroy() {
      //cleanup
    },
  }));

  const isColumnSorted = useMemo(
    () => enforceSortModel.find((sort) => sort.colId === column.colId),
    [enforceSortModel, column],
  );

  if (isOpenTicket) return null;

  const triggerSort = debounce(() => {
    let newSort = [];
    if (isMultiSort && Array.isArray(enforceSortModel)) {
      newSort = enforceSortModel.filter((sort) => sort.colId === column.colId);
    }
    if (isColumnSorted) {
      if (isColumnSorted.sort === 'desc') {
        newSort.push({ ...isColumnSorted, sort: 'asc' });
      }
    } else {
      newSort.push({ colId: column.colId, sort: 'desc', sortIndex: newSort.length });
    }
    dispatch(customSortModelChanged(newSort));
  }, 500);

  const handleSortClick = () => {
    createPerformanceMarkWithData(PERFORMANCE_MEASURE.QUEUE.SORT_CHANGE, { fieldName: column.colId || displayName });
    triggerSort();
  };

  const handleDeSelectAll = () => {
    dispatch(removeSelectedTicket());
    gridAPI.forEachNode((node) => {
      node.setSelected(false);
    });
  };

  const handleSelectAll = () => {
    const currentPage = gridAPI?.paginationGetCurrentPage();
    const totalItems = gridAPI?.getDisplayedRowCount();
    const startRow = currentPage * ticketsPerPage;
    const endRow = Math.min(startRow + ticketsPerPage, totalItems);
    gridAPI?.forEachNode((node, index) => {
      if (index >= startRow && index < endRow) {
        node.setSelected(true);
      }
    });
  };

  const handleCheckBoxRows = async ({ target }) => {
    let gridAPILength = 0;
    let lockedTicketsCount = 0;

    let count = 0;
    gridAPI.forEachNode((node) => {
      if (count < ticketsPerPage) {
        count += 1;
        gridAPILength += 1;
        if (node.selected === false && ticketsLockMap) {
          const row = ticketsLockMap[node.id];
          if (row && row.locked === true) {
            lockedTicketsCount += 1;
          }
        }
      }
    });

    if (
      !target.checked ||
      (indeterminate && selectedTicket.length >= ticketsPerPage) ||
      selectedTicket.length === gridAPILength ||
      selectedTicket.length === gridAPILength - lockedTicketsCount
    ) {
      handleDeSelectAll();
    } else {
      handleSelectAll();
    }
  };

  const handleMenuClicked = (element) => {
    props.showColumnMenu(element);
  };

  const handleConfigurationClick = () => {
    setOpenColumnConfiguration(true);
  };
  const getSortedColumnCount = () => {
    let count = 0;
    if (enforceSortModel?.length <= 1) return null;
    enforceSortModel?.forEach((sort) => {
      if (sort.colId === column.colId) count = sort?.sortIndex + 1;
    });
    return count;
  };
  const isCustomColumn = (column) => typeof column === 'string' && column?.startsWith(CUSTOM_FIELD_PREFIX);
  const isFilterActive = filterList && filterList.find((filterItem) => column?.colId === filterItem.field);
  const isSortActive = enforceSortModel && enforceSortModel.find((sortItem) => column?.colId === sortItem.colId);
  const customColumnsFilterSortReachedLimit = ((filterList?.filter(f => f.customColumn).length) + enforceSortModel?.filter(s => isCustomColumn(s.colId)).length) >= CUSTOM_COLUMNS_SORT_FILTER_LIMIT;
  const isSortDisabled = customColumnsFilterSortReachedLimit && isCustomColumn(column?.colId) && !isSortActive;
  const isFilterDisabled = customColumnsFilterSortReachedLimit && isCustomColumn(column?.colId) && !isFilterActive;
  const isSrTypeColumn = column?.colId === 'srType';
  const isBasicEdition = edition === BASIC_EDITION;
  const hideSyTypeFilter = isBasicEdition && isSrTypeColumn;
  const isTimersColumn = column?.colDef?.sysaidEntity === 'timers';

  const getFilterIconClassName = () =>
    clsx(
      'customHeaderMenuButton',
      'columnHeaderFilterIcon',
      isQuickFilterApplied && isSrTypeColumn && 'disableFilterIcon',
      isTimersColumn && 'hideFilterIcon',
    );

  return (
    <StyledCustomColumnHeader>
      {isCheckboxCol && (
        <StyledCheckBox
          checked={selectedTicket?.length > 0 && selectedTicket?.length >= ticketsPerPage}
          onChange={handleCheckBoxRows}
          indeterminate={indeterminate}
        />
      )}
      {isConfigurationCol && <CogWheel onClick={() => handleConfigurationClick()} />}
      {!isCheckboxCol && !isConfigurationCol && (
        <StyledContainer>
          <Tooltip
            title={translatedDisplayName}
            placement="top"
            arrow
            classes={tooltipClass}
            style={{ maxWidth: 'calc(100%-35px)' }}
            text={
              <StyledColumnLabel className="display-name" onClick={handleSortClick}>
                {translatedDisplayName}
              </StyledColumnLabel>
            }
            isTruncatedText
          />
          {!hideSyTypeFilter && (
            <>
              <Badge badgeContent={getSortedColumnCount()} invisible={!isColumnSorted} color="primary">
                <StyledSortIcon
                  isSortActive={isColumnSorted}
                  sort={isColumnSorted?.sort}
                  onClick={handleSortClick}
                  className="columnHeaderSortIcon"
                  disabled={isSortDisabled}
                />
              </Badge>
              <StyledFilterIcon
                disabled={isFilterDisabled}
                isFilterActive={isFilterActive}
                className={getFilterIconClassName()}
                onClick={handleMenuClicked}
              />
            </>
          )}
        </StyledContainer>
      )}
    </StyledCustomColumnHeader>
  );
});

export default CustomColumnHeader;
