import { MAXIMUM_ACTIVE_PANELS } from 'features/navBar/constants';
import { CONSTANTS } from './constants';

const {
  OPENED_MAX_WIDTH,
  TICKET_RIGHT_MARGIN,
  COLLAPSED_MIN_WIDTH,
  MAIN_PANEL_PADDINGS,
  DYNAMIC_OPENED_WIDTH,
  COLLAPSED_DEFAULT_WIDTH,
  MIN_COLLAPSED_TICKET_PADDINGS,
  MAX_COLLAPSED_TICKET_PADDINGS,
  COLLAPSED_TICKET_3_BTNS_WIDTH,
  COLLAPSED_TICKET_2_BTNS_WIDTH,
  COLLAPSED_TICKET_STYLE_BREAKPOINT,
} = CONSTANTS;
export const parseEmptyCategories = (srId, isTripleCategory, srObject) => {
  const updatedSrObject = { ...srObject };
  if (srId === 'new' && srObject.primaryCategory === undefined && srObject.secondaryCategory === undefined) {
    updatedSrObject.primaryCategory = -1;
    updatedSrObject.secondaryCategory = -1;
    if (isTripleCategory) {
      updatedSrObject.thirdLevelCategory = -1;
    }
  }
  return updatedSrObject;
};

export const TOTAL_COLLAPSED_MIN_WIDTH = COLLAPSED_MIN_WIDTH + TICKET_RIGHT_MARGIN;

export const getMainPanelWidth = (navBarWidth) => {
  const windowWidth = window.innerWidth;
  if (navBarWidth) {
    return windowWidth - navBarWidth - MAIN_PANEL_PADDINGS;
  }
  return windowWidth;
};

export const indexOnScreen = (index, panelsOnScreen) => index <= panelsOnScreen;

export const getAmountOfCollapsedTickets = (openedTicketPanelIndex, expandedTicketPanelIndex, totalPanels) => {
  const amountOfOpenedTickets = openedTicketPanelIndex !== -1 ? 1 : 0;
  const amountOfExpendedTickets = expandedTicketPanelIndex !== -1 ? 1 : 0;
  return totalPanels - amountOfOpenedTickets - amountOfExpendedTickets;
};

export const getOpenedPanelWidth = (openedTicketPanelIndex, navBarWidth) => {
  const dynamicOpenedWidth = Math.min(getMainPanelWidth(navBarWidth) * DYNAMIC_OPENED_WIDTH, OPENED_MAX_WIDTH);
  let openedPanelWidth = 0;
  if (openedTicketPanelIndex !== -1) {
    openedPanelWidth = dynamicOpenedWidth;
  }
  return openedPanelWidth;
};

export const getCollapsedWidth = (openedTicketPanelIndex, expandedTicketPanelIndex, totalPanels, navBarWidth) => {
  const openedPanelWidth = getOpenedPanelWidth(openedTicketPanelIndex, navBarWidth);
  const amountOfCollapsedTickets = getAmountOfCollapsedTickets(
    openedTicketPanelIndex,
    expandedTicketPanelIndex,
    totalPanels,
  );
  const remainingWidth = getMainPanelWidth(navBarWidth) - openedPanelWidth;
  return amountOfCollapsedTickets > 0 ? remainingWidth / amountOfCollapsedTickets : remainingWidth;
};

export const getWantedWidth = (openedTicketPanelIndex, expandedTicketPanelIndex, totalPanels, navBarWidth) => {
  const panelWidth = getMainPanelWidth(navBarWidth);
  const openedPanelWidth = getOpenedPanelWidth(openedTicketPanelIndex);
  const collapsedWidth = getCollapsedWidth(openedTicketPanelIndex, expandedTicketPanelIndex, totalPanels, navBarWidth);
  const amountOfCollapsedTickets = getAmountOfCollapsedTickets(
    openedTicketPanelIndex,
    expandedTicketPanelIndex,
    totalPanels,
  );
  let wantedExpandedOrOpenedWidth = Math.min(openedPanelWidth, OPENED_MAX_WIDTH);
  let wantedCollapsedWidth = Math.max(collapsedWidth, COLLAPSED_DEFAULT_WIDTH);

  if (panelWidth - openedPanelWidth - amountOfCollapsedTickets * wantedCollapsedWidth <= 0) {
    wantedExpandedOrOpenedWidth =
      wantedExpandedOrOpenedWidth && Math.min(panelWidth * DYNAMIC_OPENED_WIDTH, OPENED_MAX_WIDTH);
    wantedCollapsedWidth = Math.min(
      (panelWidth - wantedExpandedOrOpenedWidth - 8 * amountOfCollapsedTickets) / amountOfCollapsedTickets,
      COLLAPSED_DEFAULT_WIDTH,
    );
  }
  return { wantedExpandedOrOpenedWidth, wantedCollapsedWidth };
};

export const calculateRight = (
  index,
  startIndexToShow,
  openedTicketPanelIndex,
  expandedTicketPanelIndex,
  panelsOnScreen,
  totalPanels,
  navBarWidth,
) => {
  if (totalPanels === 1) return 0;

  const { wantedCollapsedWidth, wantedExpandedOrOpenedWidth } = getWantedWidth(
    openedTicketPanelIndex,
    expandedTicketPanelIndex,
    totalPanels,
    navBarWidth,
  );

  let endIndexToShow = startIndexToShow + panelsOnScreen - 1;
  if (totalPanels === endIndexToShow) {
    endIndexToShow = endIndexToShow - 1;
  }
  let right = 0;
  if (index < openedTicketPanelIndex || index < expandedTicketPanelIndex) {
    right = (endIndexToShow - index) * wantedCollapsedWidth + (wantedExpandedOrOpenedWidth - wantedCollapsedWidth);
  } else {
    right = (endIndexToShow - index) * wantedCollapsedWidth;
  }
  return right + (endIndexToShow - index) * 8;
};

export const getTitleWidth = (panelWidth) => {
  let width = panelWidth - MIN_COLLAPSED_TICKET_PADDINGS - COLLAPSED_TICKET_2_BTNS_WIDTH;
  if (panelWidth > COLLAPSED_TICKET_STYLE_BREAKPOINT) {
    width = panelWidth - MAX_COLLAPSED_TICKET_PADDINGS - COLLAPSED_TICKET_3_BTNS_WIDTH;
  }
  return width;
};

export const getVisiblePanelsOnScreenCount = (navBarWidth) => {
  const mainPanelWidth = getMainPanelWidth(navBarWidth);
  const dynamicOpenedWidth = mainPanelWidth * DYNAMIC_OPENED_WIDTH;
  const openedPanelWidth = Math.min(dynamicOpenedWidth, OPENED_MAX_WIDTH);
  const possibleVisiblePanels = Math.floor((mainPanelWidth - openedPanelWidth) / TOTAL_COLLAPSED_MIN_WIDTH) + 1;
  return Math.min(possibleVisiblePanels, MAXIMUM_ACTIVE_PANELS);
};
