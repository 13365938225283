import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import RichTextEditor from 'common/components/controls/RichTextEditor';
import useTexts from 'features/resolutionPanel/useTexts';
import { SubResourceType } from 'services/attachments';
import { useTicketAttachments, useDeleteAttachmentMutation } from 'remote-state/attachments';
import { RICH_TEXT_EDITOR_TYPES } from 'common/components/controls/RichTextEditor/constants';
import { has } from 'lodash-es';
import {
  selectActionLine,
  updateActionLine,
  clearActionLinePrompt,
  addUpload,
  removeUpload,
  updateUpload,
} from '../store/slice';
import { editorActionData, promptTypes, editorActionTypes } from '../constants';
import ActionLinePrompt from './prompts/actionLinePrompt';
import ActionLineFooter from './footer';
import { StyledActionLine, StyledDefaultActionLine } from './style';
import { useAuditLog } from '../hooks/useAuditLog';
import { parallelEditTypes } from './prompts/parallelEditTypes';
import { Quote } from './header/quote';
import { QuoteSubject } from './header/quote/QuoteSubject';
import { QuoteContent } from './header/quote/QuoteContent';
import SolutionResolution from './solutionResolution';
import Activities from './activities';

export default function ActionLine({ handleCancel = () => {}, auditLogId = -1, editorType, scrollToRecord }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const texts = useTexts();
  const {
    data,
    isExpanded,
    activeUploads,
    isEditorFullSize,
    isNewEditor,
    lastSavedData,
    activeIndex: activeEditorIndex,
    isEditorDirty,
    isEditorChangeProgress,
    prompt: { isOpen, promptType, message, parallelEditType, parallelEditParams, btnTexts },
  } = useSelector(selectActionLine);
  const [isUploadFileProgress, setIsUploadFileProgress] = useState(false);

  const { mutateAsync: deleteAttachmentApi } = useDeleteAttachmentMutation();
  const { createSrAttachment } = useTicketAttachments();
  const {
    setEditorExpanded,
    handleEditorFocus,
    handleEditorChange,
    updateTicketPageValidation,
    initializeEditorAction,
    discardEditorData,
    checkAndResetManualStatus,
  } = useEditorActions();
  const { toggleAuditLogsProperty, selectedFilterType } = useAuditLog();
  const { initializeEditorData } = useEditorActions();
  const editorActionSettings = editorActionData[editorType];
  const srId = router.latestLocation.search.id;
  const handleChange = (editorText) => {
    handleEditorChange(editorActionSettings.editorTextKey, { [editorActionSettings.editorTextKey]: editorText });
  };

  useEffect(() => {
    const activeUploadsWithoutErrors = activeUploads.filter((uploadFile) => !has(uploadFile, 'error'));

    if (activeUploadsWithoutErrors.length > 0) {
      updateTicketPageValidation({ isValid: false });
    } else {
      updateTicketPageValidation({ isValid: !isEditorDirty });
    }
  }, [activeUploads, activeUploads.length, isEditorDirty, updateTicketPageValidation]);

  const handleFocus = () => {
    handleEditorFocus({ auditLogId });
  };

  /*  TODO consider moving functionality to useEditor.js  */
  const handleFocusNotes = () => {
    if (activeEditorIndex === -1) {
      handleEditorFocus({ auditLogId });
    }
  };
  const toggleFullSize = () => {
    dispatch(updateActionLine({ isEditorFullSize: !isEditorFullSize }));
  };

  const handleFileUpload = async ({ file, onProgressChange, inline = false, visibility = true }) =>
    createSrAttachment({
      file,
      subResourceType: SubResourceType.TEMP,
      resourceId: data.id,
      onProgressChange,
      visibility,
      inline,
    });

  const handleImageUpload = async (params) => {
    const { metadata: uploadMetadata } = await handleFileUpload({ ...params, inline: true, visibility: false });
    if (uploadMetadata) {
      const { id, originalName, extension } = uploadMetadata;
      handleEditorChange('inlineImages', {
        list: 'inlineImages',
        listItem: {
          id,
          originalName,
          extension,
          subResourceType: params.subResourceType || SubResourceType.TEMP,
          temp: true,
        },
      });
    }
    return uploadMetadata?.id;
  };

  const handleFileAttach = async (params) => {
    const data = await handleFileUpload(params);
    const { metadata: uploadMetadata } = data;
    if (uploadMetadata) {
      const { id, originalName, extension } = uploadMetadata;
      handleEditorChange('attachments', {
        list: 'attachments',
        listItem: {
          id,
          originalName,
          extension,
          subResourceType: params.subResourceType || SubResourceType.TEMP,
          uploadId: params?.uploadId,
          temp: true,
        },
      });
      return uploadMetadata;
    }
  };

  const handleChangeActiveUploads = ({ type, payload, subResourceType = SubResourceType.TEMP }) => {
    switch (type) {
      case 'addUpload':
        dispatch(addUpload({ ...payload, subResourceType }));
        break;
      case 'removeUpload':
        dispatch(removeUpload({ ...payload, subResourceType }));
        break;
      case 'updateUpload':
        dispatch(updateUpload({ ...payload, subResourceType }));
        break;
      default:
        break;
    }
  };

  const handleRemoveAttachmentById = (id) => {
    const { attachments } = data;
    let updateAttachments;

    if (isNewEditor) {
      updateAttachments = attachments.filter((item) => item.id !== id);
    } else {
      updateAttachments = attachments.map((item) => {
        if (item.id === id) {
          return { ...item, deleted: true, preparedForDeletion: true };
        }
        return item;
      });
    }
    handleEditorChange('attachments', { list: 'attachments', listItem: updateAttachments, operation: 'overwrite' });
  };

  const handleEditorTypeChange = () => {
    const auditLogIdsToToggle = [];
    const actionLineProperties = {
      isExpanded: true,
    };
    const editModeProperties = {
      activeIndex: parallelEditParams?.auditLogId,
      isNewEditor: false,
      data: parallelEditParams?.data,
      lastSavedData: parallelEditParams?.lastSavedData,
    };
    switch (parallelEditType) {
      case parallelEditTypes.EDIT_TO_EDIT: {
        auditLogIdsToToggle.push(activeEditorIndex, parallelEditParams?.auditLogId);
        Object.assign(actionLineProperties, editModeProperties);
        break;
      }
      case parallelEditTypes.CREATE_TO_EDIT: {
        auditLogIdsToToggle.push(parallelEditParams?.auditLogId);
        Object.assign(actionLineProperties, editModeProperties);
        break;
      }
      case parallelEditTypes.EDIT_TO_CREATE:
      case parallelEditTypes.CREATE_TO_CREATE: {
        auditLogIdsToToggle.push(activeEditorIndex);
        actionLineProperties.activeIndex = -1;
        actionLineProperties.type = parallelEditParams?.type || editorType;
        const editorData = initializeEditorData(actionLineProperties.type);
        actionLineProperties.data = { ...editorData, ...parallelEditParams.data };
        actionLineProperties.lastSavedData = editorData;
        break;
      }
      default:
        return null;
    }

    toggleAuditLogsProperty({
      auditLogIds: auditLogIdsToToggle,
      property: 'isEdited',
    });
    dispatch(updateActionLine(actionLineProperties));
  };

  const removeTempAttachments = () => {
    const { attachments = [], inlineImages = [] } = data;
    const tempAttachments = [...attachments, ...inlineImages].filter((attachment) => attachment.temp);
    if (tempAttachments.length > 0) {
      tempAttachments.forEach((attachment) => deleteAttachmentApi({ attachmentId: attachment.metadata.fileId }));
    }
  };

  const handleClosePrompt = ({ isOkBtnClicked }) => {
    if (promptTypes.CANCEL === promptType) {
      if (isOkBtnClicked) {
        initializeEditorAction({ type: editorActionTypes.NOTE, isExpanded: false });
        updateTicketPageValidation({ isValid: true });
        removeTempAttachments();
        discardEditorData();
        if (parallelEditType !== null) {
          handleEditorTypeChange();
        } else {
          handleCancel();
        }
      } else {
        setEditorExpanded(true);
        checkAndResetManualStatus({ logType: editorType });
      }
    }

    dispatch(clearActionLinePrompt());
  };

  const handleCharCounterMax = () => {
    if (data?.isQuoted && data?.quotedRecord?.operation === 'FORWARD')
      return editorActionSettings.maxEditorCharsForward;
  };

  const isEditorTypeMessage = editorType === editorActionTypes.MESSAGE;

  return (
    <StyledActionLine isExpanded={isExpanded} editorType={editorType} className="action-line">
      {auditLogId === activeEditorIndex ? (
        <>
          {data?.isQuoted && data?.quotedRecord?.operation === 'REPLY' && (
            <Quote
              subject={<QuoteSubject subject={data?.quotedRecord?.subject} />}
              content={<QuoteContent content={data?.quotedRecord?.text} />}
              type={data?.quotedRecord?.type}
              scrollToRecord={() => scrollToRecord(data?.quotedRecord?.auditLogId)}
              isSavedQuote={false}
            />
          )}
          {editorType === editorActionTypes.RESOLUTION && (
            <SolutionResolution
              data={data}
              texts={texts}
              isNewEditor={isNewEditor}
              lastSavedData={lastSavedData}
              attachmentsList={data.attachments}
              handleEditorChange={handleEditorChange}
              isEditorDirty={isEditorDirty}
              onFileAttach={handleFileAttach}
              onImageUpload={handleImageUpload}
              activeUploads={activeUploads}
              onChangeActiveUploads={handleChangeActiveUploads}
              onAttachmentDeleted={handleRemoveAttachmentById}
              shouldTriggerDeleteApi={isNewEditor}
              editorActionSettings={editorActionSettings}
            />
          )}
          {editorType === editorActionTypes.ACTIVITY && (
            <Activities data={data} onChange={handleEditorChange} />
          )}
          {(editorType === editorActionTypes.NOTE || editorType === editorActionTypes.MESSAGE) && (
            <RichTextEditor
              fieldName={editorType}
              key={`${editorType}-${selectedFilterType}`}
              sourceId={srId}
              isFocusedByDefault={!isEditorTypeMessage}
              editorText={data[editorActionSettings.editorTextKey]}
              attachmentsList={data.attachments}
              placeholder={texts[editorActionSettings.placeholder]}
              CustomFooter={<ActionLineFooter />}
              onChange={handleChange}
              onFocus={handleFocusNotes}
              isExpanded={isExpanded}
              handleExpanded={setEditorExpanded}
              isFullSize={isEditorFullSize}
              isEditorDirty={isEditorDirty}
              isUploadFileProgress={isUploadFileProgress}
              toggleFullSize={toggleFullSize}
              onFileAttach={handleFileAttach}
              onImageUpload={handleImageUpload}
              activeUploads={activeUploads}
              onChangeActiveUploads={handleChangeActiveUploads}
              onAttachmentDeleted={handleRemoveAttachmentById}
              onChangeUploadFileProgress={setIsUploadFileProgress}
              shouldTriggerDeleteApi={isNewEditor}
              editorType={editorType}
              isEditorChangeProgress={isEditorChangeProgress}
              type={
                isEditorTypeMessage ? RICH_TEXT_EDITOR_TYPES.EMAIL : RICH_TEXT_EDITOR_TYPES.TEXT
              }
              charCounterMax={handleCharCounterMax()}
              dataTestId="note-editor"
            />
          )}
          {isOpen && (
            <ActionLinePrompt
              promptType={promptType}
              onOkClick={() => handleClosePrompt({ isOkBtnClicked: true })}
              onCancelClick={() => handleClosePrompt({ isOkBtnClicked: false })}
              message={message}
              btnTexts={btnTexts}
            />
          )}
        </>
      ) : (
        <StyledDefaultActionLine onClick={handleFocus}>{texts.richTextEditorPlaceholder}</StyledDefaultActionLine>
      )}
    </StyledActionLine>
  );
}
