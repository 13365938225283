import { useState, useEffect, useRef, useCallback, memo } from 'react';
import { ClickAwayListener } from '@mui/material';
import { useDispatch } from 'react-redux';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { RICH_TEXT_EDITOR_TYPES } from 'common/components/controls/RichTextEditor/constants';
import RichTextEditor from 'common/components/controls/RichTextEditor';
import {
  // DEFAULT_RICH_TEXT_VALUE,
  useRichTextEditor,
} from 'features/srPanel/templateFieldsGrid/richTextField/useRichTextEditor';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { updateRichFieldData } from 'features/srPanel/templateFieldsGrid/richTextField/store/slice';
import useTexts from './useTexts';
import { StyledRichtextWrapper, StyledMenu, RichTextRenderer } from './RichTextRender.styles';
import MissingTemplateTooltip from '../CellComponent/MissingTemplateTooltip';

export const RichTextRender = memo((props) => {
  const { srId, fieldId, required, fieldName, className = '', handleSaveValue, disabled = false, fieldValue, notInTemplate } = props;
  const { descriptionPlaceholder } = useTexts();
  const [isFullSize, setIsFullSize] = useState(false);
  const [plainTextContent, setPlainTextContent] = useState(fieldValue.plainTextContent);
  const [jsonContent, setJsonContent] = useState(fieldValue.jsonContent);

  useEffect(() => {
    setPlainTextContent(fieldValue.plainTextContent);
    setJsonContent(fieldValue.jsonContent);
  }, [fieldValue]);

  const toggleFullSize = () => {
    setIsFullSize(!isFullSize);
  };
  const {
    data,
    isEditorDirty,
    activeUploads,
    handleSave,
    handleChange,
    handleFileAttach,
    handleImageUpload,
    handleChangeActiveUploads,
    handleRemoveAttachmentById,
  } = useRichTextEditor({
    fieldId,
    fieldName,
    sourceId: srId,
    fieldValue: jsonContent,
    handleSaveValue,
    required,
  });

  const dispatch = useDispatch();
  const labelRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpened = !!anchorEl;

  const handleOpen = useCallback(() => {
    if (!disabled) {
      setAnchorEl(labelRef.current);
      dispatch(
        updateRichFieldData({
          fieldId,
          sourceId: srId,
          data: {
            text: jsonContent?.text || '',
            attachments: jsonContent?.attachments || [],
            inlineImages: jsonContent?.inlineImages || [],
          },
        }),
      );
    }
  }, [dispatch, labelRef, disabled, fieldId, srId, jsonContent]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickAway = useCallback(() => {
    if (data && data?.text !== jsonContent?.text) {
      const paragraphs = data?.text.split(/(?=<\s*p\s*>)/);
      setJsonContent(data);
      const plaintext = paragraphs[0].replaceAll('<br>', ' ').replace(/(<([^>]+)>|[&]nbsp[;])/gi, '');
      setPlainTextContent(plaintext);
    }
    handleSave(data);
    handleClose();
  }, [handleSave, handleClose, data, jsonContent?.text]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleOpen, handleClose]);

  const isEmptyRequiredValue = required && !plainTextContent;
  // const [onlyData, setOnlyData] = useState(true);

  return (
    notInTemplate ? <MissingTemplateTooltip /> :
    (<RichTextRenderer ref={labelRef} isEmptyRequiredValue={isEmptyRequiredValue}>
      <InlineFieldContainer
        disabled
        fullwidth
        fieldName={fieldName}
        onClick={handleOpen}
        required={required}
        title={plainTextContent || ''}
        text={(plainTextContent || '').split('\n')[0]}
        isMultilineRTF={plainTextContent?.includes('\n')}
        // onDoubleClick={() => setOnlyData(false)}
      />
      {/* {!onlyData && ( */}
      <StyledMenu
        open={isOpened}
        anchorEl={anchorEl}
        placement="bottom-start"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // onDoubleClick={() => setOnlyData(true)}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledRichtextWrapper className={className}>
            <RichTextEditor
              hasFullSize
              sourceId={srId}
              isExpanded={isOpened}
              isReadOnly={disabled}
              onChange={handleChange}
              editorText={data?.text}
              isFullSize={isFullSize}
              handleExpanded={handleClose}
              isEditorDirty={isEditorDirty}
              activeUploads={activeUploads}
              shouldTriggerDeleteApi={false}
              toggleFullSize={toggleFullSize}
              onFileAttach={handleFileAttach}
              onImageUpload={handleImageUpload}
              attachmentsList={data?.attachments}
              type={RICH_TEXT_EDITOR_TYPES.TABLE}
              placeholder={descriptionPlaceholder}
              editorType={editorActionTypes.RICH_TEXT_FIELD}
              onAttachmentDeleted={handleRemoveAttachmentById}
              onChangeActiveUploads={handleChangeActiveUploads}
            />   
          </StyledRichtextWrapper>
        </ClickAwayListener>
      </StyledMenu>
      {/* )} */}
    </RichTextRenderer>));
});
