import { useState } from 'react';
import PropTypes from 'prop-types';
import { PDFReader } from 'reactjs-pdf-reader';
import CircularProgress from 'common/components/circularProgress';
import { StyledCircularProgress } from './styles';

const PdfPreview = ({ pdfUrl, dataTestId, dataCy, showAllPage }) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleDocumentComplete = () => {
    setIsLoading(false);
  };

  return (
    <div data-testid={dataTestId} data-cy={dataCy}>
      {isLoading && (
        <StyledCircularProgress>
          <CircularProgress size={34} />
        </StyledCircularProgress>
      )}
      <PDFReader url={pdfUrl} showAllPage={showAllPage} onDocumentComplete={handleDocumentComplete} />
    </div>
  );
};

PdfPreview.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  showAllPage: PropTypes.bool,
  dataCy: PropTypes.string,
  dataTestId: PropTypes.string,
};

PdfPreview.defaultProps = {
  showAllPage: true,
  dataTestId: 'pdf-preview',
  dataCy: 'pdf-preview',
};

export default PdfPreview;
