import { useRef, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import ReadOnlyEditor from 'react-froala-wysiwyg/FroalaEditorView';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { editorActionData } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { HAS_HEADER } from 'features/resolutionPanel/constants';
import { baseURL } from 'services/axiosBaseInstance';

import { RICH_TEXT_EDITOR_TYPES, DEFAULT_CONFIG } from './constants';
import { useInitialContent } from './hooks/messageHooks';
import Attachments from './attachments';
import RichTextEditor from './RichTextEditor';
import { StyledGridContainer, StyledRichTextEditorWrapper } from './styles';
import { checkFileType, handlePasteFile } from './utils';

import 'tributejs/dist/tribute.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

const RichTextWrapper = forwardRef((props, ref) => {
  const {
    sourceId,
    type,
    disabled,
    editorText,
    attachmentsList,
    isReadOnly,
    shouldRenderDefaultUI,
    customDefaultUI,
    shouldTriggerDeleteApi,
    activeUploads,
    onChangeActiveUploads,
    isExpanded,
    isFullSize,
    isRichTextTruncated,
    onImageUpload,
    onFileAttach,
    onAttachmentDeleted,
    onChangeUploadImageProgress,
    onChangeUploadFileProgress,
    editorType,
    deleteAttachmentEnabled,
    dataTestId,
    editorElementsClassName,
    shouldShowAttachments = true,
  } = props;

  const configMap = {
    [RICH_TEXT_EDITOR_TYPES.TEXT]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention', 'insertFile'],
    },
    [RICH_TEXT_EDITOR_TYPES.EMAIL]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention', 'insertFile', 'cannedResponses'],
    },
    [RICH_TEXT_EDITOR_TYPES.TABLE]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention'],
    },
    [RICH_TEXT_EDITOR_TYPES.TEMPLATE]: {
      toolbarButtons: ['insertLink', 'mention'],
    },
  };

  const { toolbarButtons = [] } = configMap[type];
  const isAttachmentsEnabled = ['insertImage', 'insertFile'].some((feature) => toolbarButtons.includes(feature));
  const editorElementClassName = editorActionData[editorType]?.editorElementClassName || 'editor-element';
  const isTemplatePage = type === RICH_TEXT_EDITOR_TYPES.TEMPLATE;

  const editorWrapRef = useRef();
  const editorRef = useRef(null);
  const richTextEditorRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      richTextEditorRef.current?.focus();
    },
  }));

  const [imagePreviewConfig, setImagePreviewConfig] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [editorId, setEditorId] = useState('');
  const [isUploadFromMenuOpened, setIsUploadFromMenuOpened] = useState(false);
  const [uploadFromMenuId, setUploadFromMenuId] = useState(null);
  const [isUploadFormAttachmentsMenuOpened, setIsUploadFormAttachmentsMenuOpened] = useState(false);

  const height = useMemo(() => {
    if (isExpanded) {
      return isFullSize ? '300px' : '120px';
    }
    return editorText ? 'auto' : '24px';
  }, [isExpanded, isFullSize, editorText]);

  const initialContent = useInitialContent(editorType, sourceId);
  const signature = initialContent?.data?.emailSignature;
  const prevSignature = usePreviousValue(signature);
  let editorContent = editorText;
  if (signature && signature !== prevSignature) {
    const getSignatureEl = (currentSignature) => `<div class='email-signature'>${currentSignature}</div>`;
    if (editorText.includes('email-signature')) {
      editorContent.replace(getSignatureEl(prevSignature), getSignatureEl(signature));
    } else {
      editorContent += getSignatureEl(signature);
    }
  }

  const onChangeErrorMessage = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const clearFileInput = (event) => {
    event.target.value = '';
  };

  const handleFileUpload = (event) => {
    event.stopPropagation?.();
    event.preventDefault?.();
    const file = event.target.files[0];
    return file;
  };

  const handleImageUpload = async (event) => {
    try {
      const file = handleFileUpload(event);
      const attachmentId = await onImageUpload({ file });
      if (attachmentId) {
        editorRef.current.image.insert(`${baseURL}attachments/${attachmentId}/payload?inline=true`, true, {
          'data-attachmentId': attachmentId,
        });
      }
    } catch (error) {
      onChangeErrorMessage(error.response?.prompt);
    } finally {
      onChangeUploadImageProgress?.(false);
    }
    clearFileInput(event);
  };

  const handleUploadProgress = (id, progressEvent) => {
    onChangeActiveUploads({
      type: 'updateUpload',
      payload: {
        id,
        progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      },
    });
  };

  const handleNewAttachment = async (event) => {
    const id = uuidv4();
    try {
      const file = handleFileUpload(event);
      if (file) {
        onChangeActiveUploads({ type: 'addUpload', payload: { fileName: file.name, id } });

        await onFileAttach({
          file,
          uploadId: id,
          onProgressChange: (progressEvent) => handleUploadProgress?.(id, progressEvent),
        });
        onChangeActiveUploads({ type: 'removeUpload', payload: { id } });
      }
    } catch (error) {
      console.log('error in update progress', error);
      onChangeActiveUploads({ type: 'updateUpload', payload: { id, error: error.response?.inline } });
    } finally {
      onChangeUploadFileProgress?.(false);
    }
    clearFileInput(event);
  };

  const handleAttachmentDrop = (acceptedFiles) => {
    const isImage = checkFileType(acceptedFiles[0]);
    const event = { target: { files: acceptedFiles } };
    if (isImage) {
      handleImageUpload(event);
    } else {
      handleNewAttachment(event);
    }
  };

  const handlePasteAttachment = async (img) => {
    const syntheticEvent = handlePasteFile(img);
    if (syntheticEvent) await handleNewAttachment(syntheticEvent);
  };

  const readOnlyEditor = isReadOnly ? (
    <StyledRichTextEditorWrapper className="readonly-editor">
      <ReadOnlyEditor
        editorRef={editorRef}
        model={editorContent}
        config={{
          key: process.env.REACT_APP_FROALA_API_KEY,
          ...DEFAULT_CONFIG,
        }}
      />
      <div>{customDefaultUI}</div>
    </StyledRichTextEditorWrapper>
  ) : null;

  const richTextEditor = (
    <StyledRichTextEditorWrapper shouldRenderDefaultUI={shouldRenderDefaultUI} className="rich-text-editor-wrapper">
      <RichTextEditor
        {...props}
        ref={richTextEditorRef}
        toolbarButtons={toolbarButtons}
        isAttachmentsEnabled={isAttachmentsEnabled}
        editorWrapRef={editorWrapRef}
        editorRef={editorRef}
        editorText={editorContent}
        setEditorId={setEditorId}
        editorElementsClassName={editorElementsClassName}
        onAttachmentDrop={handleAttachmentDrop}
        onChangeImagePreviewsConfig={setImagePreviewConfig}
        isUploadFromMenuOpened={isUploadFromMenuOpened}
        setIsUploadFromMenuOpened={setIsUploadFromMenuOpened}
        isUploadFormAttachmentsMenuOpened={isUploadFormAttachmentsMenuOpened}
        setIsUploadFormAttachmentsMenuOpened={setIsUploadFormAttachmentsMenuOpened}
        setUploadFromMenuId={setUploadFromMenuId}
        handlePasteAttachment={handlePasteAttachment}
      />
      {customDefaultUI}
    </StyledRichTextEditorWrapper>
  );

  const attachmentsWrapper = shouldShowAttachments && isAttachmentsEnabled && (
    <div className={`${editorElementClassName} attachments-wrapper`}>
      <Attachments
        isExpanded={isExpanded}
        editorRef={editorRef}
        imagePreviewConfig={imagePreviewConfig}
        onChangeImagePreviewsConfig={setImagePreviewConfig}
        attachments={attachmentsList}
        onAttachmentDeleted={onAttachmentDeleted}
        activeUploads={activeUploads}
        onChangeActiveUploads={onChangeActiveUploads}
        isReadOnly={Boolean(isReadOnly)}
        onImageUpload={handleImageUpload}
        onFileAttach={handleNewAttachment}
        errorMessage={errorMessage}
        onChangeErrorMessage={onChangeErrorMessage}
        isUploadFromMenuOpened={isUploadFromMenuOpened}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        setIsUploadFromMenuOpened={setIsUploadFromMenuOpened}
        setIsUploadFormAttachmentsMenuOpened={setIsUploadFormAttachmentsMenuOpened}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        uploadFromMenuId={uploadFromMenuId}
        setUploadFromMenuId={setUploadFromMenuId}
      />
    </div>
  );

  return (
    <StyledGridContainer
      isTemplatePage={isTemplatePage}
      isExpanded={isExpanded}
      height={height}
      maximized={isFullSize.toString()}
      disabled={disabled}
      ref={editorWrapRef}
      isScrollable={attachmentsList?.length + activeUploads?.length > 3}
      hasAttachments={!!attachmentsList?.length}
      isReadOnly={isReadOnly}
      isRichTextTruncated={isRichTextTruncated}
      editorContent={editorContent}
      hasHeader={HAS_HEADER.includes(editorType)}
      id={`richtext-wrap-${editorId}`}
      data-testid={dataTestId || `richtext-wrap-${editorRef?.current?.id}`}
      data-cy={dataTestId || `richtext-wrap-${editorRef?.current?.id}`}
    >
      {readOnlyEditor || richTextEditor}
      {attachmentsWrapper}
    </StyledGridContainer>
  );
});

RichTextWrapper.propTypes = {
  type: PropTypes.number,
  charCounterMax: PropTypes.number,
  showToolbar: PropTypes.bool,
  disabled: PropTypes.bool,
  editorText: PropTypes.string,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  CustomFooter: PropTypes.element,
  attachmentsList: PropTypes.array,
  isUploadImageProgress: PropTypes.bool,
  isUploadFileProgress: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
  activeUploads: PropTypes.array,
  onChangeActiveUploads: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  handleClickAway: PropTypes.func,
  handleExpanded: PropTypes.func,
  toggleFullSize: PropTypes.func,
  onImageUpload: PropTypes.func,
  onFileAttach: PropTypes.func,
  onChangeUploadImageProgress: PropTypes.func,
  onChangeUploadFileProgress: PropTypes.func,
  isExpanded: PropTypes.bool,
  isFullSize: PropTypes.bool,
  isRichTextTruncated: PropTypes.bool,
  hasFullSize: PropTypes.bool,
  shouldTriggerDeleteApi: PropTypes.bool,
  shouldRenderDefaultUI: PropTypes.bool,
  customDefaultUI: PropTypes.element,
  dataTestId: PropTypes.string,
};

RichTextWrapper.defaultProps = {
  type: 1,
  charCounterMax: DEFAULT_CONFIG.defaultCharCounterMax,
  showToolbar: true,
  isExpanded: false,
  isFullSize: false,
  hasFullSize: true,
  disabled: false,
  isReadOnly: false,
  shouldTriggerDeleteApi: true,
  shouldRenderDefaultUI: false,
  isUploadImageProgress: false,
  isUploadFileProgress: false,
  customDefaultUI: null,
  editorText: '',
  placeholder: '',
  dataTestId: '',
  CustomFooter: null,
  attachmentsList: [],
  activeUploads: [],
  onAttachmentDeleted: () => {},
  onChangeActiveUploads: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  handleExpanded: () => {},
  handleClickAway: () => {},
  toggleFullSize: () => {},
  onImageUpload: () => {},
  onFileAttach: () => {},
  onChangeUploadImageProgress: () => {},
  onChangeUploadFileProgress: () => {},
};

export default RichTextWrapper;
