import { forwardRef } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';

const NavItem = forwardRef(
  (
    {
      classNames = '',
      title,
      icon,
      expanded,
      id = 'list',
      isActive = false,
      handleClick = () => {},
      disableTooltip = false,
    },
    ref,
  ) => (
    <StyledTooltip arrow title={disableTooltip || expanded || isActive ? null : title} placement="right">
      <ListItem disablePadding className={`${classNames} ${title} ${isActive ? 'active' : ''}`}>
        <ListItemButton data-testid={`nav-bar-item-${id}`} data-cy={`nav-bar-item-${id}`} disableGutters disableRipple onClick={handleClick} ref={ref}>
          <ListItemIcon>{icon}</ListItemIcon>
          {expanded && <ListItemText primary={title} />}
        </ListItemButton>
      </ListItem>
    </StyledTooltip>
  ),
);

export default NavItem;
