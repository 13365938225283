import { useCallback, useEffect, useRef, useState } from 'react';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import smallYellowStarsIcon from 'images/icons/generativeAi/yellow_stars_16x16.gif';
import { TicketSummaryTooltip } from 'common/components/summaryPopup/Tooltip';
import { fieldValueExists } from 'common/utils/fieldUtils';
import { Box } from '@mui/system';
import { StyledCommonTextField } from './CommonTextField';
import { CONSTANTS } from '../constants';
import { StyledTooltip } from '../Tooltip/Tooltip';
import useTexts from '../useTexts';
import { SR_PANEL_CONSTANTS } from '../../../../features/srPanel/consts';

export const InlineField = (props) => {
  const {
    title,
    value,
    handleSave,
    setIsError,
    type,
    subType,
    unread,
    disabled,
    isLocked,
    multiline,
    isMultilineRTF,
    isError,
    onChange,
    limit,
    fullwidth,
    source,
    inputRef,
    isOverflow,
    ticket,
    setIsOverflow,
    placeholder,
    required,
    isNewField,
    isTemplatePage,
    onClick,
    onFocus,
    fieldId,
    fieldTypeId,
    showSummaryTooltip = false,
    text,
    tooltipClassName,
    ...rest
  } = props;
  // const { enterTextPlaceholder } = useTexts();
  const { mandatoryFieldError } = useTexts();

  const [rows, setRows] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const maxLength = 5000;
  const characterCount = useRef(value?.length || 0);


  useEffect(() => {
    if (multiline) {
      setRows(3);
    }
  }, [multiline]);

  const handleKeyPress = (event) => {
    if (limit) {
      if (value?.length >= limit) {
        event.preventDefault();
      }
    } else if (value?.length >= CONSTANTS.INPUT_CHARACTERS_LIMIT) {
      event.preventDefault();
    }

    if (!multiline) {
      if (event.key === 'Enter' || (event.shiftKey && event.key === 'Enter')) {
        event.target.blur();
      }
    }
  };

  const onChangeValue = (event) => {
    if (fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT) {
      characterCount.current = event.target.value.length;
    }
    onChange(event);
  }

  const handleFocus = () => {
    if (!disabled && onFocus) {
      onFocus(fieldId);
    }
    if (multiline) {
      setRows(3);
    }

    setIsFocused(true);
    setIsError(false);
    setIsOverflow(false);
  };

  const getIsOverflowValue = useCallback(
    () =>
      inputRef?.current?.scrollWidth > inputRef?.current?.offsetWidth ||
      multiline ||
      isMultilineRTF ||
      value?.length > 25,
    [inputRef, isMultilineRTF, multiline, value?.length],
  );

  const prevValue = usePreviousValue(text);

  const handleBlur = () => {
    if (!multiline) {
      setRows(1);
    }
    setIsFocused(false);

    if (handleSave && value !== prevValue) {
      handleSave(value);
    }
    if (!fieldValueExists({ value }) && required) {
      setIsError(true);
    }
    if (multiline) {
      inputRef.current.scrollTo(0, 0);
    }

    setIsOverflow(getIsOverflowValue());
  };

  const onMouseOver = () => {
    setIsOverflow(getIsOverflowValue());
  };
  const setPlaceholder = () => {
    if (required && !value) {
      return '';
    }
    if (placeholder) {
      return placeholder;
    }
    return '--';
  };

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const getEndAdornment = () => {
    if (isError) {
      return null;
    }
    if (showSummaryTooltip && isHovered && !isFocused) {
      return <img src={smallYellowStarsIcon} alt="" />;
    }
    return null;
  };

  const styledCommonTextField = (
    <StyledCommonTextField
      className={`inline-field ${isError ? 'with-error' : ''}`}
      variant="standard"
      disabled={disabled}
      isLocked={isLocked}
      error={isError}
      unread={unread?.toString()}
      ticket={ticket}
      placeholder={setPlaceholder()}
      value={value}
      onChange={onChangeValue}
      onKeyPress={handleKeyPress}
      onClick={onClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      multiline={multiline}
      source={source}
      fullwidth={fullwidth}
      rows={rows}
      inputRef={inputRef}
      isNewField={isNewField}
      isTemplatePage={isTemplatePage}
      InputProps={{
        disableUnderline: true,
        readOnly: disabled,
        endAdornment: getEndAdornment(),
        'data-testid': 'inline-field',
      }}
      onMouseOver={onMouseOver}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isTextAreaFocused={isFocused && fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT}
      helperText= { isFocused && fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT &&
         <Box
            component="span"
            sx={{
               display: "flex",
               justifyContent: "flex-end",
               paddingRight: "5px",
               }}>
            <span>
              {`${characterCount.current}/${maxLength}`}
            </span>
         </Box>
      }
      type={type}
      {...rest}
    />
  );

  if (multiline) {
    return styledCommonTextField;
  }

  if (isError) {
    return (
      <StyledTooltip arrow placement="top" tooltipClassName={tooltipClassName} title={mandatoryFieldError}>
        {styledCommonTextField}
      </StyledTooltip>
    );
  }

  return showSummaryTooltip ? (
    <TicketSummaryTooltip isWrappedInputFocused={isFocused}>{styledCommonTextField}</TicketSummaryTooltip>
  ) : (
    <StyledTooltip
      arrow
      placement="top"
      tooltipClassName={tooltipClassName}
      title={!isFocused && isOverflow ? title || value : ''}
    >
      {styledCommonTextField}
    </StyledTooltip>
  );
};
