import { styled } from '@mui/material/styles';

export const StyledTemplateHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.grey.bt_alt_grey_light,
  borderRadius: '10px 10px 0px 0px',
  padding: '20px 24px',
  borderBottom: theme.shape.border.border,

  '& > :first-of-type': {
    height: '32px',

    '& .MuiTextField-root .MuiInput-root': {
      fontWeight: '300',
    },
  },
}));

export const StyledTemplateButtonsArea = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '12px',
  columnGap: '24px',
  '@media only screen and (max-width: 1440px)': {
    columnGap: '12px',
  },
  '.header-footer-div': {
    width: '50%',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 'inherit',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    '& > div.template-header': {
      maxWidth: '23%',
    },
    '& span.template-header': {
      display: 'none',
    },
  },
}));

export const StyledTemplateButtonsAreaRight = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '50%',

  '& .assignees': {
    height: '100%',
    maxWidth: '500px',
    '& .assignee-open-popup-button': {
      maxWidth: '100%',
    },

    '@media only screen and (max-width: 2500px)': {
      maxWidth: 'calc(100% - 162px)',
    },
    '@media only screen and (max-width: 1280px)': {
      maxWidth: 'calc(100% - 127px)',
    },
    '& .current-assignee': {
      maxWidth: 'calc(100% - 32px)',
      width: '100%',
    },
  },
}));
