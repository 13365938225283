import { useEffect, useState } from 'react';
import { getIsExistingSr } from 'common/utils/srUtils';
import { SECTIONS_CONSTANTS } from './constants';
import Section from './section';

export default function Sections(props) {
  const { sr, template, handleDirtyFields, handleSaveSR, hasEditPermissions, isFloatingTicketPanel } = props;
  const [sectionsCollapsed, setSectionsCollapsed] = useState();
  const srId = sr.id;
  const isExistingSR = getIsExistingSr(srId);
  const sections = template?.sections?.sort((a, b) => a.order - b.order);

  const getCollapsedSections = () => {
    let storedSectionData = sessionStorage.getItem(SECTIONS_CONSTANTS.SECTIONS_COLLAPSED_SESSION_KEY);
    storedSectionData = JSON.parse(storedSectionData);
    if (!storedSectionData) {
      storedSectionData = {};
    }
    return storedSectionData;
  };

  const setSectionsCollapsedInSession = (key, value) => {
    const sectionsStatus = getCollapsedSections();
    sectionsStatus[key] = value;
    setSectionsCollapsed(sectionsStatus);
    sessionStorage.setItem(SECTIONS_CONSTANTS.SECTIONS_COLLAPSED_SESSION_KEY, JSON.stringify(sectionsStatus));
  };

  useEffect(() => {
    if (isExistingSR) {
      const sectionsStatus = getCollapsedSections();
      if (sectionsStatus) {
        setSectionsCollapsed(sectionsStatus);
      }
    }
  }, [isExistingSR]);

  const handleExpansion = (section, value) => setSectionsCollapsedInSession(`${srId}_${section.order}`, value);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        padding: isFloatingTicketPanel ? '24px 0px 12px' : '0px',
      }}
      data-cy="sections-wrapper"
    >
      {sections?.map((section) => (
        <div key={section.order} style={{ paddingRight: '40px' }}>
          <Section
            section={section}
            srId={srId}
            handleDirtyFields={handleDirtyFields}
            handleSaveSR={handleSaveSR}
            sr={sr}
            hasEditPermissions={hasEditPermissions}
            onExpansion={isExistingSR ? handleExpansion : undefined}
            initExpanded={(sectionsCollapsed && sectionsCollapsed[`${srId}_${section.order}`]) ?? true}
          />
        </div>
      ))}
    </div>
  );
}
