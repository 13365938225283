import { memo, useEffect } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { CongratsPopup } from 'features/CongratsPopUp';
import { isEmpty } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { clearRichFields } from 'features/srPanel/templateFieldsGrid/richTextField/store/slice';
import { selectPanels } from 'store/floatingTicketPanelSlice';
import { selectFilterModel, selectRememberRow, selectColumnsOrder } from './slice';
import ColumnsConfiguration from './grid/columnsConfiguration';
import Grid from './grid';
import GridFilters from './gridFilters';
import GridConfigurationHeader from './gridConfigurationHeader';
import { StyledQueue, StyledContainer } from './style';
import useColumnTexts from './grid/customColumnHeader/useTexts';
import { useGetColumnDefinitions } from './grid/hooks/useGetColumnDefinitions';

const queueSelector = createSelector(
  selectFilterModel,
  selectRememberRow,
  selectColumnsOrder,
  (filterModel, { scrollToIndex, scrollToId }, columnsOrder) => ({
    filterModel,
    scrollToIndex,
    scrollToId,
    columnsOrder,
  }),
);
const Queue = memo(() => {
  const dispatch = useDispatch();
  const { getColumnText } = useColumnTexts();
  const { filterModel, scrollToIndex, scrollToId, columnsOrder } = useSelector(queueSelector);
  const panels = useSelector(selectPanels);
  const configuredColumnsList = useGetColumnDefinitions(columnsOrder, getColumnText);
  useEffect(() => {
    const cleanUp = () => {
      dispatch(clearRichFields());
    };
    return () => cleanUp();
  }, [dispatch]);

  return (
    <StyledQueue isPanelsOpen={Object.keys(panels).length > 0}>
      <CongratsPopup />
      <StyledContainer>
        <GridConfigurationHeader className="header" />
      </StyledContainer>
      {columnsOrder?.length > 0 && (
        <>
          <GridFilters />
          <ColumnsConfiguration />
          <Grid
            className="content"
            enforceFilterModel={filterModel}
            scrollToIndex={scrollToIndex}
            scrollToId={scrollToId}
            showFilters={!isEmpty(filterModel)}
            columnDefs={configuredColumnsList}
          />
        </>
      )}
    </StyledQueue>
  );
});

export default Queue;
