import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { IconButton } from '@mui/material';

export const StyledMenuButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'isOpen' })(
  ({ theme, isOpen }) => ({
    padding: '6px',
    height: '32px',
    width: '32px',
    borderRadius: theme.shape.border.borderRadius,
    backgroundColor: isOpen ? theme.palette.smallIconButton.btn.backgroundColor.hover : '',
    '&:hover': {
      backgroundColor: theme.palette.smallIconButton.btn.backgroundColor.hover,
    },
  }),
);

export const StyledDropDownMenu = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: '8px',
    borderRadius: '6px',
    boxShadow: `0px 2px 8px ${theme.palette.background.boxShadow}`,
  },
  ul: {
    minWidth: '180px',
    padding: 0,
  },
  li: {
    padding: '0',
    '>*': {
      padding: '12px 16px',
      width: '100%',
      justifyContent: 'flex-start',
    },
    '&:hover': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      padding: '0',
      alignSelf: 'stretch',
      borderRadius: '6px',
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
  },
}));
