import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'images/icons/upload.svg';
import { StyledAttachmentDropzone } from './StyledAttachmentDropzone';

export default function AttachmentDropzone({
  children = () => null,
  dropzoneDisabled = false,
  UploadButton,
  onAttachmentDrop,
  displayDrag = false,
  isActionItem,
  ...props
}) {
  const handleOnDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      onAttachmentDrop(acceptedFiles);
    }
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    onDrop: handleOnDrop,
    disabled: dropzoneDisabled,
  });
  return (
    <StyledAttachmentDropzone
      isActionItem={isActionItem}
      {...getRootProps({ isFocused, isDragAccept, isDragReject })}
      isDragAccept={displayDrag || isDragAccept}
      data-testid="attachment-dropzone"
      {...props}
    >
      <input {...getInputProps()} />
      <div className="attachments-zone">
        <UploadIcon />
        {UploadButton}
      </div>
      {children}
    </StyledAttachmentDropzone>
  );
}
