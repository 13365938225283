import { useCallback, useRef, useState } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as ActionsIcon } from 'images/icons/actions-icon.svg';
import { useShowLinkToClassic, useSrConversionEnabled } from 'remote-state/accountSettingsServiceHooks';
import DeleteTicket from './deleteTicket';
import OpenInClassic from './openInClassic';
import { StyledActions, StyledMenu } from './style';
import { PENDO_TRACK_EVENTS } from '../../../constants';
import ConvertTicket from './convertTicket';

export default function Actions(props) {
  const {
    ticketQueueUrl,
    onClickParent,
    srId,
    srType,
    disabledDeleteAction,
    handleMouseEnter,
    handleMouseLeave,
    hovered,
    btnClassName = '',
    deleteTicketSx,
    menuStyles = {},
    footer = null,
    pendoEvent = PENDO_TRACK_EVENTS.open_in_classic_from_queue_click,
    isFromQueue = true,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const open = Boolean(anchorEl);

  const showLinkToClassic = useShowLinkToClassic();
  const isSrConversionEnable = useSrConversionEnabled(srType);

  const handleClick = () => {
    setAnchorEl(ref.current);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <StyledActions
      className="actionsClass"
      btnClassName={btnClassName}
      isActive={anchorEl}
      data-testid="header-actions"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            ref={ref}
            id="actions-icon-button"
            onClick={handleClick}
            className={`${anchorEl || hovered ? 'active ' : ''}${btnClassName}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ActionsIcon />
          </IconButton>
          <StyledMenu
            id="action-menu"
            MenuListProps={{
              'aria-labelledby': 'action-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={2}
            menuStyles={menuStyles}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {!disabledDeleteAction && (
              <DeleteTicket
                ticketQueueUrl={ticketQueueUrl}
                handleCloseParent={handleClose}
                onClickParent={onClickParent}
                srId={srId}
                customSx={deleteTicketSx}
              />
            )}
            {isSrConversionEnable && (
                <ConvertTicket
                  ticketQueueUrl={ticketQueueUrl}
                  handleCloseParent={handleClose}
                  onClickParent={onClickParent}
                  srId={srId}
                  srType={srType}
                  isFromQueue={isFromQueue}
                />
            )}
            {showLinkToClassic && <OpenInClassic srId={srId} handleCloseParent={handleClose} pendoEvent={pendoEvent} />}
            {footer && (
              <Box>
                <Divider />
                {footer}
              </Box>
            )}
          </StyledMenu>
        </Box>
      </ClickAwayListener>
    </StyledActions>
  );
}
