import { ReactComponent as BuildingsIcon } from 'images/icons/buildings.svg';
import { ReactComponent as PhoneIcon } from 'images/icons/call.svg';
import { ReactComponent as ClockIcon } from 'images/icons/time.svg';
import { ReactComponent as EmailIcon } from 'images/icons/mail.svg';
import Tooltip from 'common/components/tooltip';
import CopyButton from 'common/components/copyButton';
import StyledUserInfoCardDetails from './styles';
import UserInfoCardDetail from './userInfoCardDetail';
import useTexts from '../useText';

const componentId = 'user-info-card-user-details';

const UserInfoCardDetails = ({ jobTitle, email, phoneNumber, localTime }) => {
  const { localTimeText } = useTexts();

  return (
    <StyledUserInfoCardDetails disablePadding id={componentId}>
      <UserInfoCardDetail id="job-title" Icon={BuildingsIcon} text={jobTitle} />
      <UserInfoCardDetail
        id="email"
        Icon={EmailIcon}
        text={Boolean(email) && <Tooltip text={email} isTruncatedText placement="top" />}
        secondaryAction={Boolean(email) && <CopyButton text={email} id="email" />}
      />
      <UserInfoCardDetail
        id="phone-number"
        Icon={PhoneIcon}
        text={Boolean(phoneNumber) && <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>}
        secondaryAction={Boolean(phoneNumber) && <CopyButton text={phoneNumber} id="phone-number" />}
      />
      <UserInfoCardDetail
        id="local-time"
        Icon={ClockIcon}
        text={Boolean(localTime) && `${localTimeText} - ${localTime}`}
      />
    </StyledUserInfoCardDetails>
  );
};
export default UserInfoCardDetails;
