import { StyledWrapper, StyledActionButton } from './style';
import useTexts from '../useTexts';
import CircularProgress from '../../../common/components/circularProgress';
import { StyledTypeBasedCircularProgress } from '../../../common/components/sysaidLibrary/Button/style';

export default function FloatingActions(props) {
  const { onSaveClicked, onCancelClicked, isSaving, isNotValid, isDisabled } = props;
  const { cancelButtonText, createButtonText } = useTexts();

  return (
    <StyledWrapper className="floatingActionsFooter">
      <StyledActionButton disabled={isSaving} onClick={onCancelClicked} data-testid="cancel">
        {cancelButtonText}
      </StyledActionButton>
      <StyledActionButton
        isNotValid={isNotValid}
        disabled={isDisabled || isSaving}
        isCreateButton
        onClick={() => {
          if (isSaving) return;
          onSaveClicked();
        }}
        data-testid="create"
      >
        {isSaving && (
          <StyledTypeBasedCircularProgress>
            <CircularProgress size={16} thickness={4} />
          </StyledTypeBasedCircularProgress>
        )}
        {createButtonText}
      </StyledActionButton>
    </StyledWrapper>
  );
}
