import { useRouter } from '@tanstack/react-router';
import { Box } from '@mui/material';
import { ROUTES_PATHS } from 'constants/index';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';

export default function NavLink({ to, isOpenInNewTab, openAsIframeInNewTab, children, settingIds, ...rest }) {
  const router = useRouter();
  const { data: showExternalLinksInIframe } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.SHOW_EXTERNAL_PAGES_IN_IFRAME,
    defaultValue: true,
  });
  const openUrlInNewWindow = (url) => {
    const urlToResource = `${url}${url.includes('?') ? '&' : '?'}openedInIframe=1`;
    let finalUrl;
    if (rest?.isSettingsPage) {
      finalUrl = `${ROUTES_PATHS.EXTERNAl_PAGES_URL}?url=${encodeURIComponent(
        urlToResource,
      )}&settingIds=${encodeURIComponent(JSON.stringify(settingIds))}&showBack=${Number(
        Boolean(rest?.isSettingsPage),
      )}`;
    } else {
      finalUrl = `${ROUTES_PATHS.EXTERNAl_PAGES_URL}?url=${encodeURIComponent(urlToResource)}&showBack=${Number(
        Boolean(rest?.isSettingsPage),
      )}`;
    }
    // Check if the URL does not include "/docs"
    if (!url.includes("/docs")) {
      // Append the query parameter based on the presence of other query parameters
      finalUrl = `${url}${url.includes('?') ? '&' : '?'}openedInIframe=1`;
    }

    // Open the URL in a new window
    window.open(finalUrl, '_blank', 'noreferrer');
  };

  const openUrlInIframe = (url) => {
    if (openAsIframeInNewTab) {
      openUrlInNewWindow(url);
    } else {
      const urlToResource = `${url}${url.includes('?') ? '&' : '?'}openedInIframe=1`;
      let searchUrl;
      if (rest?.isSettingsPage) {
        searchUrl = `${urlToResource}&settingIds=${JSON.stringify(settingIds)}&showBack=${Number(Boolean(rest?.isSettingsPage))}`;
      } else {
        searchUrl = `${urlToResource}&showBack=${Number(Boolean(rest?.isSettingsPage),)}`;
      }
      const path = ROUTES_PATHS.EXTERNAl_PAGES_URL;
      const search = {
        url: searchUrl
      }
      router.navigate({ to: path, state: settingIds, search });
    }
  };

  const handleClick = () => {
    if (
      router.flatRoutes.some((r) => r.fullPath === to) ||
      router.flatRoutes.some((r) => r.fullPath === `/spaces${to}`)
    ) {
      const path = `/spaces${to}${
        rest?.isSettingsPage ? `?settingIds=${encodeURIComponent(JSON.stringify(settingIds))}` : ''
      }`;
      return router.navigate({ to: path, state: settingIds });
    }

    if (isOpenInNewTab || !showExternalLinksInIframe) {
      openUrlInNewWindow(to);
    } else {
      openUrlInIframe(to);
    }
  };

  return (
    <Box onClick={handleClick} {...rest} sx={{ display: 'inline' }}>
      {children}
    </Box>
  );
}
