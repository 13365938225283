import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const LogWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'textTruncateEnabled' })(
  ({ textTruncateEnabled }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 0px 4px',
    columnGap: '16px',
    ...(textTruncateEnabled && { flexWrap: 'wrap' }),
    '.change-wrapper': {
      // width: '100%',
      flex: !textTruncateEnabled ? '1 0 auto' : '0 1 auto',
    },
    '.field-change-log': {
      ...(textTruncateEnabled && { flex: '1 0' }),
    },
    '.truncate-text-button': {
      flex: !textTruncateEnabled ? '1 0 auto' : '1 1 100%',
    },
  }),
);

export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.title_txt,
  paddingTop: '6px',
  paddingLeft: '8px',
}));
