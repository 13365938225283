import { useCallback, useMemo } from 'react';
import { getTemplatesList } from 'services/templateService';
import { DropdownSelect, useDropdownControl } from 'common/components/dropdownSelect';
import CustomIconButton from 'common/components/buttons/IconButton/index';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SourcePageEnum } from 'features/templateList/sourcePageEnum';
import { ReactComponent as ChevronDown } from 'images/icons/chevron_down.svg';
import { ReactComponent as Close } from 'images/icons/x_icon.svg';
import { ReactComponent as Expand } from 'images/icons/expand_icon.svg';
import { ReactComponent as MinimizeDown } from 'images/icons/minimize_down_icon.svg';
import { ReactComponent as MinimizeUp } from 'images/icons/minimize_up_icon.svg';
import { buildTemplatesListForDropdown, useTemplateListForDropdown } from 'remote-state/ticketServiceHooks';
import { FloatingTicketInstanceModes } from 'features/floatingTicketPanel/floatingTicketPanelManager/modes';
import Tooltip from 'common/components/tooltip';
import { editFloatingTicketPanel } from 'store/floatingTicketPanelSlice';
import {
  StyledActions,
  StyledCollapsedHeader,
  StyledEmptyTitle,
  StyledFieldDropdownValue,
  StyledFieldKey,
  StyledFieldsWrapper,
  StyledFieldValue,
  StyledFloatingHeader,
  StyledTemplateWrapper,
} from './style';
import useTexts from '../useTexts';
import { getTitleWidth } from '../utils/utils';
import { CONSTANTS } from '../utils/constants';

const { COLLAPSED_TICKET_STYLE_BREAKPOINT } = CONSTANTS;

export default function FloatingHeader(props) {
  const {
    srType,
    ticketTitle,
    panelWidth,
    isCategoryDrivenTemplatesEnabled,
    onTemplateChanged,
    currentTemplate,
    panel,
    onCloseClicked,
    showAutoPopulationSection,
  } = props;
  const { mode } = panel;
  const dispatch = useDispatch();
  const { anchor, handleOpen, handleClose } = useDropdownControl();
  const {
    headerTypeText,
    templatesListTitle,
    templatesListSearchPlaceholder,
    template,
    emptyTicketTitle,
    incidentText,
    problemText,
    requestText,
    changeText,
  } = useTexts();
  const columnFilters = [{ id: 'srType', value: [srType] }];
  const sort = {
    id: 'templateName',
  };
  const templateListResponse = useTemplateListForDropdown(sort, columnFilters, true).data;
  const templatesList = useMemo(() => templateListResponse?.values || [], [templateListResponse?.values]);

  let srTypeToDisplay;
  switch (srType) {
    case 'incident':
      srTypeToDisplay = incidentText;
      break;
    case 'request':
      srTypeToDisplay = requestText;
      break;
    case 'problem':
      srTypeToDisplay = problemText;
      break;
    case 'change':
      srTypeToDisplay = changeText;
      break;
    default:
      srTypeToDisplay = incidentText;
      break;
  }

  const handleClick = (mode) => {
    dispatch(editFloatingTicketPanel({ ...panel, mode }));
  };

  const handleTemplateChanged = useCallback(
    (templateId) => {
      const selectedTemplate = templateListResponse.list.filter((t) => String(t.id) === String(templateId));
      if (selectedTemplate.length > 0) {
        onTemplateChanged(selectedTemplate[0]);
      }
    },
    [templateListResponse, onTemplateChanged],
  );

  const handleChange = useCallback(
    (val) => {
      handleTemplateChanged(val.id);
      handleClose();
    },
    [handleTemplateChanged, handleClose],
  );

  const onAction = useCallback((event, func) => {
    event.stopPropagation();
    func();
  }, []);

  const selection = useMemo(
    () => (currentTemplate?.id || currentTemplate?.id === 0 ? [currentTemplate?.id] : []),
    [currentTemplate?.id],
  );

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        getTemplatesList({
          page: 0,
          size: 50,
          columnFilters: [
            {
              id: 'srType',
              value: [srType],
            },
            {
              id: 'templateName',
              value: query,
            },
          ],
          sort: {
            id: 'templateName',
          },
          sourcePage: SourcePageEnum.CREATE_TICKET_PAGE,
          excludeSubTypes: true,
        }).then((res) => buildTemplatesListForDropdown(res)),
    }),
    [srType],
  );

  // handle the panel mode change when clicking the minimize/expand buttons, depands on the previous panel mode - whether it was auto-populate mode or not
  const handlePanelModeChange = () => {
    if (
      (mode === FloatingTicketInstanceModes.COLLAPSED || mode === FloatingTicketInstanceModes.EXPANDED) &&
      showAutoPopulationSection
    ) {
      handleClick(FloatingTicketInstanceModes.AUTO_POPULATE);
    } else if (mode === FloatingTicketInstanceModes.COLLAPSED) {
      handleClick(FloatingTicketInstanceModes.REGULAR_MODE_OPENED);
    } else {
      handleClick(
        mode === FloatingTicketInstanceModes.EXPANDED
          ? FloatingTicketInstanceModes.REGULAR_MODE_OPENED
          : FloatingTicketInstanceModes.EXPANDED,
      );
    }
  };

  if (mode === FloatingTicketInstanceModes.COLLAPSED) {
    return (
      <StyledCollapsedHeader onClick={handlePanelModeChange}>
        <StyledEmptyTitle width={getTitleWidth(panelWidth)} data-testid="empty-ticket-title">
          {ticketTitle || emptyTicketTitle}
        </StyledEmptyTitle>
        <StyledActions mode={FloatingTicketInstanceModes.COLLAPSED}>
          <CustomIconButton
            tabIndex={0}
            role="button"
            className="icon-wrapper"
            btnTheme="mediumIconButtonDarkMode"
            onClick={(event) => onAction(event, () => handlePanelModeChange())}
            onKeyDown={(event) => onAction(event, () => handlePanelModeChange())}
            data-testid="minimize"
            icon={<MinimizeUp />}
          />
          <CustomIconButton
            tabIndex={0}
            role="button"
            className="icon-wrapper"
            btnTheme="mediumIconButtonDarkMode"
            onClick={(event) => onAction(event, () => handleClick(FloatingTicketInstanceModes.EXPANDED))}
            onKeyDown={(event) => onAction(event, () => handleClick(FloatingTicketInstanceModes.EXPANDED))}
            data-testid="expand"
            icon={<Expand />}
          />
          {panelWidth > COLLAPSED_TICKET_STYLE_BREAKPOINT && (
            <CustomIconButton
              tabIndex={0}
              role="button"
              className="icon-wrapper"
              btnTheme="mediumIconButtonDarkMode"
              onClick={(event) => onAction(event, onCloseClicked)}
              onKeyDown={(event) => onAction(event, onCloseClicked)}
              data-testid="close"
              icon={<Close />}
            />
          )}
        </StyledActions>
      </StyledCollapsedHeader>
    );
  }

  return (
    <StyledFloatingHeader>
      <StyledFieldsWrapper>
        <StyledFieldKey>{headerTypeText || 'Type'}</StyledFieldKey>{' '}
        <StyledFieldValue>{srTypeToDisplay}</StyledFieldValue>
      </StyledFieldsWrapper>
      {!isCategoryDrivenTemplatesEnabled && (
        <Box
          onClick={(event) => {
            handleOpen(event);
          }}
          data-testid="select-template"
        >
          <StyledTemplateWrapper>
            <StyledFieldKey>{template || 'Template'}</StyledFieldKey>{' '}
            <StyledFieldDropdownValue>
              <Tooltip
                data-cy="selected-template-name"
                isTruncatedText
                text={currentTemplate?.templateName}
                placement="top-start"
              />
              <ChevronDown style={{ display: 'flex', flexShrink: 0, marginLeft: '4px' }} />
            </StyledFieldDropdownValue>
          </StyledTemplateWrapper>
        </Box>
      )}
      {!isCategoryDrivenTemplatesEnabled && (
        <DropdownSelect
          noDivider
          title={templatesListTitle}
          searchPlaceholder={templatesListSearchPlaceholder}
          options={templatesList}
          selection={selection}
          handleChange={handleChange}
          handleClose={handleClose}
          anchor={anchor}
          handleOpen={handleOpen}
          sortBy="value"
          backendQueryConfig={backendQueryConfig}
        />
      )}
      <StyledActions>
        <CustomIconButton
          tabIndex={0}
          role="button"
          className="icon-wrapper"
          btnTheme="mediumIconButton"
          onClick={() => handleClick(FloatingTicketInstanceModes.COLLAPSED)}
          onKeyDown={() => handleClick(FloatingTicketInstanceModes.COLLAPSED)}
          data-testid="minimize"
          icon={<MinimizeDown />}
        />
        <CustomIconButton
          tabIndex={0}
          role="button"
          className="icon-wrapper"
          btnTheme="mediumIconButton"
          onClick={handlePanelModeChange}
          onKeyDown={handlePanelModeChange}
          data-testid="expand"
          icon={<Expand />}
        />
        <CustomIconButton
          tabIndex={0}
          role="button"
          className="icon-wrapper"
          btnTheme="mediumIconButton"
          onClick={onCloseClicked}
          onKeyDown={onCloseClicked}
          data-testid="close"
          icon={<Close />}
        />
      </StyledActions>
    </StyledFloatingHeader>
  );
}
