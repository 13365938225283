import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import { ReactComponent as CheckboxOff } from 'images/icons/roundCheckBox.svg';
import { ReactComponent as CheckboxOnIcon } from 'images/icons/roundCheckBoxChecked.svg';
import DeleteButton from 'features/attachments/deleteAttachment';

import {
  StyledAttachmentDetails,
  StyledAttachmentActions,
  StyledDownloadIcon,
  StyledTrashIcon,
  StyledUsername,
  StyledTimestamp,
  StyledCheckBox,
  StyledAvatar,
} from './style';

const MetadataOverlay = (props) => {
  const {
    username,
    fileName,
    attachmentId,
    subResourceType,
    fileId,
    timestamp,
    toggleCheck,
    isChecked,
    handleDownload,
    handleDelete,
    attachmentUsageCount,
    disabled,
    isScreenCapture,
    isActionItem,
    screenCaptureType,
    isTicketLocked,
  } = props;

  return (
    <>
      {!isScreenCapture && !isActionItem && (
        <StyledAttachmentDetails className="attachment-details">
          <div className="user-details">
            <StyledAvatar>
              <BasicAvatar type="user" size="small" />
            </StyledAvatar>
            <StyledUsername>{username}</StyledUsername>
          </div>
          <div className="timestamp-details">
            <StyledTimestamp>{timestamp}</StyledTimestamp>
          </div>
          <StyledCheckBox onClick={toggleCheck}>{isChecked ? <CheckboxOnIcon /> : <CheckboxOff />}</StyledCheckBox>
        </StyledAttachmentDetails>
      )}
      <StyledAttachmentActions isActionItem={isActionItem} onClick={(e) => e.stopPropagation()}>
        <StyledDownloadIcon
          onClick={handleDownload}
          data-cy={`attachment-uploader-thumbnail-download-button-${attachmentId}`}
          data-testid="download-button"
          className="download-btn"
        />
        {!disabled && (
          <DeleteButton
            fileId={fileId}
            attachmentId={attachmentId}
            subResourceType={subResourceType}
            fileName={fileName}
            isScreenCapture={isScreenCapture}
            screenCaptureType={screenCaptureType}
            onAttachmentDeleted={handleDelete}
            attachmentUsageCount={attachmentUsageCount}
            data-cy={`attachment-uploader-thumbnail-delete-button-${attachmentId}`}
            className="delete-btn"
            disabled={isTicketLocked}
          >
            <StyledTrashIcon />
          </DeleteButton>
        )}
      </StyledAttachmentActions>
    </>
  );
};
export default MetadataOverlay;
