import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

const customStyle = (isCustomStyles, disabled, theme, isSuggestedCategoryChip, isEmptyRequiredValue) => {
  let customStyles = {};
  let hoveredBackground = theme.palette.background.bg_hover_main;
  if (!disabled) {
    hoveredBackground = theme.palette.background.bg_hover_alt;
  }

  if (isCustomStyles) {
    customStyles = {
      padding: '4px 8px',
      borderRadius: theme.shape.border.borderRadius,
      '&:hover': {
        cursor: !disabled ? 'pointer!important' : 'default!important',
        backgroundColor: hoveredBackground,
        '& .warning-icon *': {
          fill: `${theme.palette.labels.bt_red_dark} !important`,
        },
        '& .multi-select-field': {
          backgroundColor: hoveredBackground,
        },

        '&': isEmptyRequiredValue && {

          '& .text span': {
            color: `${theme.palette.labels.bt_red_dark} !important`,
          },
          '& .multi-select-field': {
            backgroundColor: `${theme.palette.labels.bt_red_mid} !important`,
            '& .empty-multi-select-chip-placeholder': {
              color: `${theme.palette.labels.bt_red_dark} !important`,
            },
          },
        },
      },
    };
  }

  if (isSuggestedCategoryChip) {
    customStyles = { ...customStyles, width: 'fit-content', maxWidth: 'calc(100% - 9px)' };
  }

  return customStyles;
};

const hoverStylesCategoryItem = ({ disabled, forceHover }) =>
  forceHover
    ? {
        cursor: 'inherit',
        backgroundColor: !disabled && '#9FD0E0',
      }
    : {};

const domIgnoreProps = [
  'isCustomStyles',
  'isEmptyRequiredValue',
  'isDefault',
  'isCapitalized',
  'required',
  'isSuggestedCategoryChip',
  'isChipItem',
  'forceHover',
];

export const StyledItem = styled(Box, {
  shouldForwardProp: (prop) => !domIgnoreProps.includes(prop),
})(
  ({
    theme,
    isDefault,
    isCustomStyles,
    disabled,
    required,
    isEmptyRequiredValue,
    isSuggestedCategoryChip,
    forceHover,
    isCapitalized,
    isChipItem,
    isSROpenedFromQueue,
  }) => {
    const expandedStyles = !isChipItem && {
      backgroundColor: `${theme.palette.background.bg_hover_main} !important`,

      '& span[data-error-tooltip=true], .warning-icon': {
        display: 'none',
      },
      '& .text span': {
        color: `${theme.palette.text.txt_default} !important`,
      },
      '& .multi-select-field.expanded': {
        backgroundColor: `${theme.palette.background.bg_hover_main} !important`,
        '& .empty-multi-select-chip-placeholder': {
          color: `${theme.palette.text.txt_default} !important`,
        },
      },
    };
    const defaultCursorStyle = !disabled ? 'pointer!important' : 'default!important';
    const cursorStyle = isSROpenedFromQueue ? 'pointer!important' : defaultCursorStyle;

    return {
      boxSizing: 'border-box',
      maxWidth: '100%',
      width: isCustomStyles ? '100%' : 'unset',
      color: theme?.palette?.labels?.bt_red_reg,
      ...customStyle(isCustomStyles, disabled, theme, isSuggestedCategoryChip, isEmptyRequiredValue),
      '& .chip': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        cursor: cursorStyle,
        width: 'inherit',
      },
      '& .text': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: theme.fonts.robotoBold,
        fontStyle: 'normal',
        fontSize: '12px',
        width: '100%',
        lineHeight: '16px',
        color: isDefault ? theme.palette.text.txt_default : theme?.palette?.generic?.primary,
        texOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        letterSpacing: '0.0035em',
        maxWidth: 'inherit',
        '& p': {
          fontSize: '12px',
        },
        '& span': {
          textAlign: isCustomStyles ? 'left' : 'center',
          color: isEmptyRequiredValue && `${theme.palette.labels.bt_red_reg} !important`,
          textTransform: isCapitalized ? 'capitalize' : 'unset',
        },
        '&:hover': {
          cursor: 'inherit',
        },
        '&.ai': {
          display: 'flex',
        },
      },
       '& .primaryCategoryItem,.secondaryCategoryItem, .thirdLevelCategoryItem ': {
         '& .valueClass_undefined': {
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           minWidth: '109px',
           padding: '4px 12px',
           minHeight: '24px',
           background: '#FFFFFF',
           border: '1px solid',
           borderColor: required ? theme.palette.labels.bt_red_reg : theme?.palette?.grey?.bt_def_grey_reg,
           borderRadius: '16px',
           cursor: 'pointer',
           '& .text': {
             '.MuiTypography-root': {
               color: required ? theme.palette.labels.bt_red_reg : theme?.palette?.grey?.bt_def_grey_reg,
               width: '100%',
               '&:hover': {
                 color: required ? theme?.palette?.labels?.bt_red_hover : theme.palette.text.txt_default,
               },
             },
           },
           '&:hover': {
             backgroundColor: required ? theme?.palette?.labels.bt_red_light : theme.palette.grey.bt_alt_grey_light,
             background: required && theme?.palette?.labels?.bt_alt_grey_light,
             borderColor: required ? theme?.palette?.labels?.bt_red_hover : theme.palette.text.txt_default,
             '.MuiTypography-root': {
               color: required ? theme?.palette?.labels?.bt_red_hover : theme.palette.text.txt_default,
             },
           },
         },
       },
      '& .categoryItem': {
        fontSize: '0.75rem',
        fontFamily: 'Roboto-Regular',
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(2),
        backgroundColor: '#C9EDF9',
        height: theme.spacing(3),
        boxSizing: 'border-box',
        '&:hover': {
          cursor: 'inherit',
          backgroundColor: !disabled && '#9FD0E0',
        },
        '@media only screen and (max-width: 1920px)': {
          maxWidth: '160px',
        },
        '@media only screen and (max-width: 1440px)': {
          maxWidth: '140px',
        },
        '@media only screen and (max-width: 1280px)': {
          maxWidth: '120px',
        },
        ...hoverStylesCategoryItem({ disabled, forceHover }),
      },
      '& .PriorityItem': {
        maxWidth: 'inherit',
        '& .chip': {
          borderRadius: '12px',
          cursor: 'inherit',
          padding: '4px 12px',
          margin: '0 8px',
          '& .text .MuiTypography-root': {
            fontFamily: theme.fonts.robotoBold,
          },
        },
        '& .valueClass_0': {
          backgroundColor: theme?.palette?.blueLabels?.bt_light_blue_reg,
          '&:hover': {
            backgroundColor: !disabled && theme.palette.blueLabels.bt_light_blue_hover,
          },
        },
        '& .valueClass_1': {
          backgroundColor: theme?.palette?.labels.bt_yellow_reg,
          '&:hover': {
            backgroundColor: !disabled && theme.palette.labels.bt_yellow_hover,
          },
        },
        '& .valueClass_2': {
          backgroundColor: theme?.palette?.labels?.bt_red_reg,
          '&:hover': {
            backgroundColor: !disabled && theme.palette.labels.bt_red_hover,
          },
        },
        // '& .valueClass_undefined': {
        //   background: 'transparent',
        //   border: `1px solid ${theme?.palette?.labels.bt_red_reg}`,
        //   '&.chip .text .MuiTypography-root': {
        //     fontFamily: theme.fonts.robotoRegular,
        //   },
        //   '&:hover': {
        //     background: !disabled && theme?.palette?.labels.bt_red_light,
        //     border: `1px solid ${theme?.palette?.labels.bt_red_hover}`,
        //     '&.chip .text .MuiTypography-root': {
        //       color: `${theme?.palette?.labels.bt_red_hover} !important`,
        //     },
        //   },
        // },
      },

      '& .StatusItem': {
        maxWidth: 'inherit',

        '& .chip': {
          borderRadius: '4px',
          padding: '4px 12px',
          margin: '0 8px',
          '& .text .MuiTypography-root': {
            fontFamily: theme.fonts.robotoBold,
          },
        },

        '& .valueClass_0': {
          background: theme?.palette?.blueLabels?.bt_status_reg,

          '&:hover': {
            background: !disabled && theme?.palette?.blueLabels.bt_status_hover,
          },
        },
        // '& .valueClass_undefined': {
        //   background: 'transparent',
        //   border: `1px solid ${theme?.palette?.labels.bt_red_reg}`,
        //   '&.chip .text .MuiTypography-root': {
        //     fontFamily: theme.fonts.robotoRegular,
        //     color: `${theme?.palette?.labels.bt_red_reg} !important`,
        //   },
        //   '&:hover': {
        //     background: !disabled && theme?.palette?.labels.bt_red_light,
        //     border: `1px solid ${theme?.palette?.labels.bt_red_hover}`,
        //     '&.chip .text .MuiTypography-root': {
        //       color: `${theme?.palette?.labels.bt_red_hover} !important`,
        //     },
        //   },
        // },

        '& .valueClass_1, .valueClass_2': {
          background: theme?.palette?.text?.secondary,

          '&:hover': {
            background: !disabled && theme?.palette?.text?.title,
          },
        },
      },
      '.TypeItem': {
        '.chip': {
          margin: '0 8px',
        },
      },

      '& .warning-icon': {
        display: 'flex',
        margin: '0 2px',
      },
      '&.expanded': {
        ...expandedStyles,
        // '& .StatusItem, .PriorityItem': {
        //   '& .valueClass_undefined': {
        //     background: !disabled && theme?.palette?.labels.bt_red_light,
        //     border: `1px solid ${theme?.palette?.labels.bt_red_hover}`,
        //     '&.chip .text .MuiTypography-root': {
        //       color: `${theme?.palette?.labels.bt_red_hover} !important`,
        //     },
        //   },
        // },
      },
      '&:has(.expanded)': {
        ...expandedStyles,
      },
      '& .missing-required-field': {
        width: '100%',
        height: '16px',
      },
    };
  },
);
