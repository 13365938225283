import { useRef } from 'react';
import PropTypes from 'prop-types';

import { StyledAttachments, StyledEditorAttachments } from './style';
import { MoreAttachmentsWrapper } from './MoreAttachmentsWrapper';

export const Attachments = (props) => {
  const {
    attachments,
    isReadOnly,
    isDeleted,
    isDownloaded,
    shouldTriggerDeleteApi,
    onAttachmentDeleted,
    deleteAttachmentEnabled,
    srPanelCollapsed,
  } = props;
  const wrapperRef = useRef(null);

  return !isReadOnly ? (
    <StyledEditorAttachments>
      <MoreAttachmentsWrapper
        attachments={attachments}
        isReadOnly={isReadOnly}
        isDeleted={isDeleted}
        isDownloaded={isDownloaded}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        onAttachmentDeleted={onAttachmentDeleted}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        noButton="true"
        srPanelCollapsed={srPanelCollapsed}
      />
    </StyledEditorAttachments>
  ) : (
    <StyledAttachments ref={wrapperRef}>
      <MoreAttachmentsWrapper
        isReadOnly={isReadOnly}
        wrapperRef={wrapperRef}
        attachments={attachments}
        isDeleted={isDeleted}
        isDownloaded={isDownloaded}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        onAttachmentDeleted={onAttachmentDeleted}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        srPanelCollapsed={srPanelCollapsed}
      />
    </StyledAttachments>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
  isReadOnly: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isDownloaded: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
};

Attachments.defaultProps = {
  attachments: [],
  onAttachmentDeleted: () => {},
  isReadOnly: true,
  isDeleted: false,
  isDownloaded: false,
};
