const GRID_MODELS = {
  Realtime: 'REALTIME',
  Zen: 'ZEN',
  UnvisibleTab: 'UNVISIBLE_TAB',
};

const GRID = {
  BLOCK_SIZE: 50,
  MAX_ROWS_ALLOWED_NUM: 1000,
  MAX_ROWS_MESSAGE_TIMEOUT: 2000,
  REFETCH_INTERVAL_MILLIS: 10000,
  REFETCH_TIMEOUT_MILLIS: 2000,
  REFETCH_TIMEOUT_MILLIS_WITHIN_WEBSOCKET: 600000, //10 minutes in milliseconds
  ROWS_PER_SCREEN: 25,
  RENDER_DATA_TIME: 1500,
};

const COLUMN_FILTER_TYPES = {
  assignee: 'assignee',
  assignedGroup: 'assignedGroup',
  none: 'none',
  equals: 'equals',
  contains: 'contains',
  dynamicTime: 'dynamicTime',
  inRange: 'inRange',
};

const COLUMN_FILTER_CATEGORY = {
  primaryCategory: 'primaryCategory',
  secondaryCategory: 'secondaryCategory',
  thirdLevelCategory: 'thirdLevelCategory',
};

const COLUMN_FILTER_ASSIGNEE = 'assignee';
const COLUMN_FILTER_ASSIGNEE_GROUP = 'assignedGroup';
const COLUMN_FILTER_INSERT_TIME = 'insertTime';
const COLUMN_FILTER_DUE_DATE = 'dueDate';
const COLUMN_FILTER_REQUEST_USER = 'requestUser';
const COLUMN_FILTER_TITLE = 'title';
const COLUMN_FILTER_STATUS = 'status';

const COLUMN_FILTER_FIELDTYPE = {
  category: 'category',
  assignee: 'text',
  number: 'number',
  set: 'set',
  date: 'date',
  user: 'user',
  requestUser: 'user',
};

const COLUMN_FILTER_FIELD = {
  firstLevelKey: 'firstLevelKey',
  secondLevelKey: 'secondLevelKey',
  thirdLevelKey: 'thirdLevelKey',
};

const COLUMN_MENU = 'COLUMN_MENU';

const FILTER_TYPE_CONFIGURATIONS = {
  1: { filterType: 'text', type: 'contains' },
  2: { filterType: 'text', type: 'contains' },
  3: { filterType: 'number', type: 'equals' },
  4: { filterType: 'set', type: 'equals' },
  5: { filterType: 'set', type: 'equals' },
  6: { filterType: 'set', type: 'equals' },
  7: { filterType: 'set', type: 'equals' },
  11: { filterType: 'set', type: 'equals' },
};

const FIELD_TYPES = {
  1: 'TEXT',
  2: 'LONGTEXT',
  3: 'NUMBER',
  4: 'DATE',
  5: 'TIME_REMAINING',
  6: 'SELECT',
  7: 'MULTISELECT',
  8: 'ATTACHMENT',
};

const STATUS_CLASSES_NAMES = {
  0: 'spaces.statusClass.open',
  1: 'spaces.statusClass.closed',
};

const SAVE_ERRORS = {
  notSavedError: 'notSavedError',
  isSaveStatusError: 'isSaveStatusError',
  isSaveFieldsPermissionError: 'isSaveFieldsPermissionError',
};

const DROP_DOWN_DELAY = 200;

const ID_FIELD_ID = 2;
const STATUS_FIELD_ID = 8;
const SR_TYPE_FIELD_ID = 32;
const ASSIGNEE_FIELD_ID = 62;
const ASSIGNED_GROUP_FIELD_ID = 63;
const REQUEST_USER_FIELD_ID = 16;
const USER_MANAGER_FIELD_ID = 17;
const UPDATE_USER_FIELD_ID = 13;
const RESPONSIBLE_MANAGER_FIELD_ID = 64;
const CC_USER_FIELD_ID = 29;
const FOLLOWUP_USER_FIELD_ID = 39;

const CLOSED_CLASS = 1;
const STATUS_ERROR = 422;
const PERMISSION_ERROR = 403;
const UNRESOLVED_ERROR = 409;

export {
  GRID,
  GRID_MODELS,
  COLUMN_FILTER_TYPES,
  COLUMN_MENU,
  COLUMN_FILTER_CATEGORY,
  COLUMN_FILTER_ASSIGNEE,
  COLUMN_FILTER_STATUS,
  COLUMN_FILTER_REQUEST_USER,
  COLUMN_FILTER_DUE_DATE,
  COLUMN_FILTER_INSERT_TIME,
  COLUMN_FILTER_ASSIGNEE_GROUP,
  COLUMN_FILTER_FIELDTYPE,
  COLUMN_FILTER_FIELD,
  COLUMN_FILTER_TITLE,
  FIELD_TYPES,
  FILTER_TYPE_CONFIGURATIONS,
  DROP_DOWN_DELAY,
  STATUS_CLASSES_NAMES,
  SAVE_ERRORS,
  ID_FIELD_ID,
  STATUS_FIELD_ID,
  SR_TYPE_FIELD_ID,
  ASSIGNEE_FIELD_ID,
  ASSIGNED_GROUP_FIELD_ID,
  REQUEST_USER_FIELD_ID,
  USER_MANAGER_FIELD_ID,
  UPDATE_USER_FIELD_ID,
  CC_USER_FIELD_ID,
  RESPONSIBLE_MANAGER_FIELD_ID,
  FOLLOWUP_USER_FIELD_ID,
  CLOSED_CLASS,
  STATUS_ERROR,
  PERMISSION_ERROR,
  UNRESOLVED_ERROR,
};
