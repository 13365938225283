import { styled } from '@mui/material/styles';
import { Box, Card, CardHeader, CardContent, CardActions } from '@mui/material';

import XButton from 'common/components/xButton';

export const StyledMeMenu = styled(Card)(({ theme }) => ({
  width: '657px',
  overflow: 'auto',
  ...theme.mixins.scrollbar,
  height: '100vh',
  maxHeight: '926px',
  boxShadow: '0 0 30px 0 rgba(93, 109, 134, .1)',
  '.MuiDivider-root': {
    borderColor: theme.palette.grey.divider_grey_v2,
  },
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: 0,

  ' .MuiCardHeader-avatar': {
    padding: theme.spacing(0, 1.5, 2.75, 3),
    margin: 0,
  },

  ' .MuiCardHeader-content': {
    overflow: 'auto',
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(2),
  },

  ' .MuiCardHeader-title': {
    paddingBottom: theme.spacing(1),

    span: {
      textAlign: 'start',
      fontSize: '20px',
      lineHeight: '24px',
      fontFamily: 'Roboto-Regular',
    },
  },

  ' .MuiCardHeader-action': {
    padding: theme.spacing(2, 3.75, 0, 1.5),
    margin: 0,
  },
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
  minHeight: '668px',
  '& .MuiDivider-root': {
    marginBlock: theme.spacing(1.5),
  },
}));

export const StyledXButton = styled(XButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  display: 'flex',
  justifyContent: 'end',

  '.MuiIconButton-root': {
    padding: theme.spacing(0.75),

    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
}));

const StyledButton = styled(Box)(({ theme }) => ({
  '.MuiButton-root': {
    textTransform: 'unset',
    fontFamily: theme.fonts.robotoRegular,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.generic.sysaid_default,
    padding: theme.spacing(0.25, 0.75),

    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

export const StyledSelfServicePortalButton = styled(StyledButton)(({ theme }) => ({
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  },

  a: {
    textDecoration: 'none',
  },
}));

export const StyledSwitchToClassicButton = styled(StyledButton)(({ theme }) => ({
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  },
}));

export const StyledLogoutButton = styled(StyledButton)(({ theme }) => ({
  '.MuiButton-root': {
    padding: 0,
    paddingBottom: theme.spacing(0.25),
    justifyContent: 'flex-start',
  },
}));

export const StyledFooter = styled(CardActions)(({ theme }) => ({
  justifyContent: 'flex-end',
  padding: theme.spacing(3),
}));
