import { styled } from '@mui/material/styles';
import { defaultFileIcon } from 'images/icons/attachments';

export const StyledContainer = styled('div')(({ theme, visible, isActionItem }) => ({
  width: isActionItem ? '190px' : '170px',
  display: isActionItem && 'flex',
  height: isActionItem && '36px',
  position: isActionItem && 'relative',
  alignItems: isActionItem && 'center',
  justifyContent: isActionItem && 'space-between',
  backgroundColor: isActionItem && '#EDEFF1',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: isActionItem && '#E6E8F0',
  },

  '.metadata-overlay-wrapper': {
    display: visible ? 'flex' : 'none',
  },

  '.attach-thumbnail-text': {
    color: theme.palette.text.secondary_txt,
    marginTop: '8px',
  },
  '.attach-fileUrl-text': {
    color: isActionItem && '#787878',
    fontWeight: isActionItem && 700,
    paddingLeft: '6px',
    flex: isActionItem && '1',
  },
  '.attachment-uploader-thumbnail > div': {
    position: isActionItem && 'unset',
  },
}));

export const StyledThumbnail = styled('div')(({ fileUrl, isImage, isDefault, isActionItem }) => ({
  position: 'relative',
  height: !isActionItem && '120px',
  backgroundImage: fileUrl && !isDefault ? `url(${fileUrl})` : `url(${defaultFileIcon})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: isImage && !isDefault ? 'cover,70px' : '70px',
  background: isActionItem && 'none',
  backgroundColor: isActionItem ? '#EDEFF1' : '#F5F6FA',
  borderRadius: '6px',
  marginBottom: '4px',
  padding: isActionItem && '6px 0 6px 6px',
  cursor: 'pointer',
  display: 'flex',
  '& > .attach-fileUrl-text': {
    flex: isActionItem && '1',
  },
}));

export const StyledFileTypeLogo = styled('div')(() => ({
  position: 'absolute',
  left: '6px',
  right: '140px',
  top: '6px',
  bottom: '90.9',
  height: '24.08px',
  width: '24px',
  background: '#5380C0',
  borderRadius: '6px',
}));

export const StyledAttachmentUsageCount = styled('div')(({ theme, isImage, isActionItem }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: !isActionItem && 'absolute',
  background: theme.palette.grey.bt_def_grey_reg,
  borderRadius: '50%',
  left: isImage ? '35px' : '6px',
  top: '6px',
  color: 'white',
  fontSize: '11px',
  fontFamily: 'Roboto-Bold',
  padding: '8px',
  height: '24px',
  width: '24px',
}));

export const StyledVideoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
  width: '170px',
  position: 'relative',

  '.metadata-overlay-wrapper': {
    display: visible ? 'flex' : 'none',
  },

  '.attach-thumbnail-text': {
    color: theme?.palette?.text?.secondary_txt,
    marginTop: '8px',
  },

  '.attachment-uploader-thumbnail': {
    background: 'unset',
  },

  video: {
    position: 'absolute',
    width: '100%',
    height: '120px',
    objectFit: 'fill',
    borderRadius: '6px',
  },
}));
