import { useMemo } from 'react';
import { useGetAttachmentByIdQuery } from 'remote-state/attachments';

import { ReactComponent as DefaultFilePreview } from 'images/icons/defaultFilePreview.svg';
import { attachmentIcons } from 'features/attachments/attachmentsList/attachmentIcons';
import { attachmentPreviewTypes } from 'features/attachments/attachmentsList/attachmentPreviewTypes';
import { StyledCarouseItem, StyledImage, StyledNoPreviewContainer, StyledVideo } from './styles';
import useTexts from './useTexts';
import PdfPreview from './PdfPreview';

const CarouselItem = ({ index, file, zoomAmount = 1, isVisible = true, previewUrl }) => {
  const { canNotBePreviewed } = useTexts();
  const { isLoading, metadata: attachmentMetadata } = useGetAttachmentByIdQuery({ attachmentId: file.attachmentId });

  const hasPreview = attachmentPreviewTypes.includes(file.extension);

  let fileExtension;
  if (file.extension || attachmentMetadata?.extension) {
    fileExtension = attachmentIcons[(file.extension || attachmentMetadata?.extension).toLowerCase()];
  }

  const FilePreview = useMemo(() => {
    switch (file.extension) {
      case 'pdf':
        return (
          <PdfPreview
            pdfUrl={previewUrl}
            scale={zoomAmount}
            dataTestId={`${index}-${fileExtension}-preview`}
            dataCy={`${index}-${fileExtension}-preview`}
          />
        );
      case 'txt':
        return <iframe src={previewUrl} width="100%" title="txtAttachmentPreview" />;
      case 'mp4':
      case 'mkv':
      case 'mov':
        return (
          <StyledVideo
            zoomAmount={zoomAmount}
            controls
            data-testid={`${index}-${fileExtension}-video-preview`}
            data-cy={`${index}-${fileExtension}-video-preview`}
          >
            <source src={file.isScreenCapture ? file.src : previewUrl} />
          </StyledVideo>
        )
      default:
        return (
          <StyledImage
            className="carousel-img"
            src={file.isScreenCapture ? file.src : previewUrl}
            zoomAmount={zoomAmount}
            visible={isVisible}
            data-testid={`${index}-${fileExtension}-image-preview`}
            data-cy={`${index}-${fileExtension}-image-preview`}
          />
        );
    }
  }, [file.extension, file.isScreenCapture, file.src, fileExtension, index, isVisible, previewUrl, zoomAmount]);

  return (
    !isLoading && (
      <StyledCarouseItem
        visible={isVisible}
        fileExtension={fileExtension}
        className={hasPreview ? 'carousel-item' : 'carousel-item no-preview-item'}
      >
        {hasPreview ? FilePreview : (
          <StyledNoPreviewContainer data-testid="no-preview" data-cy="no-preview">
            <DefaultFilePreview className="no-preview-icon" />
            <div className="no-preview-text">{canNotBePreviewed}</div>
          </StyledNoPreviewContainer>
        )}
        {isVisible && (
          <div className="carouse-item-title" data-testid="attachment-preview-title" data-cy="attachment-preview-title">
            {file.fileName || attachmentMetadata?.originalName}
          </div>
        )}
      </StyledCarouseItem>
    )
  );
};

export default CarouselItem;
