import { Menu, styled } from '@mui/material';

export const commonMenuStyles = (theme) => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 8px 0px rgba(118, 130, 150, 0.35)',
  },
  '& span': {
    flexGrow: 0,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontSize: theme.fontSize.main,
    lineHeight: 1.25,
    fontFamily: theme.fonts.robotoRegular,
    color: theme.palette.text.title_txt,
  },
  '& .MuiButtonBase-root.MuiMenuItem-root:hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
  },
});

export const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => !['menuStyles'].includes(prop),
})(({ theme, menuStyles }) => ({
  ul: {
    padding: theme.spacing(1),
    li: {
      borderRadius: theme?.shape?.border?.borderRadius,
      padding: '8px',
    },
  },
  ...commonMenuStyles(theme),
  ...menuStyles,
}));

export const StyledActions = styled('div', {
  shouldForwardProp: (props) => props !== 'btnClassName' && props !== 'isActive',
})(({ theme, btnClassName, isActive }) => ({
  '& .MuiButtonBase-root': {
    border: 'none',
    backgroundColor: isActive ? 'rgba(149, 169, 201, 0.30)' : 'transparent',
    cursor: 'pointer',
    padding: '6px',
    height: '32px',
    width: '32px',
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: 'rgba(149, 169, 201, 0.30)',

      '& .icon': {
        fill: theme.palette.icons.icons_grey_v1,
      },
    },
  },

  [`& .${btnClassName}.active`]: {
    display: 'flex',
  },
  '.delete-sr-action-btn': {
    padding: '3px',
    width: '22px',
    height: '22px',
  },
}));
