import { useEffect, useState, useCallback, useRef, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import Assignee from 'common/components/assignee';
import useTexts from 'common/components/assignee/useTexts';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { isNone } from 'common/utils/utils';
import { selectForceShowValidationError } from 'store/fieldValidationsSlice';
import { COMMON_CONSTANTS } from 'constants/common';
import { AssigneeText, StyledAssignees } from './styleAssignees';

const Assignees = (props) => {
  const {
    admins,
    groups,
    fieldId,
    isNewSR,
    onFocus,
    onChange,
    disabled,
    required,
    bigAvatar,
    handleDirty,
    dataset = {},
    isTemplatePage,
    currentAssigned,
    withTitle = true,
    dropdownGroupName,
    dropdownGroupList,
    setDropdownGroupName,
    isFloatingTicketPanel,
    updateFieldValidation,
  } = props;
  const { assigneeText, unassignedText, mandatoryFieldError } = useTexts();
  const [anchorHtmlElement, setAnchorHtmlElement] = useState(null);
  const outerRef = useRef(null);
  const [assigneeValue, setAssigneeValue] = useState(currentAssigned);
  const [isUpdatingSR, setIsUpdatingSR] = useState(false);
  const [isUpdatingAssignee, setIsUpdatingAssignee] = useState(false);
  const [wasAssigneeClicked, setWasAssigneeClicked] = useState(false);
  const forceShowError = useSelector(selectForceShowValidationError);

  const isUnassigned = useMemo(
    () => isNone(assigneeValue?.admin?.userName) && isNone(assigneeValue?.group?.groupName),
    [assigneeValue],
  );

  useEffect(() => {
    setAssigneeValue(currentAssigned);
  }, [currentAssigned]);

  useEffect(() => {
    if (isUpdatingSR !== isUpdatingAssignee) {
      setIsUpdatingSR(isUpdatingAssignee);
    }
  }, [isUpdatingAssignee, isUpdatingSR]);

  const prevIsUpdatingSR = usePreviousValue(isUpdatingSR);
  const prevCurrentAssigned = usePreviousValue(currentAssigned);

  useEffect(() => {
    if (prevIsUpdatingSR !== isUpdatingSR && !isUpdatingSR && prevCurrentAssigned !== currentAssigned) {
      setAssigneeValue(currentAssigned);
    }
  }, [prevIsUpdatingSR, isUpdatingSR, prevCurrentAssigned, currentAssigned]);

  const checkAndUpdateValidationStatus = useCallback(
    (admin, group) => {
      let isValid = true;
      const adminValid = admin && admin.userName && admin.userName !== 'none';
      const groupValid = group && group.groupName && group.groupName !== 'none';
      if (required && !adminValid && !groupValid) {
        isValid = false;
      }
      if (updateFieldValidation) {
        updateFieldValidation('assignee', required, isValid);
      }
      return isValid;
    },
    [required, updateFieldValidation],
  );

  const prevAssignee = usePreviousValue(assigneeValue);
  const prevRequired = usePreviousValue(required);

  useEffect(() => {
    if (prevAssignee !== assigneeValue || prevRequired !== required) {
      checkAndUpdateValidationStatus(assigneeValue?.admin, assigneeValue?.group);
    }
  }, [prevAssignee, assigneeValue, prevRequired, required, checkAndUpdateValidationStatus]);

  const handleChangeAssignees = async (admin, group) => {
    setIsUpdatingAssignee(true);
    const isSameValue =
      admin?.userName &&
      admin?.userName === assigneeValue?.admin?.userName &&
      group?.groupName &&
      group?.groupName === assigneeValue?.group?.groupName;
    let updateAssigneeValue = { assignee: null, assignedGroup: null };
    let key;

    if (!isSameValue) {
      checkAndUpdateValidationStatus(admin, group);
      const adminSelected = admin && admin.userName;
      const groupSelected = group && group.groupName;
      let isAssigneeChanged = false;
      if (adminSelected && groupSelected) {
        updateAssigneeValue = { assignedGroup: group?.groupName, assignee: admin.userName };
        isAssigneeChanged = true;
        key = 'assignedGroup_assignee';
      } else if (groupSelected) {
        updateAssigneeValue = { assignedGroup: group?.groupName, assignee: COMMON_CONSTANTS.NONE };
        isAssigneeChanged = true;
        key = 'assignedGroup';
      } else if (adminSelected) {
        updateAssigneeValue = { assignedGroup: COMMON_CONSTANTS.NONE, assignee: admin.userName };
        isAssigneeChanged = true;
        key = 'assignee';
      } else if (!adminSelected && !groupSelected) {
        updateAssigneeValue = { assignee: null, assignedGroup: null };
        setAssigneeValue({ assignee: null, assignedGroup: null });
        isAssigneeChanged = true;
        key = 'assignedGroup_assignee';
      } else {
        setAssigneeValue({ assignee: null, assignedGroup: null });
      }
      if (isAssigneeChanged) {
        onChange(updateAssigneeValue, key);
      }
    }

    if (handleDirty) {
      handleDirty({ assignee: updateAssigneeValue?.assignee });
      handleDirty({ assignedGroup: updateAssigneeValue?.assiassignedGroupgnee });
    }
    setIsUpdatingAssignee(false);
  };

  const displayAssigneeText = () => {
    if (!isNone(assigneeValue?.admin?.calculatedUserName)) {
      return assigneeValue?.admin?.calculatedUserName;
    }
    if (!isNone(assigneeValue?.group?.groupName)) {
      return assigneeValue?.group?.groupName;
    }
    return unassignedText;
  };

  const handleAssigneeClicked = () => {
    if (!wasAssigneeClicked) {
      setWasAssigneeClicked(true);
    }
  };

  const hasAssigneeValue = () => {
    let hasValue = true;
    if (
      (assigneeValue?.admin?.id === null || assigneeValue?.admin?.id === undefined) &&
      (assigneeValue?.group?.id === null || assigneeValue?.group?.id === undefined)
    ) {
      hasValue = false;
    }
    return hasValue;
  };

  const getClassName = () => {
    if ((isUnassigned && required && !isNewSR) || (isNewSR && wasAssigneeClicked && required && !hasAssigneeValue())) {
      return 'assignees unassigned';
    }
    return 'assignees';
  };

  const handleUpdateAssigneeState = useCallback(
    (currentAssigned) => {
      if (assigneeValue.assignee || assigneeValue.assignedGroup) {
        setAssigneeValue(currentAssigned);
      }
    },
    [assigneeValue],
  );

  useEffect(() => {
    handleUpdateAssigneeState(currentAssigned);
  }, [currentAssigned, handleUpdateAssigneeState]);

  const notValid = useMemo(() => {
    const isInvalid = isUnassigned && required;
    const newFieldError = isNewSR && isInvalid && forceShowError;
    const existingFieldError = !isNewSR && isInvalid;
    return newFieldError || existingFieldError;
  }, [forceShowError, isNewSR, isUnassigned, required]);

  const assigneeTextComponent = (isSelected) => (
    <AssigneeText
      isTruncatedText
      isError={notValid}
      isSelected={isSelected}
      text={displayAssigneeText()}
      className="current-assignee"
      onClick={() => setAnchorHtmlElement(outerRef?.current)}
      dataset={{
        'data-testid': 'assignees-tooltip',
      }}
    />
  );

  return (
    <StyledAssignees
      isError={notValid}
      isFloatingTicketPanel={isFloatingTicketPanel}
      disabled={disabled}
      className={getClassName()}
      isTemplatePage={isTemplatePage}
      {...dataset}
    >
      <Assignee
        assigneeTitle={withTitle && assigneeText}
        tooltipTitle={!isTemplatePage && notValid ? mandatoryFieldError : ''}
        currentAssigned={assigneeValue}
        required={required}
        notValid={notValid}
        bigAvatar={bigAvatar}
        handleChangeAssignees={handleChangeAssignees}
        disabled={disabled}
        adminList={admins}
        groupList={groups}
        dropdownGroupName={dropdownGroupName}
        setDropdownGroupName={setDropdownGroupName}
        dropdownGroupList={dropdownGroupList}
        anchorHtmlElementOuter={anchorHtmlElement}
        handleAssigneeClicked={handleAssigneeClicked}
        assigneeTextComponent={assigneeTextComponent}
        fieldId={fieldId}
        onClick={onFocus}
      />
    </StyledAssignees>
  );
};
export default memo(Assignees);
