import { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { DialogContentText } from '@mui/material';
import { getFieldAttributes } from 'services/ticketService';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFieldAttributes, useUpdateSR } from 'remote-state/ticketServiceHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { convertFromClientFieldId, getValueKeyByFieldName } from 'common/utils/fieldUtils';
import useDelayHover from 'common/utils/hooks/useDelayHover';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import Tooltip from 'common/components/tooltip';
import User from 'common/components/user';
import { setOnPopupAdvanceMessage } from 'store/globalSlice';
import MessagePrompt from 'common/components/messagePrompt';
import { PromptTextField } from 'common/components/messagePrompt/contextTypes/TextField';
import { messagePromptStyle } from 'common/components/priority/style';
import useTexts from 'common/components/priority/useTexts';
import { ConditionalWrapper, mergeArraysByKey, toDisplayUser } from 'common/utils/utils';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import { QUERIES_KEYS } from 'constant';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { convertValue } from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';
import useStatusTexts from 'common/components/status/useTexts';
import MultiSelectField from 'features/queue/grid/defaultCellRender/MultiSelectField';
import useQueueTexts from 'features/queue/grid/useTexts';
import useValidateSrLockBeforeUpdate from '../../hooks/useValidateSrLockBeforeUpdate';
import { StyledItem } from './SelectRender.styles';
import { BarLoader } from '../SkeletonLoader';
import { selectSelectRenderValue } from '../../../slice';

const extractValueAndReason = (valueObject, keyString) => {
  if (valueObject) {
    const { value, reason } = valueObject;
    let valueToUpdate = value;
    if (Array.isArray(value)) {
      valueToUpdate = value
        .filter((el) => el !== '')
        .map((item) => item[keyString])
        ?.toString();
    } else if (typeof value === 'object' && value !== null) {
      valueToUpdate = value[keyString];
    }
    return { value: valueToUpdate, reason };
  }
  return { value: null, reason: null };
};

const findSelectedItem = (selectedItem, keyString) => {
  if (!selectedItem) return [];
  if (Array.isArray(selectedItem)) {
    return selectedItem.map((item) => item[keyString]);
  }
  return [selectedItem[keyString]];
};

export const SelectRender = memo((props) => {
  const {
    srId,
    value,
    style,
    srType = 1,
    fieldId,
    customColumn,
    fieldName,
    captionString,
    required,
    delay = 0,
    isMultiple,
    headerName,
    hoverEffect,
    delayedHover,
    className = '',
    disabled = false,
    sortBy,
    notInTemplate,
  } = props;
  const fieldAttributesInput = useMemo(() => [{ fieldId, srType, customColumn }], [fieldId, srType, customColumn]);
  const { data: fieldAttributes, isError } = useFieldAttributes(fieldAttributesInput, false, srId)?.[0] || {};
  const values = fieldAttributes?.values;

  const [dropdownList, setDropdownList] = useState(values || []);
  const [showPrompt, setShowPrompt] = useState(false);
  const [priorityUpdateReason, setPriorityUpdateReason] = useState('');
  const [updatedValue, setUpdatedValue] = useState(value);
  const [anchor, setAnchor] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const timeout = useRef();
  const hoverRef = useRef();
  const labelRef = useRef(null);

  const dispatch = useDispatch();
  const forcedSelectRenderValue = useSelector(selectSelectRenderValue);

  const {
    popupTitle,
    popupDescription,
    popupInputPlaceholder,
    popupOkBtnText,
    mandatoryFieldError,
    emptyStatusPlaceholder,
    emptyPriorityPlaceholder,
  } = useTexts();
  useDelayHover(hoverRef, delayedHover, hoverEffect);
  const { checkAndUpdateSr } = useUpdateSR();
  const {
    data: { manualPriorityChangesEnabled },
  } = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  const { statusRequiredFieldsTooltipMessage } = useStatusTexts();
  const { notExistInTemplateMessage } = useQueueTexts();

  const { data: isSROpenedFromQueue } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.OPEN_SR_FROM_QUEUE,
    defaultValue: false,
  });

  useEffect(() => {
    setDropdownList(values || []);
  }, [values]);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  useEffect(() => () => clearTimeout(timeout.current), []);

  useEffect(() => {
    const { srId: targetSrId, fieldName: targetFieldName, fieldValue } = forcedSelectRenderValue;

    if (targetSrId === srId && targetFieldName === fieldName) {
      checkAndUpdateSr({ requestParams: { [targetFieldName]: fieldValue }, id: targetSrId });
      setUpdatedValue(fieldValue);
    }
  }, [forcedSelectRenderValue, srId, fieldName, checkAndUpdateSr]);

  const parsedValue = useMemo(() => {
    let value = updatedValue;
    if (updatedValue) {
      if (isMultiple) {
        value = updatedValue.split(',');
      }
      if (typeof updatedValue === 'object') {
        value = convertValue(updatedValue);
      }
    }
    return value;
  }, [updatedValue, isMultiple]);

  const keyString = getValueKeyByFieldName(fieldName);

  let valueId;
  if (isMultiple) {
    valueId = parsedValue;
  } else if (typeof parsedValue === 'object') {
    valueId = parsedValue?.[keyString];
  } else {
    valueId = parsedValue;
  }

  const { data: selectedItem, loaded: islistValueLoaded } = useGetListValueById({
    listKey: fieldId,
    customColumn,
    list: dropdownList && dropdownList.length > 0 ? dropdownList : values,
    id: valueId,
    isMultiple,
  });

  const isPriorityColumn = fieldName === 'priority';
  const isStatusColumn = fieldName === 'status';
  const isChipItem = isStatusColumn || isPriorityColumn;

  const getPlaceholder = () => {
    if (isStatusColumn) {
      return emptyStatusPlaceholder;
    }
    if (isPriorityColumn) {
      return emptyPriorityPlaceholder;
    }
    if (required && !valueId) {
      return '';
    }
    return selectedItem?.placeholder || '--';
  };

  const handleOpen = useCallback(() => {
    if (!disabled) {
      timeout.current = setTimeout(() => {
        setAnchor(labelRef.current);
      }, delay);
    }
  }, [labelRef, disabled, delay]);

  const handleClose = useCallback(() => setAnchor(null), []);

  const handleSaveSR = useCallback(
    async (valueObject) => {
      const { value, reason } = extractValueAndReason(valueObject, keyString);
      const object = { [fieldName]: value };
      const objectToUpdate = {
        requestParams: object,
        id: srId,
        queueUpdate: true,
      };
      handleClose();
      const res = await checkAndUpdateSr(objectToUpdate, reason);
      if (!isMultiple && res) {
        const resValue = res.data?.sr?.[fieldName];
        if (
          resValue &&
          selectedItem?.[keyString] !== (typeof resValue === 'object' ? convertValue(resValue)[keyString] : resValue)
        ) {
          setUpdatedValue(resValue);
        }
      }
    },
    [srId, fieldName, keyString, handleClose, checkAndUpdateSr, isMultiple, selectedItem],
  );

  const { handleUpdateSR } = useValidateSrLockBeforeUpdate({ srId, handleSaveSR });

  const updateMultiSelectField = useCallback(() => {
    handleUpdateSR({ value: selectedItem });
  }, [handleUpdateSR, selectedItem]);

  const handleChange = useCallback(
    (value, optionsMap) => {
      if (optionsMap) setDropdownList(Object.values(optionsMap));
      if (isMultiple) {
        setUpdatedValue(value.toString());
      } else if (manualPriorityChangesEnabled && fieldName === 'priority') {
        if (value.valueKey !== selectedItem?.valueKey) {
          setUpdatedValue(value);
          dispatch(setOnPopupAdvanceMessage(true));
          setShowPrompt(true);
        }
      } else {
        handleUpdateSR({ value });
      }
    },
    [manualPriorityChangesEnabled, fieldName, isMultiple, handleUpdateSR, dispatch, selectedItem?.valueKey],
  );

  const handleOkClickInPrompt = useCallback(() => {
    if (priorityUpdateReason) {
      handleUpdateSR({ value: updatedValue, reason: priorityUpdateReason });
    }
    dispatch(setOnPopupAdvanceMessage(false));
    setPriorityUpdateReason('');
    setShowPrompt(false);
  }, [updatedValue, priorityUpdateReason, handleUpdateSR, dispatch]);

  const handleClosePrompt = () => {
    dispatch(setOnPopupAdvanceMessage(false));
    setPriorityUpdateReason('');
    setShowPrompt(false);
  };

  const handlePriorityReasonUpdate = (value) => {
    setPriorityUpdateReason(value);
  };

  const stylingVariant = itemClassNames[fieldName];
  const styleClass = `valueClass_${selectedItem?.valueClass}`;
  const isDefault = !['Priority', 'Status'].includes(headerName);
  const isCustomStyles =
    headerName === 'Ticket Type' || headerName === 'Urgency' || headerName === 'Impact' || fieldName === 'requestUser' || fieldName === 'cc' ||
    (isMultiple && customColumn);

  const handleClearValue = useCallback(() => {
    handleUpdateSR(null);
    setUpdatedValue(null);
    handleClose();
  }, [handleUpdateSR, handleClose]);

  const selection = useMemo(() => findSelectedItem(selectedItem, keyString), [selectedItem, keyString]);
  const isEmptyRequiredValue = required && !valueId;

  const getTooltipTitle = () => {
    if (isEmptyRequiredValue && isChipItem) {
      return mandatoryFieldError;
    }
    if (fieldName === 'requestUser' && selectedItem?.isDeleted) {
      return 'User does not exist';
    }
    if (headerName === 'Status' && disabled) {
      return statusRequiredFieldsTooltipMessage;
    }
    return undefined;
  };
  const tooltipTitle = getTooltipTitle();

  const options = useMemo(() => {
    const key = isMultiple && customColumn ? 'value' : keyString;
    if (!isMultiple || isEmpty(selectedItem)) return dropdownList;
    if (Array.isArray(selectedItem)) return mergeArraysByKey(dropdownList, selectedItem, key);
    return dropdownList.includes(selectedItem) ? dropdownList : [...dropdownList, selectedItem];
  }, [isMultiple, dropdownList, selectedItem, keyString, customColumn]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        typeof query === 'object'
          ? getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { ...query, customColumn })
          : getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { query, customColumn }),
    }),
    [fieldId, customColumn],
  );

  const isSrType = headerName === 'SR Type';
  const text = selectedItem?.[captionString] || updatedValue?.calculatedUserName || getPlaceholder();
  const isDisabled = disabled || isMultiple;

  if (isError) return <div>{isError}</div>;
  if (!islistValueLoaded && fieldName !== 'requestUser') return <BarLoader withFade width={60} height={24} />;

  return (
    <StyledItem
      ref={hoverRef}
      isDefault={isDefault}
      className={anchor ? 'expanded' : ''}
      required={required}
      isCustomStyles={isCustomStyles}
      disabled={isDisabled}
      isSROpenedFromQueue={isSROpenedFromQueue}
      isEmptyRequiredValue={isEmptyRequiredValue}
      isChipItem={isChipItem}
      isCapitalized={fieldName === 'srType'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      // onDoubleClick={() => setOnlyData(true)}
    >
      {/* {onlyData ? (
        <span onDoubleClick={() => setOnlyData(false)}>{text}</span>
      ) : ( */}
      <>
        {isMultiple ? (
          <MultiSelectField
            itemList={options}
            selectedItemList={selection}
            handleSelectItems={handleChange}
            captionString={captionString}
            keyString={keyString}
            disabled={disabled}
            fieldPlaceholder="--"
            srId={srId}
            sortBy={sortBy}
            required={required}
            tooltipTitle={isEmptyRequiredValue && mandatoryFieldError}
            fieldId={fieldId}
            customColumn={customColumn}
            srType={srType}
            updateField={updateMultiSelectField}
          />
        ) : (
          <>
            <span
              ref={labelRef}
              className={`${headerName}Item ${className}`}
              data-testid="selected-render"
              onClick={handleOpen}
              aria-hidden="true"
            >
              <span key={selectedItem?.valueKey} className={`chip ${styleClass}`}>
                <ConditionalWrapper
                  condition={toDisplayUser(
                    [itemClassNames.reporter, 'requestUser', 'submitUser', 'manager'],
                    headerName,
                  )}
                  wrapper={(children) => <User userName={selectedItem?.valueCaption}>{children}</User>}
                >
                  <span className="text">
                    <Tooltip
                      isError={isEmptyRequiredValue && isChipItem}
                      title={notInTemplate ? notExistInTemplateMessage : tooltipTitle}
                      isTruncatedText={!notInTemplate}
                      forceShowOnHover={tooltipTitle != null}
                      text={text}
                      placement="top"
                      disableHoverListener={isEmptyRequiredValue && !isChipItem}
                      placeholder={getPlaceholder()}
                      // TODO: Perform the check if we need custom style to the wrapper of this component, and apply the style prop as received from the wrapper always.
                      style={isCustomStyles || isSrType ? style : null}
                    />
                    {isEmptyRequiredValue && !isChipItem && (
                      <Tooltip
                        className="missing-required-field"
                        title={mandatoryFieldError}
                        placement="top"
                        disableHoverListener={!isEmptyRequiredValue || !mandatoryFieldError}
                      />
                    )}
                  </span>
                </ConditionalWrapper>
              </span>
            </span>
            {isHovered && (
              <DropdownSelect
                anchor={anchor}
                options={options}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                selection={selection}
                fieldName={fieldName}
                keyString={keyString}
                captionString={captionString}
                stylingVariant={stylingVariant}
                sortBy={sortBy}
                handleClearValue={handleClearValue}
                backendQueryConfig={backendQueryConfig}
                isActionsHidden={isChipItem}
              />
            )}
          </>
        )}

        {showPrompt && (
          <MessagePrompt
            open
            onOkClick={handleOkClickInPrompt}
            showCancelBtn
            showIcon={false}
            isOkButtonDisable={priorityUpdateReason.length === 0}
            onClose={handleClosePrompt}
            title={popupTitle(selectedItem?.valueCaption)}
            btnOkText={popupOkBtnText}
            style={messagePromptStyle}
          >
            <DialogContentText>
              {popupDescription}
              <span className="mandatory-asterisk">*</span>
            </DialogContentText>
            <PromptTextField placeholder={popupInputPlaceholder} onChange={handlePriorityReasonUpdate} />
          </MessagePrompt>
        )}
      </>
      {/* )} */}
    </StyledItem>
  );
});
