import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { SR_TYPES } from 'features/TicketPanel/constants';
import { ReactComponent as NewTicketIcon } from 'images/icons/newTicket.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus-icon.svg';
import { Popper, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrow-down-white.svg';
import { addFloatingTicketPanel, selectPanels } from 'store/floatingTicketPanelSlice';
import { showPrompt } from 'store/messagePromptSlice';
import { BASIC_EDITION } from 'features/settings/constants';
import { useApplicationData } from 'remote-state/applicationHooks';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { useTicketTemplateData } from 'remote-state/ticketServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { openChatbot } from 'features/loggedInRoutesPanel/slice';
import { useAutoPopulationEnabled } from 'remote-state/accountSettingsServiceHooks';
import { FloatingTicketInstanceModes } from 'features/floatingTicketPanel/floatingTicketPanelManager/modes';
import { StyledNewTicket } from './style';
import useTexts from '../useTexts';

import { OPEN_NEW_TICKET_ID, MAXIMUM_ACTIVE_PANELS } from '../constants';

export default function NewTicket(props) {
  const { expanded } = props;
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const isAutoPopulateEnabled = useAutoPopulationEnabled();
  const [open, setOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const panels = useSelector(selectPanels);
  const {
    newTicketText,
    newTicketIncident,
    newTicketRequest,
    newTicketProblem,
    newTicketChange,
    maxFloatingPanelReached,
    maxFloatingPanelReachedButton,
  } = useTexts();
  const { data } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);

  useTicketTemplateData(SR_TYPES.incident, true, !initialized);

  const userPermissionChgMgmtCreateWithoutDesign = data?.userPermissionChgMgmtCreateWithoutDesign;
  const userPermissionChgMgmtCreateRFC = data?.userPermissionChgMgmtCreateRFC;
  let newTicketTypes = [
    { type: SR_TYPES.incident, title: newTicketIncident },
    { type: SR_TYPES.request, title: newTicketRequest },
  ];

  let canUserCreateChangesOrProblems = true;
  if (!userPermissionChgMgmtCreateWithoutDesign && !userPermissionChgMgmtCreateRFC) {
    canUserCreateChangesOrProblems = false;
  }

  if (canUserCreateChangesOrProblems) {
    newTicketTypes = newTicketTypes.concat([
      { type: SR_TYPES.problem, title: newTicketProblem },
      { type: SR_TYPES.change, title: newTicketChange },
    ]);
  }
  const isBasicEdition = edition === BASIC_EDITION;
  if (isBasicEdition) {
    newTicketTypes = [{ type: SR_TYPES.incident, title: newTicketIncident }];
  }

  const keys = Object.keys(panels);
  const isDisabled = keys.some((k) => panels[k].isLoading);

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  const onClickNewTicket = (srType) => {
    dispatch(openChatbot(false));
    if (Object.keys(panels).length === MAXIMUM_ACTIVE_PANELS) {
      dispatch(
        showPrompt({
          title: maxFloatingPanelReached || "New Service request can't be added",
          btnOkText: maxFloatingPanelReachedButton || 'Got It',
          showCancelBtn: false,
        }),
      );
      return;
    }
    if (isDisabled) return;
    performance.mark(PERFORMANCE_MEASURE.QUEUE.CREATE_TICKET_BTN);
    dispatch(
      addFloatingTicketPanel({
        srType: srType || newTicketTypes[0].type,
        createTime: Date.now(),
        mode: isAutoPopulateEnabled
          ? FloatingTicketInstanceModes.AUTO_POPULATE
          : FloatingTicketInstanceModes.REGULAR_MODE_OPENED,
      }),
    );
  };

  const closeMenu = (ticketType) => (event) => {
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    if (expanded) {
      setOpen(false);
    }
    onClickNewTicket(ticketType);
  };

  const newTicketTypesElement = (
    <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start">
      <Paper
        sx={{
          boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35);',
          borderRadius: '10px;',
          marginLeft: expanded ? '160px' : '48px',
          marginTop: !expanded && '-42px',
        }}
      >
        <ClickAwayListener onClickAway={toggleMenu}>
          <MenuList sx={{ padding: '12px 0;' }}>
            {newTicketTypes.map(({ type: ticketType, title }) => (
              <MenuItem
                key={ticketType}
                onClick={closeMenu(ticketType)}
                sx={{
                  padding: '12px 0 12px 24px;',
                  width: '225px;',
                  lineHeight: '20px;',
                  fontSize: '14px;',
                  fontFamily: 'Roboto-Regular',
                  '&:hover': { backgroundColor: '#f5f6fa' },
                }}
              >
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
  return (
    <StyledNewTicket expanded={expanded} ref={anchorRef}>
      {expanded ? (
        <div className="capsule">
          <button
            disabled={isDisabled}
            id={OPEN_NEW_TICKET_ID}
            data-testid={OPEN_NEW_TICKET_ID}
            data-cy={OPEN_NEW_TICKET_ID}
            className="capsule-left"
            style={
              isBasicEdition
                ? {
                    borderRight: 'none',
                  }
                : undefined
            }
            onClick={() => onClickNewTicket()}
          >
            <PlusIcon /> <span>{newTicketText}</span>
          </button>
          {!isBasicEdition && (
            <button className="capsule-right" onClick={toggleMenu}>
              <ArrowDownIcon />
            </button>
          )}
          {!isBasicEdition && newTicketTypesElement}
        </div>
      ) : (
        <button
          disabled={isDisabled}
          id={OPEN_NEW_TICKET_ID}
          data-testid={OPEN_NEW_TICKET_ID}
          data-cy={OPEN_NEW_TICKET_ID}
          onClick={() => onClickNewTicket()}
        >
          <NewTicketIcon />
          {newTicketTypesElement}
        </button>
      )}
    </StyledNewTicket>
  );
}
