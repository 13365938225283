import { useMemo } from 'react';
import { getIsExistingSr } from 'common/utils/srUtils';
import { ReactComponent as PrevIcon } from 'images/icons/prev.svg';
import { ReactComponent as NextIcon } from 'images/icons/next.svg';

function NextOrPrevSR({ srId }) {
  const isExistingSR = getIsExistingSr(srId);

  const loadedSrIds = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem('loadedSrIds')) || [];
    } catch {
      return [];
    }
  }, []);

  const currentSrIdIndex = loadedSrIds.findIndex((loadedSrId) => loadedSrId === srId);
  const isNextDisable = currentSrIdIndex === loadedSrIds.length - 1;
  const isPrevDisable = currentSrIdIndex === 0;

  const handleClickPrev = () => {
    window.location.href = `?id=${loadedSrIds[currentSrIdIndex - 1]}`;
  };

  const handleClickNext = () => {
    window.location.href = `?id=${loadedSrIds[currentSrIdIndex + 1]}`;
  };

  return (
    <>
      {Boolean(loadedSrIds?.length) && isExistingSR && currentSrIdIndex !== -1 && (
        <div className="next-or-prev-sr">
          <button onClick={handleClickPrev} disabled={isPrevDisable} data-testid="prev-sr">
            <PrevIcon />
          </button>
          <button onClick={handleClickNext} disabled={isNextDisable} data-testid="next-sr">
            <NextIcon />
          </button> 
        </div>
      )}
    </>
  );
}

export default NextOrPrevSR;
