export const USER_CONSTANTS = {
  INPUT_DATE: 'inputDate',
  TIMEZONE: 'timezone',
  DATE_FORMAT: 'dateFormat',
  DATE_FORMAT_NO_TIME: 'dateFormatNoTime',
  AMERICAN: 'american',
  MANUAL_USER_SOURCE: 4,
  LDAP_ENABLED_USER_SOURCE: 1,
  FIRST_DAY_OF_WEEK: 'firstDayOfWeek',
  SHARE_NOTE_BY_DEFAULT: 'shareNoteByDefault',
};
