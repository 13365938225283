import { useRouter } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { APP_CONSTANTS } from 'constants/app';
import {
  AccountLimitationsApi,
  AttachmentPath,
  AttachmentsApi,
  ResourceType,
  ResponseError,
} from 'services/attachments';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { baseURL } from 'services/axiosBaseInstance';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import {
  generatePreSignedUrl,
  getPresignedUrl,
  getPreviewPresignedUrl,
  updateAttachmentInS3,
  updateInDB,
  updateMultipleInDB,
} from '../../services/attachments/apis/V2AttachmentService';

import useTexts from './useTexts';
import { DUMMY_ATTACHMENT_ID, responseType } from './constants';

const attachmentAxiosInstance = new AttachmentsApi();
const limitationsAxiosInstance = new AccountLimitationsApi();

const useMaxFileSize = () => {
  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
  return useQuery(['maxFileSize', accountId], () => limitationsAxiosInstance.findMaxFileSize(), {
    placeholderData: {},
    staleTime: 1000 * 60 * 15,
  });
};

const useGetAttachmentQuery = ({ queryKey, resourceId, resourceType, subResourceId = null, distinct }) => {
  const attachmentsQuery = useQuery(
    [queryKey, resourceId],
    () => attachmentAxiosInstance.getAttachmentsByResource({ resourceId, resourceType, distinct }),
    {
      placeholderData: [],
      enabled: typeof resourceId === 'number' && !!resourceId,
      staleTime: 1000 * 60 * 15,
    },
  );

  const attachmentsBySubResourceId = useMemo(() => {
    if (!attachmentsQuery.data?.length) return [];
    return attachmentsQuery.data.filter((attachment) => attachment.subResourceId === subResourceId);
  }, [attachmentsQuery.data, subResourceId]);

  return {
    ...attachmentsQuery,
    attachmentsBySubResourceId,
  };
};

const useGetAttachmentByIdQuery = ({ queryKey = 'attachment', attachmentId }) => {
  const attachmentByIdQuery = useQuery(
    [queryKey, attachmentId],
    () => attachmentAxiosInstance.findAttachmentById({ attachmentId }),
    {
      placeholderData: {},
      enabled: typeof attachmentId === 'number' && !!attachmentId,
      staleTime: 1000 * 60 * 15,
    },
  );
  const { data: { data: innerData } = {}, ...rest } = attachmentByIdQuery;

  return {
    ...innerData,
    ...rest,
  };
};

function handleUploadError(error, createAttachment, maxFileSize) {
  if (error.response) {
    const { data, status } = error.response;
    switch (status) {
      case 413:
        throw new ResponseError('', {
          prompt: createAttachment.prompt.fileSizeErrorDescription(maxFileSize?.fileMaxSizeInMB),
          inline: createAttachment.inline.fileSizeErrorDescription(maxFileSize?.fileMaxSizeInMB),
        });
      case 415:
        throw new ResponseError('', {
          prompt: createAttachment.prompt.mediaTypesErrorDescription,
          inline: createAttachment.inline.mediaTypesErrorDescription,
        });
      default:
        throw new ResponseError('', {
          prompt: createAttachment.prompt.errorDescription(data.error),
          inline: createAttachment.inline.errorDescription(data.error),
        });
    }
  }
}

const useV2NewAttachment = (handleSuccess = null) => {
  const { createAttachment } = useTexts();
  const { data: maxFileSize } = useMaxFileSize();

  const { mutateAsync: uploadToS3 } = useMutation(({ data, params }) =>
    updateAttachmentInS3(params.payload, data.preSignedUrl),
  );

  const { mutateAsync: createPreSignedUrl } = useMutation((params) =>
    generatePreSignedUrl({ ...params, fileMaxSize: maxFileSize?.fileMaxSizeInMB }),
  );

  const { mutateAsync: updateDB } = useMutation({
    mutationFn: ({ preSignedUrl: { path, generatedFileName }, params }) =>
      updateInDB({ ...params, path, generatedFileName }),
  });

  return {
    ...useMutation(
      async (params) => {
        const preSignedUrl = await createPreSignedUrl(params);
        if (preSignedUrl?.responseType === responseType.USE_PRESIGNED_URL_FOR_UPLOAD) {
          await uploadToS3({ data: preSignedUrl, params });
        }
        return updateDB({ preSignedUrl, params });
      },
      {
        onSuccess: ({ data }) => handleSuccess?.(data) || data,
        onError: (error) => handleUploadError(error, createAttachment, maxFileSize),
      },
    ),
  };
};

const useV2NewAttachments = (handleSuccess = null) => {
  const { createAttachment } = useTexts();
  const { data: maxFileSize } = useMaxFileSize();

  const { mutateAsync: uploadToS3 } = useMutation(({ data, file }) =>
    updateAttachmentInS3(file, data.preSignedUrl),
  );

  const { mutateAsync: createPreSignedUrl } = useMutation((params) =>
    generatePreSignedUrl({ ...params, fileMaxSize: maxFileSize?.fileMaxSizeInMB }),
  );

  const { mutateAsync: updateDB } = useMutation({
    mutationFn: (filesMetadata) => updateMultipleInDB(filesMetadata),
  });

  return {
    ...useMutation(
      async (params) => {
        const uploadPromises = params.payload.map(async (file) => {
          const preSignedUrl = await createPreSignedUrl({ payload: file, metadata: params.metadata });
          if (preSignedUrl?.responseType === responseType.USE_PRESIGNED_URL_FOR_UPLOAD) {
            await uploadToS3({ data: preSignedUrl, file });
          }
          return { preSignedUrl, params: { payload: file, metadata: params.metadata } };
        });
        const results = await Promise.all(uploadPromises);
        const updateDbFiles = results.filter(result => result !== null);
        if (updateDbFiles.length > 0) {
          return updateDB(updateDbFiles);
        }
        return {};
      },
      {
        onSuccess: ({ data }) => handleSuccess?.(data) || data,
        onError: (error) => handleUploadError(error, createAttachment, maxFileSize),
      }
    )
  }
}

const useNewAttachment = (handleSuccess = null) => {
  const { createAttachment } = useTexts();

  /*  temporary restriction in client for the file size (< 10 Mb ) until we find a way to overcome the limitation  */
  // const { data: maxFileSize } = useMaxFileSize();
  const maxFileSize = { fileMaxSizeInMB: 10 };

  return {
    ...useMutation(
      (params) =>
        attachmentAxiosInstance.createNewAttachmentForm({ ...params, fileMaxSize: maxFileSize?.fileMaxSizeInMB }),
      {
        onSuccess: (data) => handleSuccess?.(data) || data,
        onError: (error) => handleUploadError(error, createAttachment, maxFileSize),
      },
    ),
  };
};

const useReassignTempAttachmentsMutation = (handleSuccess = null) => ({
  ...useMutation((params) => attachmentAxiosInstance.reassignTempAttachmentsById(params), {
    onSuccess: (data) => handleSuccess?.(data) || data,
    onError: (error) => {
      throw new ResponseError(error);
    },
  }),
});

const useDeleteAttachmentMutation = (handleSuccess = null) => ({
  ...useMutation(
    ({ attachmentId, isDeleteDuplicate, subResourceType }) =>
      attachmentAxiosInstance.deleteAttachmentById({ attachmentId, isDeleteDuplicate, subResourceType }),
    {
      onSuccess: (data) => handleSuccess?.(data) || data,
      onError: (error) => {
        throw new ResponseError(error);
      },
    },
  ),
});

const useDeleteAttachmentsQuery = (handleSuccess = null) => ({
  ...useMutation(
    (attachments) =>
      Promise.all(
        attachments.map((attachment) =>
          attachmentAxiosInstance.deleteAttachmentById({
            attachmentId: attachment.fileId,
            subResourceType: attachment.subResourceType,
          }),
        ),
      ),
    {
      onSuccess: (data) => handleSuccess?.(data) || data,
      onError: (error) => {
        throw new ResponseError(error);
      },
    },
  ),
});

const getDownloadFileUrl = (attachmentId, fileId, isAttachmentSizeLimited, subResourceType) => {
  const isFileSystemAttachment = attachmentId === DUMMY_ATTACHMENT_ID;
  if (isAttachmentSizeLimited && !isFileSystemAttachment) {
    return getPresignedUrl(attachmentId);
  }
  return {
    url: `${baseURL}${AttachmentPath.V3}${
      isFileSystemAttachment ? fileId : attachmentId
    }/payload${subResourceType ? `?sub_resource_type=${subResourceType}` : ''}`,
  };
};

const useGeneratedPresignedUrlByAttachmentId = (attachmentId, fileId, subResourceType) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { data: isAttachmentSizeLimited } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.ATTACHMENT_SIZE_LIMITATION,
  });
  const downloadFileUrlQueryKey = 'presignedUrl';
  const previewFileUrlQueryKey = 'previewPresignedUrl';

  const srAttachments = useGetAttachmentQuery({
    queryKey: 'srAttachments',
    resourceId: srId,
    resourceType: ResourceType.SR,
    distinct: true,
  });
  const srAttachmentIndex = srAttachments.data?.findIndex(({ attachment }) =>
    attachmentId === DUMMY_ATTACHMENT_ID
      ? attachment.metadata.fileId === fileId
      : attachment.metadata.id === attachmentId,
  );

  const { data: downloadFileData = {}, ...rest } = useQuery(
    [downloadFileUrlQueryKey, attachmentId, fileId, subResourceType],
    () => getDownloadFileUrl(attachmentId, fileId, isAttachmentSizeLimited, subResourceType),
    {
      placeholderData: { url: null },
      enabled: Boolean(attachmentId) && srAttachmentIndex !== -1,
      staleTime: 1000 * 60 * 10,
      cacheTime: 0,
    },
  );

  const { data: previewFileData = {} } = useQuery(
    [previewFileUrlQueryKey, attachmentId, fileId, subResourceType],
    () =>
      attachmentId === DUMMY_ATTACHMENT_ID
        ? getDownloadFileUrl(attachmentId, fileId, isAttachmentSizeLimited, subResourceType)
        : getPreviewPresignedUrl(attachmentId),
    {
      placeholderData: { url: null },
      enabled: Boolean(attachmentId) && srAttachmentIndex !== -1,
      staleTime: 1000 * 60 * 10,
      cacheTime: 0,
    },
  );

  const getFileUrlByAttachmentId = async (attachmentId, fileId, subResourceType) => {
    const { url: presignedUrl } = await queryClient.fetchQuery({
      queryKey: [downloadFileUrlQueryKey, attachmentId, fileId, subResourceType],
      queryFn: () => getDownloadFileUrl(attachmentId, fileId, isAttachmentSizeLimited, subResourceType),
    });
    return presignedUrl || {};
  };

  return {
    getFileUrlByAttachmentId,
    data: {
      downloadUrl: downloadFileData.url,
      previewUrl: previewFileData.url,
    },
    ...rest,
  };
};

export {
  useGetAttachmentQuery,
  useDeleteAttachmentMutation,
  useDeleteAttachmentsQuery,
  useNewAttachment,
  useV2NewAttachment,
  useV2NewAttachments,
  useMaxFileSize,
  useReassignTempAttachmentsMutation,
  useGetAttachmentByIdQuery,
  useGeneratedPresignedUrlByAttachmentId,
  getDownloadFileUrl
};
