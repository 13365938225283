import { APP_CONSTANTS } from 'constants/index';

const keepCookies = [APP_CONSTANTS.IN_SPACES_SWITCH_FEEDBACK_SHOWN_COOKIE];

export const deleteAllCookies = () => {
  document.cookie.split(';').forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();
    //There are some cookies that should stay on browser when user signs out and here we are checking them
    if (!keepCookies.includes(cookieName)) {
      document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()};path=/`;
    }
  });
};

export const clearStorage = (skipCookies = false, keepWebSocketsLocalStorageItems = false) => {
  if(!skipCookies){
    deleteAllCookies();
  }
  sessionStorage.clear();
  //We should remove all state but keep columnsOrder
  try {
    const queueState = JSON.parse(localStorage.getItem('persist:queue'));
    const columnsOrder = queueState?.columnsOrder;
    const target = localStorage.getItem(APP_CONSTANTS.ACCOUNT_TARGET);
    const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
    const isAllMessagesExpandedValue = localStorage.getItem(APP_CONSTANTS.IS_ALL_MESSAGES_EXPANDED);

    localStorage.clear();

    if (isAllMessagesExpandedValue != null) {
      localStorage.setItem(APP_CONSTANTS.IS_ALL_MESSAGES_EXPANDED, isAllMessagesExpandedValue);
    }

    if (columnsOrder) {
      localStorage.setItem('persist:queue', JSON.stringify({ columnsOrder }));
    }

    if(keepWebSocketsLocalStorageItems){
      localStorage.setItem(APP_CONSTANTS.ACCOUNT_TARGET, target);
      localStorage.setItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY, accountId);
    }
  } catch (error) {
    console.error('An error occurred during storage clearing:', error);
    localStorage.clear();
  }
};
