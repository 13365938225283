import { useRef, useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import Tooltip from 'common/components/tooltip';
import useClickAway from 'common/utils/hooks/useClickAway';
import { ReactComponent as FilterIcon } from 'images/icons/gh_filter.svg';
import { ReactComponent as CloseChipIcon } from 'images/icons/chipDelete.svg';
// import { ZenMode } from '../zenMode/zenMode';
import {
  StyledStack,
  StyledGridFilters,
  StyledChip,
  StyleFilterIconContiner,
  StyledChipContainer,
  StyledChipDropDown,
  StyledDropDown,
  StyledDropDownItems,
  StyledClearAll,
  StyledDropdownContainer,
  StyledChipFilter,
} from './style';

export default function GridFilters({ table, columnFilters }) {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [splitIndex, setSplitIndex] = useState();
  const [shouldRecalculateChipList, setShouldRecalculateChipList] = useState(false);
  const chipContainerRef = useRef(null);
  const chipDropDownRef = useRef(null);

  useClickAway(chipDropDownRef, () => {
    setOpenDropDown(false);
  });

  const handleResize = useCallback(() => {
    setTimeout(() => {
      setSplitIndex(-1);
      setShouldRecalculateChipList(true);
    }, 1000);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const calcSplitIndex = useCallback(() => {
    const containerRight = chipContainerRef.current.getBoundingClientRect().right;
    const index = Array.from(chipContainerRef.current.childNodes).findIndex(
      (node) =>
        node.getBoundingClientRect().right > containerRight || node.classList.contains('dropdown-chip-container'),
    );
    setSplitIndex(index);
  }, []);

  useLayoutEffect(() => {
    calcSplitIndex();
  }, [columnFilters, calcSplitIndex]);

  useLayoutEffect(() => {
    if (shouldRecalculateChipList) {
      calcSplitIndex();
      setShouldRecalculateChipList(false);
    }
  }, [shouldRecalculateChipList, calcSplitIndex]);

  const { visibleChips, dropdownChips } = useMemo(
    () =>
      splitIndex > -1
        ? { visibleChips: columnFilters?.slice(0, splitIndex), dropdownChips: columnFilters?.slice(splitIndex) }
        : { visibleChips: columnFilters },
    [columnFilters, splitIndex],
  );

  const handleDelete = useCallback(
    (chip) => () => {
      const col = table.getColumn(chip.id);
      if (col) {
        col.setFilterValue();
      }
    },
    [table],
  );

  const handleClearAll = useCallback(() => {
    table.resetColumnFilters();
  }, [table]);

  const toggleDropdown = useCallback(() => setOpenDropDown((prev) => !prev), [setOpenDropDown]);

  const addSpace = useCallback((value, withDescription) => {
    if (!value) return '';
    const newValue = value.replaceAll(',', ', ');
    if (withDescription.id === 'id' && withDescription.value.type !== 'equals') {
      // @TODO come back to this
      let val;
      if (withDescription.value.type === 'lessThan') {
        val = 'less than';
      } else if (withDescription.value.type === 'greaterThan') {
        val = 'greater than';
      }
      const key = newValue.slice(0, newValue.indexOf(':') + 1);
      const value = newValue.slice(newValue.indexOf(':') + 1, newValue.length);
      return (
        <span>
          {key}
          <StyledChipFilter>{val}</StyledChipFilter>
          {value}
        </span>
      );
    }
    return newValue;
  }, []);

  return (
    <StyledGridFilters className="grid-filters">
      <StyledStack direction="row" spacing={1}>
        <StyleFilterIconContiner data-testid="filter-button">
          <FilterIcon />
        </StyleFilterIconContiner>
        <StyledChipContainer ref={chipContainerRef}>
          {visibleChips?.map((chip) => {
            const { id, value } = chip;
            const column = table.getColumn(id);
            const chipText = addSpace(`${column.columnDef.header}: ${value.values?.join(', ') || ''}`, chip);
            return (
              <StyledChip
                id={`chip_${id}`}
                data-testid={`chip_${id}`}
                key={id}
                label={
                  <Tooltip
                    placement="top"
                    isTruncatedText
                    title={chipText}
                    text={chipText}
                    style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
                  />
                }
                variant="outlined"
                onDelete={handleDelete(chip)}
                deleteIcon={<CloseChipIcon />}
              />
            );
          })}
          {dropdownChips?.length > 0 && (
            <StyledDropdownContainer ref={chipDropDownRef} className="dropdown-chip-container">
              <StyledChipDropDown onClick={toggleDropdown} label={`+${dropdownChips.length}`} variant="outlined" />
              {openDropDown && (
                <StyledDropDown>
                  {dropdownChips?.map((chip) => {
                    const { id, value } = chip;
                    const column = table.getColumn(id);
                    const chipText = addSpace(`${column.columnDef.header}: ${value.values?.join(', ') || ''}`, chip);
                    return (
                      <StyledDropDownItems
                        key={id}
                        label={
                          <Tooltip
                            isTruncatedText
                            title={chipText}
                            text={chipText}
                            style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
                          />
                        }
                        variant="outlined"
                        onDelete={handleDelete(chip)}
                        deleteIcon={<CloseChipIcon />}
                      />
                    );
                  })}
                </StyledDropDown>
              )}
            </StyledDropdownContainer>
          )}
        </StyledChipContainer>
        {columnFilters?.length > 0 && (
          <StyledClearAll data-testid="clear-all" onClick={handleClearAll}>
            Clear All
          </StyledClearAll>
        )}
      </StyledStack>
      {/* <ZenMode /> */}
    </StyledGridFilters>
  );
}
