import { useState, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import ChangeProfileBadge from 'common/components/changeProfileBadge';
import Tooltip from 'common/components/tooltip';
import { AVATAR_CONSTANTS, APP_CONSTANTS } from 'constants/index';
import useTexts from 'features/navBar/meMenu/useTexts';
import NavLink from 'features/navBar/navLink';
import { ReactComponent as LinkExternal } from 'images/icons/link-external.svg';
import { ReactComponent as Switch } from 'images/icons/Switch.svg';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { removeCookie, getCookie, setCookie } from 'common/utils/utils';
import LogoutBtn from '../logoutBtn';
import { StyledCardHeader, StyledXButton, StyledSelfServicePortalButton, StyledSwitchToClassicButton } from '../styles';
import ChangeProfilePicture from './changeProfilePicture';
import FeedbackDialogOnSwitch from './feedbackOnSwitch';

export default function MeMenuHeader({ userName, settings: { profileImage }, handleChange, closeMenu }) {
  const [showBadge, setShowBadge] = useState(false);
  const [openChangeProfileImage, setOpenChangeProfileImage] = useState(false);
  const { sspBtnText, switchToClassicBtnText } = useTexts();
  const { data: enabledSwitchUi } = useApplicationData(QUERIES_KEYS.ENABLED_SWITCH_UI);
  const feedbackRef = useRef(null);

  const { data: isFeedbackDialogOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.SHOW_FEEDBACK_ON_SWITCH_TO_CLASSIC,
    defaultValue: false,
  });

  const handleOpenChangeProfileImage = () => {
    setOpenChangeProfileImage(true);
  };

  const handleCloseChangeProfileImage = () => {
    setOpenChangeProfileImage(false);
  };

  const handleSwitchClick = () => {
    const isFeedbackAlreadyViewed = getCookie(APP_CONSTANTS.IN_SPACES_SWITCH_FEEDBACK_SHOWN_COOKIE);
    //If user viewed Feedback during last 90 days then we wouldnt show it again. We also check FF
    if (isFeedbackDialogOn && !isFeedbackAlreadyViewed) {
      feedbackRef.current?.open();
      setCookie(APP_CONSTANTS.IN_SPACES_SWITCH_FEEDBACK_SHOWN_COOKIE, 'true', 90);
    } else {
      removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
      window.open('/index.jsp#/HelpDesk.jsp?fromId=List&showFirstPage=true&comeFromSpaces=true', '_self', 'noreferrer');
    }
  };

  return (
    <>
      <ChangeProfilePicture
        open={openChangeProfileImage}
        handleClose={handleCloseChangeProfileImage}
        handleChange={handleChange}
        userName={userName}
        profileImage={profileImage}
      />
      <StyledXButton handleClick={closeMenu} iconButtonProps={{ 'data-testid': 'close-header-button' }} />
      <StyledCardHeader
        avatar={
          <Box
            onMouseEnter={() => setShowBadge(true)}
            onMouseLeave={() => setShowBadge(false)}
            onClick={() => handleOpenChangeProfileImage()}
            data-testid="profile-image"
          >
            <ChangeProfileBadge
              invisible={!showBadge}
              avatar={
                <BasicAvatar
                  size={AVATAR_CONSTANTS.SIZES.XL}
                  profileImage={profileImage}
                  userName={userName}
                  customStyles={{
                    rootStyles: {
                      cursor: 'pointer',
                    },
                  }}
                  disableHover
                />
              }
            />
          </Box>
        }
        title={<Tooltip text={userName} isTruncatedText placement="top" data-testid="username-title" />}
        subheader={<LogoutBtn data-testid="logout-button" data-cy="logout-button" />}
        action={
          <>
            <StyledSelfServicePortalButton>
              <NavLink to="/servicePortal" isOpenInNewTab>
                <Button
                  data-testid="ssp-link"
                  variant="text"
                  disableElevation
                  disableRipple
                  startIcon={<LinkExternal />}
                >
                  {sspBtnText}
                </Button>
              </NavLink>
            </StyledSelfServicePortalButton>
            {enabledSwitchUi && (
              <StyledSwitchToClassicButton>
                <Button
                  data-testid="switch-to-classic-btn"
                  variant="text"
                  disableElevation
                  disableRipple
                  onClick={handleSwitchClick}
                  startIcon={<Switch />}
                >
                  {switchToClassicBtnText}
                </Button>
              </StyledSwitchToClassicButton>
            )}
          </>
        }
      />
      <FeedbackDialogOnSwitch ref={feedbackRef} />
    </>
  );
}
