import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Assignees from 'common/components/assignee/assignees';
import Status from 'common/components/status';
import { useAssigneesList, useFieldAttributes, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import useGetFieldPermissions from 'common/utils/hooks/useGetFieldPermissions';
import { setForceShowError } from 'store/fieldValidationsSlice';
import { isRequiredField } from 'common/utils/fieldUtils';
import Actions from '../actions';
import Share from '../actions/share';
import { headerActionsMenuStyles, HeaderItemLabel, LabelWrap, StyledRightButtons } from '../style';
import useTexts from './useTexts';
import TemplateName from '../actions/templateName';
import { PENDO_TRACK_EVENTS } from '../../../constants';

function HeaderFooterRight(props) {
  const {
    className,
    onTicketStatusChange,
    srObject,
    onBackButtonClick,
    isTicketDisabled,
    template,
    isValidTicket,
    isNewSR,
    updateFieldValidation,
    isFloatingTicketPanel,
    handleFieldChange,
    handleTicketLock,
    handleDirty,
    updateOnExit,
    forceStopuseStateStore,
  } = props;
  const { srType, id: srId, assignee: assigneeName, assignedGroup, status } = srObject;
  const { assigneeText, statusText } = useTexts();
  const [dropdownGroupName, setDropdownGroupName] = useState();
  const dispatch = useDispatch();

  const {
    admins: { data: admins },
    groups: { data: groups },
    adminsByGroup: { data: dropdownGroupList },
  } = useAssigneesList(dropdownGroupName);
  const assigneeField = template?.header?.find((field) => field.fieldId === 62);
  const getAssigneePermissionsRules = useGetFieldPermissions(assigneeField);

  const statusField = template?.header?.find((field) => field.fieldId === 8);
  const getStatusPermissionsRules = useGetFieldPermissions(statusField);
  const statusAttributesInput = useMemo(
    () => [{ fieldId: statusField?.fieldId, srType }],
    [statusField?.fieldId, srType],
  );
  const statusList = useFieldAttributes(statusAttributesInput)?.[0]?.data?.values;
  const isStatusDisabled =
    isTicketDisabled || Boolean(!isValidTicket) || getStatusPermissionsRules?.disabled || statusField?.readOnly;
  const statusValue = statusList?.find((stsItem) => Number(stsItem.id) === Number(status));

  const assigneeRequired = isRequiredField(status, assigneeField?.required, assigneeField?.requiredStatuses);
  const isAssigneeFieldDisabled = isTicketDisabled || getAssigneePermissionsRules?.disabled || assigneeField?.readOnly;
  if (isStatusDisabled) dispatch(setForceShowError(true));

  const handleStatusChange = useCallback(
    (updatedStatus) => {
      if (onTicketStatusChange) {
        onTicketStatusChange(updatedStatus);
      }
      const identifier = updatedStatus.id || updatedStatus.valueKey;
      handleFieldChange({ status: Number(identifier) });
      updateOnExit(true);
    },
    [onTicketStatusChange, handleFieldChange, updateOnExit],
  );
  const handleAssigneeChange = (e) => {
    handleFieldChange(e);
    updateOnExit(true);
  };

  const ticketPermissions = useTicketPermissions('delete', srId);
  const disabledDeleteAction = !(ticketPermissions ? ticketPermissions.data : false);

  const currentAssigned = useMemo(() => {
    const assigneeUserFiltered = admins?.filter((assignee) => assigneeName === assignee.userName);
    const profileImage =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].profileImage : undefined;
    const calculatedUserName =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].calculatedUserName : undefined;
    const assigneeId = assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].id : undefined;
    const groupsFiltered = groups?.filter((group) => assignedGroup === group.groupName);
    const groupId = groupsFiltered && groupsFiltered.length ? groupsFiltered[0].id : undefined;
    return {
      admin: { userName: assigneeName, id: assigneeId, profileImage, calculatedUserName },
      group: { groupName: assignedGroup, id: groupId },
    };
  }, [admins, groups, assignedGroup, assigneeName]);

  return (
    <div className={`footer-right ${className}`}>
      <LabelWrap className="assignee-label-wrap">
        <HeaderItemLabel className={`${className} assignee-label`}>{assigneeText}</HeaderItemLabel>
        <Assignees
          currentAssigned={currentAssigned}
          required={assigneeRequired}
          bigAvatar
          disabled={isAssigneeFieldDisabled}
          key={assigneeName || assignedGroup}
          admins={admins}
          groups={groups}
          dropdownGroupName={dropdownGroupName}
          dropdownGroupList={dropdownGroupList}
          setDropdownGroupName={setDropdownGroupName}
          isNewSR={isNewSR}
          handleDirty={handleDirty}
          isFloatingTicketPanel={isFloatingTicketPanel}
          onFocus={handleTicketLock}
          onChange={handleAssigneeChange}
          updateFieldValidation={updateFieldValidation}
        />
      </LabelWrap>
      <LabelWrap data-cy="status-list">
        <HeaderItemLabel className={className}>{statusText}</HeaderItemLabel>
        <Status
          fieldId={statusField?.fieldId}
          status={status}
          statusValue={statusValue}
          statusList={statusList}
          onChange={handleStatusChange}
          onFocus={handleTicketLock}
          disabled={isStatusDisabled}
          srType={srType}
          handleDirty={handleDirty}
          isFloatingTicketPanel={isFloatingTicketPanel}
          updateFieldValidation={updateFieldValidation}
          forceStopuseStateStore={forceStopuseStateStore}
        />
      </LabelWrap>
      {!isFloatingTicketPanel && (
        <StyledRightButtons>
          <Share />
          <Actions
            footer={<TemplateName template={template} />}
            menuStyles={headerActionsMenuStyles}
            srId={srId}
            srType={srType}
            onClickParent={onBackButtonClick}
            disabledDeleteAction={disabledDeleteAction || isTicketDisabled}
            deleteTicketSx={{ '.delete-ticket': { justifyContent: 'end', minWidth: 0 } }}
            pendoEvent={PENDO_TRACK_EVENTS.open_in_classic_from_ticket_click}
            isFromQueue={false}
          />
        </StyledRightButtons>
      )}
    </div>
  );
}
export default memo(HeaderFooterRight);
