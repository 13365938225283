import DropDownActions from './dropDownActions';
import { StyledDropDownTitle, StyledSearchField, StyledDivider, StyledStack } from './style';

const DropDownHeader = ({
  title,
  subTitle,
  isSearchVisible,
  searchFieldProps,
  isActionsVisible,
  actionsProps,
  isDividerVisible = true,
}) => (
  <StyledStack spacing={1} data-cy="dropdown-header" hasTitle={!!title} className="dropdown-header">
    {title && <StyledDropDownTitle>{title}</StyledDropDownTitle>}
    {isSearchVisible && <StyledSearchField {...searchFieldProps} />}
    {isActionsVisible && <DropDownActions subTitle={subTitle} {...actionsProps} />}
    {isDividerVisible && (
      <StyledDivider
        shouldClearStackMargin={
          isActionsVisible &&
          (actionsProps.isMultiSelect ? true : actionsProps?.clearValueHandler && !actionsProps?.isClearHidden)
        }
      />
    )}
  </StyledStack>
);

export default DropDownHeader;
