import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')(({ theme }) => ({
  background: 'white',
  width: '100%',
  padding: '24px',
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme?.palette?.grey?.bt_alt_grey_hover}`,
}));

export const StyledActionButton = styled('div', {
  shouldForwardProp: (prop) => !['isCreateButton', 'disabled', 'isNotValid'].includes(prop),
})(({ theme, isCreateButton, disabled, isNotValid }) => {
  let backgroundColor = theme?.palette?.primary?.btn?.backgroundColor?.main;
  let hoveredBackgroundColor = theme?.palette?.primary?.btn?.backgroundColor?.hover;
  let color = theme?.palette?.generic?.primary;

  if (!isCreateButton) {
    color = theme?.palette?.generic?.sysaid;
    backgroundColor = theme?.palette?.secondary?.btn?.backgroundColor?.main;
    hoveredBackgroundColor = theme?.palette?.secondary?.btn?.backgroundColor?.hover;
  }
  if (isCreateButton && isNotValid) {
    backgroundColor = theme?.palette?.generic?.sysaid_mid;
    hoveredBackgroundColor = theme?.palette?.generic?.sysaid_mid;
  }

  return {
    cursor: disabled ? 'not-allowed' : 'pointer',
    backgroundColor,
    transition: 'background-color 0.2s ease',
    color,
    borderRadius: '60px',
    padding: '4px 16px',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '> div': {
      height: '20px',
      marginRight: '4px',
    },
    '&:hover': {
      backgroundColor: hoveredBackgroundColor,
    },
  };
});
