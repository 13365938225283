import { useRef, useState, useLayoutEffect, useMemo, useCallback } from 'react';
import Tooltip from 'common/components/tooltip';
import { StyledChip } from 'features/queue/gridFilters/style';
import { ReactComponent as CloseChipIcon } from 'images/icons/chipDelete.svg';
import { ReactComponent as PlusAttach } from 'images/icons/plus-attach.svg';
import { StyledListWrapper, StyledMultiSelectLabel, StyledPlusWrapper } from './style';

const RIGHT_SIDE_OFFSET_WIDTH = 95;
const chipExcludeClassList = ['truncated-items', 'plus-button'];

export default function MultiSelectChipList({
  keyString,
  itemList,
  captionString,
  handleOpen,
  handleDelete,
  placeholder,
  parentHeight,
  isDropdownOpen,
  disabled,
  readOnly = false,
  withTruncatedChips = false,
  isWorkFlowMultiList = false,
}) {
  const [listHeight, setListHeight] = useState(0);
  const [isTruncated, setIsTruncated] = useState(false);
  const [visibleCount, setVisibleCount] = useState(itemList?.length);

  const isUpdatingTruncation = useRef(false);
  const listContainerRef = useRef(null);

  useLayoutEffect(() => {
    setListHeight(listContainerRef?.current?.offsetHeight);

    const calculateVisibleChips = () => {
      if (isUpdatingTruncation.current) return;

      isUpdatingTruncation.current = true;

      const container = listContainerRef?.current;
      if (container) {
        let currentWidth = 0;
        let count = 0;
        const containerWidth = container.clientWidth;
        const chipElements = Array.from(container.children).filter(
          (chip) => !chipExcludeClassList.some((className) => chip.classList?.contains(className)),
        );

        chipElements.forEach((chip) => {
          const chipWidth = chip?.offsetWidth;

          if (currentWidth + chipWidth + RIGHT_SIDE_OFFSET_WIDTH <= containerWidth) {
            currentWidth += chipWidth;
            count += 1;
          }
        });

        setVisibleCount(count);
        setIsTruncated(count > 0 && count < itemList?.length);
        isUpdatingTruncation.current = false;
      }
    };

    if (withTruncatedChips) {
      calculateVisibleChips();
    }
  }, [itemList, withTruncatedChips]);

  const truncatedTooltipText = useMemo(
    () =>
      !isTruncated
        ? null
        : itemList
            ?.slice(visibleCount, itemList?.length)
            ?.map((e) => e?.[captionString])
            ?.join(', '),
    [itemList, visibleCount, captionString, isTruncated],
  );

  const renderList = useCallback(
    () =>
      Array.isArray(itemList)
        ? (isTruncated ? itemList.slice(0, visibleCount) : itemList).map((item) => (
            <StyledChip
              className="multi-select-chip"
              data-testid="multi-select-chip"
              disableCursor={disabled}
              readOnly={readOnly}
              isWorkFlowMultiList={isWorkFlowMultiList}
              key={item?.[keyString]}
              variant="outlined"
              deleteIcon={<CloseChipIcon />}
              onDelete={() => handleDelete(item?.[keyString])}
              label={
                <Tooltip
                  placement="top"
                  isTruncatedText
                  title={item?.[captionString]}
                  text={item?.[captionString]}
                  style={{ fontSize: '0.875rem', lineHeight: 1.2858, color: '#303030' }}
                />
              }
            />
          ))
        : null,
    [
      itemList,
      isWorkFlowMultiList,
      keyString,
      captionString,
      disabled,
      handleDelete,
      isTruncated,
      readOnly,
      visibleCount,
    ],
  );

  return (
    <StyledListWrapper
      className="multi-select-list-wrapper"
      ref={listContainerRef}
      listHeight={listHeight}
      isDropdownOpen={isDropdownOpen}
      noWrap={withTruncatedChips}
    >
      {!itemList?.length && (
        <StyledMultiSelectLabel className="empty-multi-select-chip-placeholder">{placeholder}</StyledMultiSelectLabel>
      )}
      {renderList()}
      {isTruncated ? (
        <StyledChip
          className="multi-select-chip truncated-items"
          data-testid="multi-select-truncation-chip"
          variant="outlined"
          label={
            <Tooltip
              placement="top"
              title={truncatedTooltipText}
              text={`+${itemList?.length - visibleCount}`}
              style={{ fontSize: '0.875rem', lineHeight: 1.2858, color: '#303030' }}
            />
          }
        />
      ) : null}
      {!isDropdownOpen && (
        <StyledPlusWrapper
          className="plus-button"
          listHeight={parentHeight || listHeight || 0}
          disabled={disabled}
          readOnly={readOnly}
        >
          <PlusAttach data-testid="multiselect-plus-btn" onClick={handleOpen} />
        </StyledPlusWrapper>
      )}
    </StyledListWrapper>
  );
}
