import { lazy, Suspense, useMemo } from 'react';
import { journeyConstants } from '../constants/journey';
import { actionEnums } from '../constants';
import { TitleWrapper, LogWrapper } from './style';
import useTexts from './useTexts';
import { LogValue } from '../style';
import LogActionImg from '../logActionImg';
import { useWorkflowAttributeQuery } from '../../Workflows/api/workflowQueries';

const FieldChangeLog = lazy(() => import('../logDescription/fieldChange'));
const StatusChangeLog = lazy(() => import('../logDescription/events/actionItemStatusChange'));
const DateFieldChangeLog = lazy(() => import('../logDescription/events/actionItemDateFieldChange'));

const LogTypeComponentMap = {
  [actionEnums.ACTION_ITEM_STATUS_ENABLED]: StatusChangeLog,
  [actionEnums.ACTION_ITEM_STATUS_COMPLETED]: StatusChangeLog,
  [actionEnums.ACTION_ITEM_DATE_FIELD_CHANGE]: DateFieldChangeLog,
};

export const WorkflowLog = ({ logTitle, logType, fieldName, logInformation, ...props }) => {
  const { oldKey, newKey, oldValue, newValue, fieldDisplayName, camelCaseFieldName, listType, isValueId } =
    logInformation;
  const { getTextByKey, texts } = useTexts();
  const displayName = fieldDisplayName ? getTextByKey(fieldDisplayName) || fieldDisplayName : fieldName;
  const changeText = `${displayName} ${texts?.changedText || ''}`;
  let LogComponent = FieldChangeLog;

  if (LogTypeComponentMap[logType]) {
    LogComponent = LogTypeComponentMap[logType];
  }
  const isDynamicList = listType === journeyConstants.DYNAMIC_LIST;
  const isWorkflowListChange =
    (logType === actionEnums.ACTION_ITEM_FIELD_CHANGE && (isDynamicList || isValueId)) || false;
  const { oldValueForQuery, newValueForQuery, queryFieldName } = useMemo(
    () => ({
      oldValueForQuery: isDynamicList ? oldKey : oldValue,
      newValueForQuery: isDynamicList ? newKey : newValue,
      queryFieldName: camelCaseFieldName || fieldName,
    }),
    [isDynamicList, oldKey, oldValue, newKey, newValue, camelCaseFieldName, fieldName],
  );
  const { data: oldWorkflowLog } = useWorkflowAttributeQuery({
    fieldName: queryFieldName,
    paramValue: oldValueForQuery,
    enabled: isWorkflowListChange && !!oldValueForQuery,
  });

  const { data: newWorkflowLog } = useWorkflowAttributeQuery({
    fieldName: queryFieldName,
    paramValue: newValueForQuery,
    enabled: isWorkflowListChange && !!newValueForQuery,
  });

  return (
    <>
      <LogActionImg logType={logType} />
      <LogValue isShiftedAction>
        {logTitle && <TitleWrapper>{logTitle}</TitleWrapper>}
        <Suspense fallback={null}>
          <LogWrapper textTruncateEnabled={logInformation?.textTruncateEnabled}>
            <div className="change-wrapper">{changeText}</div>
            <LogComponent
              logType={logType}
              logInformation={logInformation}
              oldWorkflowLog={oldWorkflowLog}
              newWorkflowLog={newWorkflowLog}
              {...props}
            />
          </LogWrapper>
        </Suspense>
      </LogValue>
    </>
  );
};
