import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useChannel } from '@ably-labs/react-hooks';
import { APP_CONSTANTS } from 'constants/app';
import { useLoadedSrIdsWithValue } from 'remote-state/ticketServiceHooks';
import { CHANNEL_MESSAGE_TYPES } from 'constants/common';
import { parseChannelData, generateSecretKey } from 'common/utils/decrypt';
import { GRID_MODELS } from '../constants';

const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
const QUEUE_CHANNEL_NAME = accountId;

const combineAndDecyrptUpdateState = ({ prev, data, name }) => {
  try {
    const { existingSrs, updates, isEnabled } = prev;
    if (name === CHANNEL_MESSAGE_TYPES.ADD_TICKET) {
      // If there is addTicket event it means we will trigger new search api request
      // Because of that we will remove all the rest of the update jobs that we have in list because they all redundant.
      return {
        isEnabled,
        existingSrs,
        updates: new Map().set(CHANNEL_MESSAGE_TYPES.ADD_TICKET, { id: null, name, data }),
      };
    }

    const id = data?.srId;
    if (existingSrs && existingSrs[id]) {
      const mapkey = id + name;
      const parsedData = parseChannelData({
        data,
        name,
        secretKey: generateSecretKey({ id, insertTime: existingSrs[id] }),
        prevData: updates.get(mapkey)?.data,
      });
      return { existingSrs, isEnabled, updates: new Map(updates.set(mapkey, { id, name, data: parsedData })) };
    }
    return prev;
  } catch (error) {
    console.error('Websockets: combineAndDescyrptUpdateState', error);
  }
  return prev;
};

const getActiveViewFromStorage = () => {
  const key = 'persist:queue';
  const jsonString = localStorage.getItem(key);

  // Check if the JSON string is present
  if (jsonString) {
    try {
      // Parse the JSON string into a JavaScript object
      const viewData = JSON.parse(jsonString);
      const activeView = JSON.parse(viewData.activeView);
      return {
        filters: activeView.columnsConfig.customFilterModel,
        sorts: activeView.columnsConfig.customSortModel,
        columns: activeView.columnsConfig.columnsOrder,
      };
    } catch (error) {
      // Handle JSON parsing errors
      console.error('Websockets: Error parsing JSON:', error);
      return null; // Return null if parsing fails
    }
  } else {
    // Return null if the JSON string is not found in localStorage
    console.warn(`Websockets: No data found for key "${key}" in localStorage`);
    return null;
  }
};

const isFiltersContainsUpdatedParams = (params) => {
  let isInQueueColumsList = false;
  let isUsedInFilters = false;
  try {
    const { columns, filters, sorts } = getActiveViewFromStorage();
    //first search in columns if user doesnt have that column in view no need to
    for (let i = 0; i < columns.length; i++) {
      const field = columns[i].fieldName;
      if (params.includes(field)) {
        isInQueueColumsList = true;
        break;
      }
    }
    //If field not in columns configuration so it doesnt inside of any filter or sort. Checkings are redundant
    if (isInQueueColumsList) {
      //first search in filters
      for (let i = 0; i < filters.length; i++) {
        const field = filters[i].field;
        if (params.includes(field)) {
          isUsedInFilters = true;
          break;
        }
      }
      //If field already found in filters its redundant to check in sorts.
      if (!isUsedInFilters) {
        for (let i = 0; i < sorts.length; i++) {
          const field = sorts[i].colId;
          if (params.includes(field)) {
            isUsedInFilters = true;
            break;
          }
        }
      }
    }
  } catch (error) {
    console.error('Websockets: Error while checking params in queue configuration', error);
  }
  return {
    isInQueueColumsList: true,
    isUsedInFilters,
  };
};

export const useQueueWebSocketDataSync = ({ gridMode, isEnabled, queryKey }) => {
  const [queueUpdates, setQueueUpdates] = useState({ existingSrs: {}, updates: new Map([]), isEnabled });
  const { data: loadedSrIdsWithKeys } = useLoadedSrIdsWithValue('insertTime');
  const queryClient = useQueryClient();
  const isZenModeOn = gridMode === GRID_MODELS.Zen;
  const invalidateQueryData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey });
  }, [queryClient, queryKey]);

  const updateColAccordingFieldType = useCallback(
    ({ field, newValues }) => {
      const fieldName = field.field;
      const updatedParams = newValues.params;
      //Just in case blocking updates on list of fields that cannot be updated or they are not
      if (!Object.keys(updatedParams).includes(fieldName)) return field;
      const prevValue = field?.value;
      switch (fieldName) {
        case 'assignee':
          field = { ...field, value: { assignee: updatedParams.assignee, assignedGroup: updatedParams.assignedGroup } };
          break;
        // TODO: Currently we have an issue with updateing values of CategoryRender dynamicly and it requires a lot of refactoring so we will just trigger search request. We will remove code below after we made it work properly
        case 'primaryCategory':
          invalidateQueryData();
          break;
        default: {
          //The data that websocket sends can be incomplete so we are checking here is filed exist in that particular object
          //If not it means that value didnt updated so to avoid false undefined values we will keep previous value.
          field = { ...field, value: fieldName in updatedParams ? updatedParams[fieldName] : prevValue };
          break;
        }
      }
      return field;
    },
    [invalidateQueryData],
  );

  const findSrByIdAndUpdate = useCallback(
    ({ id, serviceRequests, newValues }) => {
      if (!Array.isArray(serviceRequests)) return serviceRequests;
      return serviceRequests.reduce((result, sr) => {
        const isUpdatedSr = sr.find((col) => col.field === 'id' && col.value === Number(id));
        let updatedSR = sr;
        if (isUpdatedSr) {
          //If we are here it means we are in correct SR and we need to update values according object that came from websocket
          updatedSR = sr.map((field) => updateColAccordingFieldType({ field, newValues }));
        }
        return [...result, updatedSR];
      }, []);
    },
    [updateColAccordingFieldType],
  );

  const updateSRInCacheById = useCallback(
    ({ id, data }) => {
      queryClient.setQueryData(queryKey, (prevQueue) => {
        if (prevQueue?.serviceRequests && id && data) {
          const updatedServiceRequestsList = findSrByIdAndUpdate({
            id,
            serviceRequests: prevQueue?.serviceRequests,
            newValues: data,
          });
          return { ...prevQueue, serviceRequests: updatedServiceRequestsList };
        }
      });
    },
    [queryClient, queryKey, findSrByIdAndUpdate],
  );

  const unlockTicket = useCallback(
    ({ id }) => {
      queryClient.setQueryData(['ticketLockStatus', id], () => ({
        isLocked: false,
        lockingUser: null,
      }));
    },
    [queryClient],
  );

  const deleteTicket = useCallback(
    ({ id }) => {
      queryClient.setQueryData(queryKey, (prevQueue) => {
        let serviceRequests = prevQueue?.serviceRequests;
        if (Array.isArray(serviceRequests)) {
          const countsResult = {
            count: prevQueue.count,
            countTotal: prevQueue.countTotal,
            srTypeFilterCounterAll: prevQueue.srTypeFilterCounterAll,
          };
          serviceRequests = serviceRequests.filter((sr) => {
            const isFiltered = sr[0].value !== id;
            if (!isFiltered) {
              countsResult.count -= 1;
              countsResult.countTotal -= 1;
              countsResult.srTypeFilterCounterAll -= 1;
            }
            return isFiltered;
          });
          const result = { ...prevQueue, serviceRequests, ...countsResult };
          return result;
        }
        return prevQueue;
      });
    },
    [queryClient, queryKey],
  );

  const lockTicket = useCallback(
    ({ data, id }) => {
      queryClient.setQueryData(['ticketLockStatus', id], () => ({
        isLocked: true,
        lockingUser: data?.userName,
      }));
    },
    [queryClient],
  );

  const updateCacheByType = useCallback(
    ({ id, name, data }) => {
      //using swith because in a future there will be other actions such as adding, removing also
      switch (name) {
        case CHANNEL_MESSAGE_TYPES.UPDATED:
          updateSRInCacheById({ id, data });
          break;

        case CHANNEL_MESSAGE_TYPES.LOCK_TICKET:
          lockTicket({ id, data });
          break;

        case CHANNEL_MESSAGE_TYPES.UNLOCK_TICKET:
          unlockTicket({ id });
          break;

        case CHANNEL_MESSAGE_TYPES.DELETE_TICKET:
          deleteTicket({ id });
          break;

        case CHANNEL_MESSAGE_TYPES.ADD_TICKET:
          invalidateQueryData();
          break;

        default:
          console.warn('Couldnt find appropiate action for given Websocket event', name, data);
          break;
      }
    },
    [lockTicket, unlockTicket, updateSRInCacheById, deleteTicket, invalidateQueryData],
  );

  const [RealtimeChannel] = useChannel(QUEUE_CHANNEL_NAME, ({ data, name }) => {
    //I leaved this console.log intentionally for better testing and help QA understand when there is. Will be removed after QA approve.
    console.log('Websockets: useChannel emited', { data, name });
    const updateStateFunc = () => {
      //We are checking this because sometimes in between unmounting component there fires setState hook which is already undefied and causes issues in WebSocket
      if (setQueueUpdates) {
        setQueueUpdates((prev) => (prev?.isEnabled ? combineAndDecyrptUpdateState({ prev, data, name }) : prev));
      }
    };
    //If the event is sr update we need to do check-ups on top before adding it Queue Updates
    if (name === CHANNEL_MESSAGE_TYPES.UPDATED && data.srParams) {
      const fieldUsage = isFiltersContainsUpdatedParams(data.srParams);
      // If updated filed isnt in queue column list then its no need to make an update
      if (fieldUsage.isInQueueColumsList) {
        if (fieldUsage.isUsedInFilters) {
          invalidateQueryData();
        } else {
          updateStateFunc();
        }
      }
      return;
    }
    updateStateFunc();
  });

  useEffect(() => {
    setQueueUpdates((prev) => ({ ...prev, existingSrs: loadedSrIdsWithKeys }));
  }, [loadedSrIdsWithKeys]);

  useEffect(() => {
    setQueueUpdates((prev) => ({ ...prev, isEnabled }));
  }, [isEnabled]);

  useEffect(() => {
    let isNotUmounted = true;
    const updatesList = queueUpdates.updates;
    if (!isZenModeOn && updatesList.size !== 0) {
      const updateCache = async () => {
        const bulkItems = Array.from(updatesList, ([key, value]) => ({ key, value }));
        await Promise.all([
          ...bulkItems.map(({ key, value }) => {
            if (!isNotUmounted) return;
            const { id, name, data } = value;
            setQueueUpdates((prev) => {
              if (prev.updates.has(key)) {
                prev.updates.delete(key);
              }
              return { ...prev, updates: new Map(prev.updates) };
            });
            return updateCacheByType({ name, data, id });
          }),
        ]).catch((e) => console.error('Websockets: Error happened while applying events from websocket', e));
      };
      updateCache();
    }
    return () => {
      isNotUmounted = false;
    };
  }, [isZenModeOn, queueUpdates, updateCacheByType]);

  return { isConnectionFailed: RealtimeChannel.state === 'failed' };
};

export default useQueueWebSocketDataSync;
