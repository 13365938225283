const SETTINGS_MENU_COLORS = {
  52: (theme) => theme.palette.blueLabels.bt_light_blue_hover, //customization
  95: (theme) => theme.palette.generic.sysaid_default, //administration
  112: (theme) => theme.palette.labels.bt_orange_hover, //tracking
  119: (theme) => theme.palette.labels.bt_purple_mid, //monitoring
};

export const BASIC_EDITION = 0;
export const FULL_EDITION = 2;
export const SETTINGS_MENU_ITEM_ID = 51;
export const TEMPLATES_MENU_ITEM_ID = 52;
export const TEMPLATES_SUB_MENU_LIST_ID = 53;
export const TEMPLATES_SUB_MENU_ITEM_ID = 54;

export { SETTINGS_MENU_COLORS };
