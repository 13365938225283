import { styled } from '@mui/material/styles';

export const OpenTicketWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const OpenTextWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

export const OpenTextPara = styled('p')(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.blue_user_name,
}));
