import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledNewTicket = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ theme, expanded }) => ({
  margin: `0 ${expanded ? '20px 12px' : '8px 0'}`,
  height: '40px',
  '& button': {
    all: 'unset',
  },
  '& :hover': {
    cursor: 'pointer',
  },
  '& svg': {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      '.svg_path': {
        fill: theme.palette.generic.sysaid_default_hover,
      },
    },
  },
  '& .capsule': {
    display: 'flex',
    width: '200px',
    height: '40px',
    borderRadius: '60px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.generic.sysaid,
    color: theme.palette.generic.primary,
    fontSize: '16px',
    lineHeight: '16px',

    '& svg': {
      width: '16px',
      height: '16px',
    },

    ':hover': {
      backgroundColor: theme.palette.generic.sysaid_default_hover,
    },
    '.capsule-left': {
      display: 'flex',
      boxSizing: 'border-box',
      width: '160px',
      borderRight: '1px solid rgba(255, 255, 255, 0.6)',
      padding: '12px 26px 12px 24px',

      '& svg': {
        marginRight: '12px',
      },
    },

    '.capsule-right': {
      display: 'flex',
      maxWidth: '40px',
      padding: '12px 14px 12px 10px',
    },
  },
}));
