import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTicketLockStatus, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { getFilterAuditLogTypes, getMoreTicketAuditLogByFilter } from 'services/auditLogsService';
import useTexts from 'features/resolutionPanel/useTexts';
import { selectActiveUser } from 'store/userSlice';
import plusIcon from 'images/icons/black-plus-icon.svg';
import { LOG_DESCRIPTION_BREAK_LINE_TYPES } from 'features/resolutionPanel/constants';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useAuditLog';
import { Attachments } from 'features/attachments/attachmentsList';
import { useJourneyLogsByFilterQuery } from 'remote-state/useAuditLogsHooks';
import { Box, CircularProgress, Link } from '@mui/material';
import { useTicketAttachments } from 'remote-state/attachments';
import LogTitle from './logTitle';
import TitleImg from './logActionImg/titleImg';
import LogMetaData from './logMetaData';
import ActionLine from './actionLine';
import { actionEnums, FILTER_ENUMS, HAS_FOOTER } from './constants/actionEnums';
import { selectActionLine, selectAuditLog, updateAuditLogTypes } from './store/slice';
import {
  AuditLogInner,
  LogValue,
  StyledAuditLog,
  StyledAuditLogRow,
  StyledLoading,
  StyledPriorityReason,
} from './style';
import { AuditLogEvent } from './logDescription/event';
import { EditableEditor, EditorChange } from './logDescription/editor';
import { Notification } from './logDescription/notification';
import { NoteFooter } from './logDescription/editor/customFooters';
import AuditLogFieldChange from './logDescription/fieldChange';
import { MessageFooter } from './logDescription/editor/customFooters/messageFooter';
import { ReadOnlyEditor } from './logDescription/editor/readOnlyEditor';
import { editorActionTypes } from './constants/editorActionTypes';
import { EditableSolutionResolution } from './logDescription/editor/editableSolutionResolution';
import OutOfOffice from './logDescription/events/outOfOffice';
import ActionItemStatusChange from './logDescription/events/actionItemStatusChange';
import LogActionImg from './logActionImg';
import { StyledLogDescription } from './logDescription/style';
import { WorkflowLog } from './workflowLog';
import MigrationHistoryEvent from './logDescription/events/migrationHistory';
import ActivityLog from './actionLine/activities/components/ActivityLog';
import { useApplicationData } from '../../../../remote-state/applicationHooks';
import { QUERIES_KEYS } from '../../../../constant';
import TicketConversionEvent from './logDescription/events/ticketConversion';
import { TitleWrapper } from './workflowLog/style';
import { isDeleted } from './utils';

const SHIFTED_ACTIONS = [
  actionEnums.NOTE_CREATE,
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.SOLUTION_RESOLUTION_CREATED,
  actionEnums.NOTE_UPDATE,
  actionEnums.SOLUTION_UPDATED,
  actionEnums.RESOLUTION_UPDATED,
  actionEnums.FIELD_DESCRIPTION,
  actionEnums.FIELD_REASSIGNMENT,
  actionEnums.FIELD_OUT_OF_OFFICE,
  actionEnums.NOTIFICATION,
  actionEnums.NOTE_DELETE,
  actionEnums.ATTACHMENTS_ADDED,
  actionEnums.ATTACHMENTS_DELETED,
  actionEnums.ATTACHMENTS_DOWNLOADED,
  actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED,
  actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
  actionEnums.ESCALATION_MESSAGE,
];

const EDITOR_ACTIONS = [
  actionEnums.NOTE_CREATE,
  actionEnums.NOTE_UPDATE,
  actionEnums.NOTE_DELETE,
  actionEnums.NOTIFICATION,
  actionEnums.SOLUTION_RESOLUTION_CREATED,
  actionEnums.SOLUTION_UPDATED,
  actionEnums.RESOLUTION_UPDATED,
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.ACTIVITY_CREATED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
  actionEnums.ESCALATION_MESSAGE,
  actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED,
  actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED,
  actionEnums.ATTACHMENTS_ADDED
];

const ACTIONS_WITHOUT_TITLE = [
  actionEnums.NOTE_CREATE,
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.SOLUTION_RESOLUTION_CREATED,
  actionEnums.FIELD_REASSIGNMENT,
  actionEnums.FIELD_OUT_OF_OFFICE,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
];

const hasLog = (data) => data?.length > 0 && !data[0].logInformation.isDisabledJourneyLog;

export default function AuditLog({
  srPanelCollapsed,
  srId,
  selectedFilter,
  currentAuditLogsByFilter,
  auditLogsRef,
  scrollToAuditLogRecord,
  srType,
  showActionLineShadow,
}) {
  const dispatch = useDispatch();
  const texts = useTexts();
  const { type: editorType } = useSelector(selectActionLine);
  const ticketAuditLogs = useSelector(selectAuditLog);
  const { toggleAuditLogsProperty, getTicketAuditLogs, getTicketAuditLogsByType, updateAuditLogs, isLoading } =
    useAuditLog();
  const previousTicketId = usePreviousValue(srId);
  const { data: hasEditPermissions } = useTicketPermissions('edit', srId === 'new' ? null : srId);
  const { data: isResolutionWithLog } = useJourneyLogsByFilterQuery({
    srId,
    ...(editorType === editorActionTypes.RESOLUTION && { filterType: 'resolution' }),
    select: hasLog,
  });
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const {
    data: { manualPriorityChangesEnabled },
  } = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  const { updateSRAttachmentsAfterDelete } = useTicketAttachments();

  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;

  const showActionLine = hasEditPermissions && !isTicketLocked && !isResolutionWithLog;

  const isActionItemAttachmentLog = (auditLog) =>
    (auditLog?.logType === actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED ||
      auditLog?.logType === actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED) &&
    auditLog.logInformation.actionItemName;

  const currentAuditLogsByFilterList = currentAuditLogsByFilter?.list || [];

  const shouldFetchAuditLogTypes = useMemo(
    () => Object.keys(ticketAuditLogs).every((filter) => ticketAuditLogs[filter]?.types?.length === 0),
    [ticketAuditLogs],
  );

  const getAuditLogTypes = useCallback(async () => {
    try {
      const allFiltersArr = Object.keys(ticketAuditLogs);
      const filterTypes = await getFilterAuditLogTypes(allFiltersArr);
      if (filterTypes) {
        allFiltersArr.forEach((filter) => {
          dispatch(updateAuditLogTypes({ filter, filterTypes }));
        });
      }
    } catch (e) {
      //TODO: handle error on fetching audit log filters types
      console.log(e);
    }
  }, [dispatch, ticketAuditLogs]);

  // when select filter value changes -> if selected for the first time then get data from server(initial 200 rows)
  const filterTicketAuditLogs = useCallback(async () => {
    if (!currentAuditLogsByFilterList?.length || previousTicketId !== srId) {
      await getTicketAuditLogs();
      // needed to calculate total activities
      await getTicketAuditLogsByType(FILTER_ENUMS.ACTIVITIES);
    }
  }, [getTicketAuditLogs, getTicketAuditLogsByType, srId, previousTicketId, currentAuditLogsByFilterList?.length]);

  useEffect(() => {
    // get each filter's types for the first time(so that if now we see list of 'summary', and we add any log that not on summary,
    // we want the change to be added to the other appropriate lists, therefore we must know the types at first)
    if (shouldFetchAuditLogTypes) {
      getAuditLogTypes();
    }
  }, [getAuditLogTypes, shouldFetchAuditLogTypes]);

  useEffect(() => {
    // called every time when select filter value changes, including on first render
    // get the proper list of audit list from the server ONLY ON FIRST RENDER according to the select filter value
    filterTicketAuditLogs();
  }, [selectedFilter, filterTicketAuditLogs, srId]);

  // get another max 200 rows(if exist) of audit logs according to the filter selected
  const onClickLoadMoreLogs = async () => {
    try {
      const lastId = currentAuditLogsByFilterList[0].id;
      const res = await getMoreTicketAuditLogByFilter(srId, selectedFilter, lastId);
      if (res?.length) dispatch(updateAuditLogs({ log: res }));
    } catch (e) {
      //TODO: handle error on fetching audit log filters types
      console.log(e);
    }
  };
  const initRef = (el, id) => {
    auditLogsRef.current[id] = el;
    return null;
  };

  const onAttachmentDeleted = async (attachmentId) => {
    updateSRAttachmentsAfterDelete(attachmentId);
    await getTicketAuditLogs();
  };

  const renderDescriptionByType = (auditLog) => {
    switch (auditLog?.logType) {
      // event
      case actionEnums.OPERATION_CREATE:
      case actionEnums.EVENT_CLOSE:
      case actionEnums.EVENT_REOPEN:
      case actionEnums.EVENT_DUE_DATE:
      case actionEnums.EVENT_VIEW:
        return <AuditLogEvent auditLog={auditLog} srPanelCollapsed={srPanelCollapsed} />;

      case actionEnums.TICKET_MIGRATION_HISTORY:
        return <MigrationHistoryEvent srId={auditLog.srId} logTimestamp={auditLog.logTimestamp} />;

      case actionEnums.TICKET_CONVERSION:
        return (
          <TicketConversionEvent
            srId={auditLog.srId}
            oldSrType={auditLog.logInformation.srType.oldValue}
            newSrType={auditLog.logInformation.srType.newValue}
          />
        );

      case actionEnums.NOTE_CREATE:
      case actionEnums.NOTE_DELETE:
        return (
          <EditableEditor
            value={auditLog.logInformation.noteText}
            logType={auditLog.logType}
            editorType={editorActionTypes.NOTE}
            customFooter={<NoteFooter {...auditLog.logInformation} />}
            toggleAuditLogsProperty={toggleAuditLogsProperty}
            isRichTextTruncated={auditLog.isRichTextTruncated}
            isEdited={auditLog.isEdited}
            auditLogId={auditLog.id}
            logInformation={auditLog.logInformation}
            isTicketLocked={isTicketLocked}
            showKebabMenu
          />
        );
      case actionEnums.NOTE_UPDATE:
      case actionEnums.SOLUTION_UPDATED:
      case actionEnums.RESOLUTION_UPDATED:
        return (
          <EditorChange
            newValue={auditLog.logInformation.newValue?.noteText}
            oldValue={auditLog.logInformation.oldValue?.noteText}
            oldCustomFooter={
              HAS_FOOTER.includes(auditLog.logType) && <NoteFooter {...auditLog.logInformation.oldValue} />
            }
            newCustomFooter={
              HAS_FOOTER.includes(auditLog.logType) && <NoteFooter {...auditLog.logInformation.newValue} />
            }
            logType={auditLog.logType}
            toggleAuditLogsProperty={toggleAuditLogsProperty}
            isRichTextTruncated={auditLog.isRichTextTruncated}
            auditLogId={auditLog.id}
            showKebabMenu={false}
            logInformation={auditLog.logInformation}
            isTicketLocked={isTicketLocked}
          />
        );
      case actionEnums.MESSAGE_SENT:
      case actionEnums.AI_MESSAGE_SEND:
      case actionEnums.MESSAGE_RECEIVED:
      case actionEnums.IM_MESSAGE:
      case actionEnums.SMS_MESSAGE:
      case actionEnums.ITIL_MESSAGE:
        return (
          <ReadOnlyEditor
            value={auditLog.logInformation.messageText}
            logType={auditLog.logType}
            customFooter={
              auditLog.logType !== actionEnums.AI_MESSAGE_SEND && (
                <MessageFooter
                  logType={auditLog.logType}
                  logInformation={auditLog.logInformation}
                  auditLogId={auditLog.id}
                  timestamp={auditLog.logTimestamp}
                />
              )
            }
            toggleAuditLogsProperty={toggleAuditLogsProperty}
            isRichTextTruncated={auditLog.isRichTextTruncated}
            auditLogId={auditLog.id}
            logInformation={auditLog.logInformation}
            showTruncationButton
            scrollToRecord={scrollToAuditLogRecord}
            isTicketLocked={isTicketLocked}
          />
        );
      case actionEnums.ATTACHMENTS_ADDED:
      case actionEnums.ATTACHMENTS_DELETED:
      case actionEnums.ATTACHMENTS_DOWNLOADED:
      case actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED:
      case actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED:
        return (
          <Attachments
            logType={auditLog?.logType}
            auditLogId={auditLog?.id}
            attachments={auditLog.logInformation.attachments}
            isDeleted={isDeleted(auditLog)}
            isDownloaded={auditLog?.logType === actionEnums.ATTACHMENTS_DOWNLOADED}
            deleteAttachmentEnabled
            onAttachmentDeleted={onAttachmentDeleted}
            srPanelCollapsed={srPanelCollapsed}
          />
        );
      case actionEnums.SOLUTION_RESOLUTION_CREATED:
        return (
          <EditableSolutionResolution
            solutionValue={auditLog.logInformation.solution.text}
            resolutionValue={auditLog.logInformation.resolution.text}
            logType={auditLog.logType}
            editorType={editorActionTypes.RESOLUTION}
            toggleAuditLogsProperty={toggleAuditLogsProperty}
            isRichTextTruncated={auditLog.isRichTextTruncated}
            isEdited={auditLog.isEdited}
            auditLogId={auditLog.id}
            logInformation={auditLog.logInformation}
            isTicketLocked={isTicketLocked}
            hasEditPermissions={hasEditPermissions}
          />
        );
      case actionEnums.SOLUTION_NEW_ARTICLE_CREATED:
        return (
          <Link component="a" href={auditLog.logInformation.link}>
            {auditLog.logInformation.text}
          </Link>
        );
      case actionEnums.NOTIFICATION:
      case actionEnums.ESCALATION_MESSAGE:
        return <Notification logInformation={auditLog.logInformation} />;
      case actionEnums.FIELD_OUT_OF_OFFICE:
        return (
          <OutOfOffice
            userName={auditLog?.logInformation.assignedUserOutOfOffice}
            reAssignment={auditLog?.logInformation.reassignedUser}
          />
        );
      case actionEnums.ESCALATION_RESET:
      case actionEnums.SOLUTION_SR_RESOLVED:
        return null;
      case actionEnums.ACTION_ITEM_STATUS_COMPLETED:
      case actionEnums.ACTION_ITEM_STATUS_ENABLED:
        return <ActionItemStatusChange logType={auditLog.logType} />;
      case actionEnums.ACTIVITY_CREATED:
        return (
          <ActivityLog
            auditLog={auditLog}
            editorType={editorActionTypes.ACTIVITY}
            toggleAuditLogsProperty={toggleAuditLogsProperty}
          />
        );
      default:
        return (
          <AuditLogFieldChange
            logInformation={auditLog?.logInformation}
            logType={auditLog?.logType}
            isRichTextTruncated={auditLog.isRichTextTruncated}
            auditLogId={auditLog?.id}
            srType={srType}
          />
        );
    }
  };

  return (
    <StyledAuditLog
      isLoadMoreButtonDisplayed={currentAuditLogsByFilterList?.length < currentAuditLogsByFilter?.totalCount}
    >
      {currentAuditLogsByFilterList?.length < currentAuditLogsByFilter?.totalCount && (
        <button className="load-more-audit-logs-button" onClick={onClickLoadMoreLogs}>
          {texts?.auditLogLoadMore}
        </button>
      )}
      {currentAuditLogsByFilterList?.length ? (
        <div id="scrollableDiv" className="scrollable-div">
          <InfiniteScroll
            dataLength={currentAuditLogsByFilterList.length}
            next={onClickLoadMoreLogs}
            hasMore
            loader={<h4>{texts?.auditLogLoading}</h4>}
            scrollableTarget="scrollableDiv"
          >
            {currentAuditLogsByFilterList
              .filter((auditLog) => !auditLog.logInformation?.isDisabledJourneyLog)
              .map((auditLog, index) => (
                <StyledAuditLogRow
                  data-testid={`audit-log${
                    auditLog?.logInformation?.fieldName ? `-${auditLog?.logInformation?.fieldName}` : ''
                  }`}
                  className={`audit-log-row ${index === 0 ? 'first-row' : ''} ${
                    index === currentAuditLogsByFilterList.length - 1 ? 'last-row' : ''
                  } ${auditLog?.logType === actionEnums.FIELD_DESCRIPTION ? 'description-log-type' : ''}
                ${
                  auditLog?.logType === actionEnums.NOTE_CREATE ||
                  auditLog?.logType === actionEnums.MESSAGE_SENT ||
                  auditLog?.logType === actionEnums.AI_MESSAGE_SEND ||
                  auditLog?.logType === actionEnums.IM_MESSAGE ||
                  auditLog?.logType === actionEnums.SMS_MESSAGE ||
                  auditLog?.logType === actionEnums.ITIL_MESSAGE ||
                  actionEnums.SOLUTION_RESOLUTION_CREATED
                    ? 'editor-log-type'
                    : ''
                } ${LOG_DESCRIPTION_BREAK_LINE_TYPES.includes(auditLog.logType) ? 'break-line' : ''}
                `}
                  key={auditLog.id}
                  fullWidth={auditLog.isEdited}
                  ref={(el) => initRef(el, auditLog.id)}
                  isEditMode={auditLog.isEdited}
                >
                  <AuditLogInner
                    isEditorAction={EDITOR_ACTIONS.includes(auditLog?.logType)}
                    isEditMode={auditLog.isEdited}
                  >
                    {!auditLog?.logInformation?.actionItemId || isActionItemAttachmentLog(auditLog) ? (
                      <>
                        <LogActionImg logType={auditLog.logType} />
                        <LogValue
                          isShiftedAction={
                            SHIFTED_ACTIONS.includes(auditLog?.logType) ||
                            auditLog?.logInformation?.fieldName === 'description' ||
                            auditLog?.logInformation?.fieldName === 'title'
                          }
                          isEditorAction={EDITOR_ACTIONS.includes(auditLog?.logType)}
                        >
                          {isActionItemAttachmentLog(auditLog) && (
                            <TitleWrapper>{auditLog.logInformation.actionItemName}</TitleWrapper>
                          )}
                          <LogTitle auditLog={auditLog} isEditorAction={EDITOR_ACTIONS.includes(auditLog?.logType)} />
                          <StyledLogDescription
                            hasTitle={ACTIONS_WITHOUT_TITLE.includes(auditLog?.logType)}
                            logType={auditLog?.logType}
                            isEditMode={auditLog.isEdited}
                            isEditorAction={EDITOR_ACTIONS.includes(auditLog?.logType)}
                            data-testid="log-information"
                          >
                            {renderDescriptionByType(auditLog)}
                          </StyledLogDescription>
                          {auditLog?.logType === actionEnums.FIELD_PRIORITY &&
                            auditLog?.logInformation?.reason &&
                            manualPriorityChangesEnabled && (
                              <StyledPriorityReason>
                                <span>{texts.reasonText}</span>
                                <span>{auditLog.logInformation.reason}</span>
                              </StyledPriorityReason>
                            )}
                        </LogValue>
                      </>
                    ) : (
                      <WorkflowLog
                        auditLogId={auditLog.id}
                        logType={auditLog.logType}
                        logInformation={auditLog.logInformation}
                        logTitle={auditLog.logInformation.actionItemName}
                        fieldName={auditLog.logInformation?.fieldName}
                        toggleAuditLogsProperty={toggleAuditLogsProperty}
                        isRichTextTruncated={auditLog.isRichTextTruncated}
                      />
                    )}
                  </AuditLogInner>
                  {!auditLog.isEdited && <LogMetaData auditLog={auditLog} srPanelCollapsed={srPanelCollapsed} />}
                </StyledAuditLogRow>
              ))}
          </InfiniteScroll>
        </div>
      ) : (
        <>
          {isLoading ? (
            <StyledLoading>
              <CircularProgress size={18} color="success" />
              <Box>{texts.auditLogLoading}</Box>
            </StyledLoading>
          ) : (
            <div>
              {/* will be removed in the future, check with product what needs to be */}
              <h4>
                {texts?.auditLogNo} {texts[`${selectedFilter}Filter`]} {texts?.auditLogRecords}
              </h4>
            </div>
          )}
        </>
      )}
      <div className={`audit-log-action-line ${editorType}`}>
        {showActionLine && (
          <>
            <TitleImg className="title-img" editorType={editorType} imgSrc={plusIcon} isPlusIcon />
            <ActionLine editorType={editorType} scrollToRecord={scrollToAuditLogRecord} />
            <div
              className={`action-line-shadow ${showActionLineShadow ? 'active' : ''}`}
              data-testid="action-line-shadow"
            />
          </>
        )}
      </div>
    </StyledAuditLog>
  );
}
