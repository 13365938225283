import { styled } from '@mui/material/styles';
import MuiTextField, { textFieldClasses } from '@mui/material/TextField';

export const StyledCommonTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) =>
    !['unread', 'error', 'ticket', 'fullwidth', 'disabled', 'isNewField', 'isTemplatePage', 'isLocked', 'isTextAreaFocused'].includes(prop),
})(({ theme, unread, error, fullwidth, disabled, ticket, isNewField, isTemplatePage, multiline, isTextAreaFocused }) => {
  let background = 'inherit';
  let hoveredBackground = theme.palette.background.bg_hover_main;
  const isPointerEventsOn = disabled === undefined || !isTextAreaFocused && disabled && multiline;
  const pointerEventsWhenTemplatePage = isPointerEventsOn ? 'auto' : 'none';

  let color = theme?.palette?.text?.txt_default;

  if (unread) {
    color = theme?.palette?.text?.title_txt;
  }
  if (ticket) {
    color = theme?.palette?.text?.txt_default;
  }

  if (isNewField && !error && !isTemplatePage) {
    background = '#EBF5FF';
    hoveredBackground = '#D5E4F2';
  }

  if (disabled && !error) {
    hoveredBackground = 'transparent';
    background = 'transparent';
  }

  return {
    [`&.${textFieldClasses.root}`]: {
      background,
      color: error && theme?.palette?.labels.bt_red_reg,
      width: fullwidth ? '100%' : 'min(100%, 224px)',
      borderRadius: '4px',
      border: !error ? theme.shape.border.border : 'none',
      borderColor: isTextAreaFocused? theme?.palette?.grey?.divider_grey_v1 :'transparent',
      scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
      scrollbarWidth: 'thin',

      '.MuiInputBase-root': {
        alignItems: multiline && 'flex-start',
      },
      '.Mui-disabled': {
        textFillColor: color,
      },
      '& .warning-icon': {
        margin: multiline ? '2px 2px 0' : '0px 2px',
        display: 'flex',
      },
      '&:hover': {
        '.MuiInput-input': {
          color: error && theme?.palette?.labels.bt_red_dark,
          textFillColor: error && theme?.palette?.labels.bt_red_dark,
          '&::placeholder': {
            color: error && theme?.palette?.labels.bt_red_dark,
            opacity: 1,
          },
        },
        '.Mui-disabled': {
          fontFamily: unread ? theme.fonts.robotoBold : theme.fonts.robotoRegular,
          color: error ? theme?.palette?.labels.bt_red_dark : color,
          cursor: 'default',
        },
        ':not(.Mui-focused):hover': {
          background: hoveredBackground,
          color: error && theme.palette.labels.bt_red_dark,
          borderRadius: '4px',
        },
        '& .warning-icon *': {
          fill: theme.palette.labels.bt_red_dark,
        },
      },
      '.Mui-focused': {
        margin: isTextAreaFocused?'0':'-1px',
        background: '#fff',
        border: isTextAreaFocused?'none':theme.shape.border.border,
        borderRadius: theme.shape.border.borderRadius,
        color: theme?.palette?.text?.txt_default,
        scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
        scrollbarWidth: 'thin',

        '.MuiInput-input': {
          color: theme?.palette?.text?.txt_default,
          textFillColor: theme?.palette?.text?.txt_default,

          '&::placeholder': {
            color: theme?.palette?.text?.txt_default,
          },
        },
        '*::-webkit-scrollbar': {
          width: '4px',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          background: theme?.palette?.grey?.divider_grey_v1,
        },
        '*::-webkit-scrollbar-track': {
          marginTop: '2px',
          marginBottom: '2px',
        },
        '& span[data-error-tooltip=true], .warning-icon *': {
          display: 'none',
        },
      },
      '.MuiFormHelperText-root': {
        color: theme?.palette?.text?.secondary_txt,
        fontFamily: theme?.fonts.robotoRegular,
        border: 'none',
      },
      '.MuiInput-input': {
        padding: 0,
        textOverflow: 'ellipsis',
        height: isTemplatePage ? '22px' : 'auto',
        pointerEvents: isTemplatePage ? pointerEventsWhenTemplatePage : 'auto',
        '&::placeholder': {
          // color: error && theme?.palette?.labels.bt_red_reg,
          opacity: 1,
        },
      },
      '.MuiInput-root': {
        maxWidth: '100%',
        padding: isTemplatePage ? '6px 8px' : '4px 8px',
        marginLeft: '0px',
        fontSize: '14px',
        fontFamily: unread ? theme.fonts.robotoBold : theme.fonts.robotoRegular,
        color: unread ? theme?.palette?.text?.title_txt : color,
      },
      '*::-webkit-scrollbar': {
        width: '0',
      },
      '*::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: theme?.palette?.grey?.divider_grey_v1,
      },
      '*::-webkit-scrollbar-track': {
        marginTop: '2px',
        marginBottom: '2px',
      },
    },

    // Hide number field arrows
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0',
    },
    /* Firefox */
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  };
});
