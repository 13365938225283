import GlobalStyles from '@mui/material/GlobalStyles';

export function GlobalStyle() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        body: {
          margin: 0,
          height: '100%',
          fontFamily: 'Roboto-Regular',
          transition: 'background .3s',
        },
        '#root': {
          height: '100%',
        },
        html: {
          height: '100%',
        },

        '& .ok-btn': {
          fontFamily: theme.fonts.robotoRegular,
          fontSize: '1rem',
          lineHeight: '16px',
          flexGrow: '0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '10px',
          padding: '8px 16px',
          borderRadius: '60px',
          backgroundColor: theme.palette.grey.bt_def_grey_reg,
          color: '#FFFFFF',
          border: 'none',
          cursor: 'pointer',
          ':hover': {
            background: theme.palette.grey.bt_def_grey_hover,
          },
          ':disabled': {
            backgroundColor: theme.palette.grey.bt_def_grey_light,
            cursor: 'not-allowed',
          },
        },
        '& .cancel-btn': {
          fontFamily: theme.fonts.robotoRegular,
          fontSize: '1rem',
          lineHeight: '16px',
          flexGrow: '0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '10px',
          padding: '8px 16px',
          borderRadius: '60px',
          color: theme.palette.icons.bt_def_grey_reg,
          backgroundColor: theme.palette.grey.bt_alt_grey_reg,
          border: 'none',
          cursor: 'pointer',
          ':hover': {
            color: theme.palette.text.title_txt,
            backgroundColor: theme.palette.grey.bt_def_grey_light,
          },
          ':disabled': {
            color: theme.palette.grey.bt_def_grey_light,
            backgroundColor: theme.palette.grey.bt_alt_grey_light,
            cursor: 'not-allowed',
          },
        },
        '.mandatory-asterisk': {
          color: theme.palette.labels.bt_red_reg,
        },
        //custom style for grid column dragging element
        '.ag-dnd-ghost , .ag-theme-alpine.ag-dnd-ghost': {
          display: 'flex !important',
          flexDirection: 'row !important',
          gap: '8px !important',
          paddingLeft: '8px !important',
          paddingRight: '58px !important',
          height: '64px !important',
          backgroundColor: theme?.palette?.grey?.bt_alt_grey_light,
          fontWeight: 'normal',
          opacity: 0.8,

          'body:not:has(.unlockedColumn)': {
            borderLeftWidth: '4px !important',
            borderLeftColor: '#699D8C !important',
            borderLeftStyle: 'solid !important',
            borderRadius: '0px !important',
          }
        },
        '.ag-dnd-ghost-icon': {
          marginRight: '0px !important',
        },

        '.ag-dnd-ghost-icon div': {
          display: 'flex !important',
          flexDirection: 'row !important',
          alignItems: 'center !important',
          justifyContent: 'center !important',
        },
        '.ag-dnd-ghost-label': {
          fontFamily: "'Roboto-Regular' !important",
          lineHeight: '20px !important',
          fontSize: '14px !important',
          color: '#4F4F4F  !important',
        },
        '.inline-field-tooltip': {
          width: '100%',
        },
        '.error-tooltip .MuiTooltip-tooltip': {
          background: '#DE4949 !important',
          color: '#FFFFFF !important',
        },
        '.error-tooltip .MuiTooltip-arrow': {
          color: '#DE4949 !important',
        },
      })}
    />
  );
}
