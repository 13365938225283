import PropTypes from 'prop-types';
import NavLink from 'features/navBar/navLink';
import { icons } from './icons';
import Title from './menuTitle';
import useTexts from './useTexts';
import { StyledMenuButtonsContainer } from './styles';

const MenuItem = ({ id: subMenuListId, rbToken, subMenuList, searchFilter, menuItemId, highlightColor }) => {
  const texts = useTexts();
  const Icon = icons[subMenuListId];

  return (
    <StyledMenuButtonsContainer className="menu-item">
      {Icon && <Icon data-testid="icon" />}
      <div className="menu-item-header" data-testid={texts[rbToken]?.replaceAll(' ', '')}>
        <Title
          title={texts[rbToken] || ''}
          highlightText={searchFilter}
          highlightColor={highlightColor}
          id={rbToken?.replaceAll('.', '-')}
          className="title"
        />
        <div className="links-wrapper">
          {subMenuList.map(({ id, rbToken, url, isOpenInNewTab }) => {
            const setting = { menuItemId, subMenuListId, subMenuItemId: id };
            return (
              <NavLink
                data-testid={`${id}-nav-link`}
                settingIds={setting}
                to={url}
                isOpenInNewTab={isOpenInNewTab}
                isSettingsPage
                key={`${id}-sub-menu-title`}
              >
                <Title
                  id={rbToken?.replaceAll('.', '-')}
                  className="link-menu-title"
                  title={texts[rbToken] || ''}
                  highlightText={searchFilter}
                  highlightColor={highlightColor}
                />
              </NavLink>
            );
          })}
        </div>
      </div>
    </StyledMenuButtonsContainer>
  );
};

Title.propTypes = {
  rbToken: PropTypes.string,
  subMenuList: PropTypes.array,
  searchFilter: PropTypes.string,
  highlightColor: PropTypes.string,
};

Title.defaultProps = {
  rbToken: '',
  subMenuList: [],
  searchFilter: '',
};

export default MenuItem;
