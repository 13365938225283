import { Input } from '@mui/material';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import useTexts from 'features/resolutionPanel/useTexts';
import useIsFirstRender from 'common/utils/hooks/useIsFirstRender';
import { StyledSubjectInput } from './style';

const SubjectInput = ({ title, srId }) => {
  const { handleEditorChange } = useEditorActions();
  const { subjectFormat } = useTexts();
  const subjectFormatText = subjectFormat(title, srId);
  const indexOfTitle = subjectFormatText.indexOf(title);
  const isFirstRender = useIsFirstRender();
  const srIdPrefix = subjectFormatText.slice(0, indexOfTitle);

  if (title && isFirstRender) {
    const completeSubject = `${srIdPrefix}${title}`;
    handleEditorChange('completeSubject', { completeSubject });
    handleEditorChange('subject', { subject: title });
  }

  const onChange = (e) => {
    handleEditorChange('subject', { subject: e.target.value });
    handleEditorChange('completeSubject', { completeSubject: `${srIdPrefix}${e.target.value}` });
  };

  return (
    <StyledSubjectInput>
      {srId && (
        <span className="sr-id" data-testid="messages-subject-prefix">
          {srIdPrefix}
        </span>
      )}
      <Input
        disableUnderline
        type="text"
        name="subject"
        value={title}
        onChange={onChange}
        data-testid="messages-subject-input"
      />
    </StyledSubjectInput>
  );
};

export default SubjectInput;
