import { merge } from 'lodash-es';
import { styled } from '@mui/material/styles';
import MuiTextField, { textFieldClasses } from '@mui/material/TextField';

export const TitleTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) =>
    prop !== 'error' &&
    prop !== 'disabled' &&
    prop !== 'isNewField' &&
    prop !== 'wasInFocus' &&
    prop !== 'forceShowValidation' &&
    prop !== 'sysaidVariant',
})(({ sysaidVariant, theme, error, disabled, isNewField, wasInFocus, forceShowValidation }) => {
  let color = theme?.palette?.text?.title_txt;
  let hoveredColor = theme?.palette?.text?.txt_default;
  let backgroundColor = 'transparent';
  let hoveredBackground = theme?.palette?.grey?.divider_grey_v1;
  const focusedBackground = theme?.palette?.generic?.primary;
  let styles = {};
  switch (sysaidVariant) {
    case 'subTitle':
      color = theme?.palette?.text?.txt_default;
      hoveredColor = theme?.palette?.text?.txt_default;
      if (disabled) {
        backgroundColor = 'transparent';
      }
      if (isNewField) {
        backgroundColor = theme?.palette?.background?.action;
      }

      if (error && (wasInFocus || forceShowValidation)) {
        color = theme?.palette?.labels.bt_red_reg;
        backgroundColor = theme?.palette?.labels.bt_red_light;
        hoveredColor = theme?.palette?.labels.bt_red_hover;
      }
      styles = {
        [`&.${textFieldClasses.root}`]: {
          '.Mui-focused': {
            '.MuiInput-input': {
              border: `1px solid ${theme?.palette?.border?.placeholder}`,
            },
          },
          '.MuiInput-input': {
            fontSize: '18px',
            padding: '2px 6px',
            border: '1px solid transparent',
            background: backgroundColor,
            color: hoveredColor,
            '&::placeholder': {
              color,
            },
          },
          background: backgroundColor,
          '.MuiInput-root': {
            fontSize: theme?.fontSize?.medium,
            '&:hover': {
              background: disabled && 'none',
            },
          },
        },
      };
      break;
    case 'templateDesignerTitle':
    default:
      if (disabled) {
        backgroundColor = 'transparent';
        hoveredBackground = 'transparent';
      }
      if (isNewField) {
        color = theme?.palette?.text?.title_txt;
        hoveredColor = theme?.palette?.text?.txt_default;
        hoveredBackground = theme?.palette?.grey?.divider_grey_v1;
      }

      if (error && (wasInFocus || forceShowValidation)) {
        color = theme?.palette?.labels.bt_red_reg;
        backgroundColor = theme?.palette?.labels.bt_red_light;
        hoveredBackground = theme?.palette?.labels.bt_red_mid;
        hoveredColor = theme?.palette?.labels.bt_red_hover;
      }
      styles = {
        [`&.${textFieldClasses.root}`]: {
          '.Mui-focused': {
            '.MuiInput-input': {
              border: `1px solid ${theme?.palette?.border?.placeholder}`,
              color: theme?.palette?.text?.txt_default,
              '&:hover': {
                backgroundColor: focusedBackground,
              },
              '&::placeholder': {
                color: theme?.palette?.text?.txt_default,
              },
            },
          },
          '.MuiInput-input': {
            border: '1px solid transparent',
            padding: '4px 8px',
            background: backgroundColor,
            color,
            '&::placeholder': {
              color,
            },
            '&:hover': {
              background: hoveredBackground,
              color: hoveredColor,
            },
          },
        },
      };
      break;
  }

  const defaultStyles = {
    [`&.${textFieldClasses.root}`]: {
      width: '100%',
      '.Mui-focused': {
        input: {
          backgroundColor: theme?.components?.main?.background,
        },
      },
      '.MuiInput-input': {
        fontSize: theme?.fontSize?.big,
        fontFamily: theme?.fonts?.robotoLight,
        borderRadius: theme?.shape?.border?.borderRadius,
        height: '24px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&::placeholder': {
          opacity: 1,
        },
      },
      '.MuiInputBase-root': {
        letterSpacing: '0px',
      },
    },
  };
  return merge(defaultStyles, styles);
});

export const StyledTitleTextField = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'error' && prop !== 'width' && prop !== 'minWidth' && prop !== 'disabled' && prop !== 'maxWidth',
})(({ theme, maxWidth }) => ({
  letterSpacing: '0',
  width: '100%',
  maxWidth,
  '&:not(.MuiInput-root):hover': {
    color: theme?.palette?.text?.txt_default,
  },
}));
