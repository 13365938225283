import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

export const StyledPromptWrapper = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'isFitContent',
})(({ theme, style, isFitContent }) => ({
  ...style,
  '.propmt-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',

    'prompt-icon': {
      marginTop: '8px',
    },
  },

  '.dialog-action-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },
  '.warning-wrapper': {
    display:'flex',
    flexDirection: 'column',
    marginRight: 'auto',  },

  '.conversion-warning-wrapper': {
    display: 'flex',
    alignItems: 'center',

  },
  '.workflow-warning-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },

  '.warning-text': {
    marginLeft: '8px',
    color: theme?.palette?.bt_orange_light?.contrastText,
    fontSize: '0.875rem',
  },
  '.workflow-warning-text': {
    marginLeft: '8px',
    color: theme?.palette?.bt_red_light?.contrastText,
    fontSize: '0.875rem',
  },

  '& .MuiFormControl-root': {
    marginTop: style?.MuiFormControl?.marginTop,
  },

  '& .MuiPaper-root': {
    minWidth: style?.MuiPaper?.minWidth,
    width: isFitContent ? 'fit-content' : 'min(392px, 70%)',
    minHeight: '0',
    padding: '24px',
    borderRadius: style?.MuiPaper?.borderRadius || '4px',
    boxShadow: `0 2px 8px ${theme?.palette?.background?.boxShadow}`,
    backgroundColor: theme?.palette?.generic?.primary,
    overflow: 'hidden',
    maxWidth: isFitContent ? 'fit-content' : '392px',
    justifyContent: 'space-between',
  },

  '&& .MuiBackdrop-root': {
    background: theme?.palette?.background?.cancelNoteModal,
    backdropFilter: 'blur(2px) !important',
  },

  '&& .MuiTypography-root:not(span)': {
    padding: style?.MuiTypography?.padding || '0 !important',
    fontFamily: style?.MuiTypography?.fontFamily || theme?.fonts?.robotoRegular,
    fontSize: theme?.fontSize?.main,
    wordBreak: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme?.palette?.text?.secondary_txt,
    lineHeight: '18px',
  },
  '& .propmt-wrapper h2.MuiDialogTitle-root': {
    color: theme?.palette?.text?.title_txt,
    fontFamily: theme?.fonts?.robotoLight,
    fontWeight: '300',
    lineHeight: '24px',
    fontSize: theme.fontSize.medium,
    ...style?.MuiDialogTitle,
  },
  '&& .MuiDialogContent-root': {
    padding: 0,
    overflowY: 'inherit !important',
  },
  '&& .MuiDialogActions-root': {
    padding: '24px 0 0 0',
  },

  '&& .MuiTextField-root': {
    width: '366px',
    height: style?.MuiTextFieldRoot?.height || '82px',
    backgroundColor: style?.MuiTextFieldRoot?.backgroundColor || theme?.palette?.background.bg_new_input_text,
    borderRadius: '4px',
    marginTop: '24px',
    '& input': {
      color: theme?.palette?.text?.title_txt,
      fontFamily: theme?.fonts?.robotoRegular,
      fontSize: theme?.fontSize?.main,
    },
  },

  '&& .MuiInputBase-root': {
    width: style?.MuiInputBaseRoot?.width,
    fontSize: theme?.fontSize?.main,
    padding: '4px 8px',
  },
  '&& .MuiFormHelperText-root': {
    color: theme?.palette?.text?.secondary_txt,
    fontFamily: theme?.fonts?.robotoRegular,
    position: 'absolute',
    bottom: '0',
    right: '0',
    padding: '4px 8px',
  },

  '&& .MuiDialogContentText-root': {
    width: 'auto',
    margin: style?.MuiDialogContentText?.margin || '8px 0 0',
    fontFamily: theme?.fonts?.robotoRegular,
    fontSize: theme?.fontSize?.main,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme?.palette?.text?.secondary_txt,
    height: 'auto',
    whiteSpace: 'pre-wrap',
    fontWeight: style?.MuiPaper?.fontWeight,
    wordBreak: 'break-word',
  },
  scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
  scrollbarWidth: 'thin',
  '*::-webkit-scrollbar': {
    width: '4px',
  },
  '*::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: theme?.palette?.grey?.divider_grey_v1,
  },
}));
