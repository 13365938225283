export const SR_PANEL_CONSTANTS = {
  TEXT: 1,
  LONG_TEXT: 2,
  NUMBER: 3,
  DATE: 4,
  TIME_REMAINING: 5,
  SELECT: 6,
  MULTI_SELECT: 7,
  ATTACHMENT: 8,
  LINK: 9,
  BOOLEAN: 10,
  DATE_TIME: 11,
  SPECIAL_LINK: 12,
  CLOSED_CLASS: 1,
};

export const DESCRIPTION_FIELD_ID = 5;
export const STATUS_FIELD_ID = 8;
export const PRIORITY_FIELD_ID = 58;
export const RICH_TEXT_FIELD_IDS = [DESCRIPTION_FIELD_ID];
export const DEFAULT_CLOSE_STATUS = {
  value: 'Closed',
  id: 3,
  valueClass: 1,
  idForFilter: '3',
  valueForFilter: 'Closed',
};

export const CUSTOM_FIELD_PREFIX = 'sr_cust_';
export const CUSTOM_TEXT_FIELD_LIMIT = 64;

export const CC_FIELD_NAME = 'cc';

export const SIMPLE_CONTENT_PATTERN = /^<p[^>]*>([\s\S]*?)<\/p>(<p[^>]*>([\s\S]*?)<\/p>)*$/;
/** TODO: Better the editor's behaviour to avoid side effects in the future
* The simple content in our case is the text each row of which is wrapped into <p>.
* Is not mixed with other tags no <div>, <span> or * even inside one-level <p>.
* Images wrapped into <p> also should be excluded from such pattern.
*/
