import { Typography } from '@mui/material';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';

import { StyledFormField } from '../styles';

export default function MeMenuFormField({
  label,
  name,
  type,
  labelProps,
  inputComponent: Input,
  inputProps,
  handleChange,
}) {
  return (
    <StyledFormField className="form-field-wrapper">
      {label && (
        <FieldName
          className="form-field-label"
          name={
            <Typography variant="span" sx={{ paddingBlock: '1px', marginRight: '2px' }}>
              {label}
            </Typography>
          }
          {...labelProps}
        />
      )}
      {Input && (
        <Input className="form-field-input" name={name} type={type} handleChange={handleChange} {...inputProps} />
      )}
    </StyledFormField>
  );
}
