import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum'; // Ensure Datadog RUM is imported
import { getEnvironment } from '../utils';

// Define the structure of window.performance.memory (non-standard in TypeScript)
interface MemoryInfo {
  usedJSHeapSize: number;
  totalJSHeapSize: number;
  jsHeapSizeLimit: number;
}

declare global {
  interface Performance {
    memory?: MemoryInfo;
  }
}

export const useMemoryReporting = (interval: number): void => {
  useEffect(() => {
    const environment: string = getEnvironment();
    // Function to monitor memory usage
    const reportMemoryUsage = () => {      
      const isDataDogAvailable = datadogRum && typeof datadogRum === 'object' && Object.keys(datadogRum).length > 0;

      const logMemoryToDatadog = ({usedJSHeapSize, totalJSHeapSize, jsHeapSizeLimit, url}: MemoryInfo & {url: string}) => {
        datadogRum?.addTiming?.('memory_usage.used_js_heap_size', usedJSHeapSize);
        datadogRum?.addTiming?.('memory_usage.total_js_heap_size', totalJSHeapSize);
        datadogRum?.addTiming?.('memory_usage.js_heap_size_limit', totalJSHeapSize);

        // Trigger RUM User Action to report both used and total JS heap size
        datadogRum?.addAction?.('Memory Usage Report', {
          used_js_heap_size: usedJSHeapSize,
          total_js_heap_size: totalJSHeapSize,
          js_heap_size_limit: jsHeapSizeLimit,
          environment,
          url
        }); 
      };

      // Check if memory is available
      if (window.performance && window.performance.memory) {
        const { usedJSHeapSize, totalJSHeapSize, jsHeapSizeLimit } = window.performance.memory;
        const url= window.location.href;

        if (isDataDogAvailable) {
          logMemoryToDatadog({ usedJSHeapSize, totalJSHeapSize, jsHeapSizeLimit, url });
        } else {
          console.warn('Datadog RUM is not available. Memory usage reporting will not be performed.');
        }
      } else {
        console.warn('window.performance.memory is not supported in this browser.');
      }
    };

    // Set an interval to report memory usage every minute (or the provided interval)
    const intervalId = setInterval(reportMemoryUsage, interval);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [interval]);
}
