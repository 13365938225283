import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterIcon from 'features/queue/grid/customColumnHeader/FilterIcon';
import SortIcon from 'features/queue/grid/customColumnHeader/SortIcon';

export const StyledGrid = styled(Box)(({ theme }) => ({
  height: '80%',
  width: '100%',
  marginBottom: '24px',
  '& .pointer': {
    cursor: 'pointer',
  },

  '.wrapper': {
    height: '100%',
    width: '100%',
    '>div': {
      borderBottom: `1px solid ${theme.palette.labels.bt_black_light}`,
    },
  },
  // table: {
  //   width: '100%',
  //   height: '100%',
  //   tableLayout: 'fixed',
  //   overflow: 'hidden',
  //   borderCollapse: 'collapse',
  //   borderRadius: theme.components.main.borderRadius,
  //   border: `1px solid ${theme.palette.labels.bt_black_light}`,
  // },

  '.table-row': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },

  '.table-body': {
    height: '100%',
    width: '100%',
    '.table-row': {
      borderTop: `1px solid ${theme.palette.labels.bt_black_light}`,
      width: 'calc(100% - 11px)',

      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.generic.primary}`,
      },
    },
  },

  '.header-cell': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    fontFamily: theme.fonts.robotoBold,
    fontSize: theme.fontSize.small,
    padding: '12px 24px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.background.bg_hover_alt,

      '.columnHeaderSortIcon': {
        visibility: 'visible',
      },
      '.columnHeaderFilterIcon': {
        visibility: 'visible',
      },
    },

    '>div': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  },
  '.cell, .header-cell': {
    display: 'flex',
    color: theme.palette.text.secondary_txt,
    textAlign: 'left',
    width: '10%',
  },
  '.cell': {
    fontSize: theme.fontSize.main,
    fontFamily: theme.fonts.robotoRegular,
    backgroundColor: theme.palette.generic.primary,
    alignItems: 'center',
    height: '64px',
    maxHeight: '64px',
    padding: '12px 24px',
  },

  '#templateNameHeader,.templateNameCell': {
    width: '40%',
  },
  '#visibleInSelfServicePortalHeader,.visibleInSelfServicePortalCell': {
    justifyContent: 'center',
    textAlign: 'center',
  },
  '#ActionsHeader,.ActionsCell': {
    minWidth: '140px',
  },

  '.columnHeaderFilterMenu': {
    position: 'absolute',
    zIndex: 1,
    top: '100%',
  },

  '.grid-scroll': {
    borderRadius: `0 0 ${theme.components.main.borderRadius} ${theme.components.main.borderRadius}`,
  },
}));

export const StyledSortIcon = styled(SortIcon, {
  shouldForwardProp: (prop) => prop !== 'isSortActive',
})(({ isSortActive, theme }) => ({
  visibility: isSortActive ? 'visible' : 'hidden',
  position: 'relative',
  height: '16px',
  '& > svg': {
    zIndex: 1,
  },
  '&:hover': {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      bottom: '-2px',
      right: '-2px',
      backgroundColor: theme.palette.blueLabels.bt_light_blue_hover_op,
      zIndex: 0,
      borderRadius: '4px',
    },
  },
}));

export const StyledFilterIcon = styled(FilterIcon, {
  shouldForwardProp: (prop) => prop !== 'isFilterActive',
})(({ isFilterActive, theme }) => ({
  visibility: isFilterActive ? 'visible' : 'hidden',
  '& > svg': {
    zIndex: 1,
  },
  '&:hover': {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      bottom: '-2px',
      right: '-2px',
      backgroundColor: theme.palette.blueLabels.bt_light_blue_hover_op,
      zIndex: 0,
      borderRadius: '4px',
    },
  },
}));
