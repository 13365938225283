import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateListPageHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: '0px 0 20px',
  display: 'flex',
  justifyContent: 'space-between',
  '.list-page-header-left': {
    paddingLeft: '15px',
    paddingTop: '20px',
  },
  '.header-bottom': {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    padding: '4px 0',

    '.header-title': {
      fontSize: theme.fontSize.big,
      color: theme.palette.text.title_txt,
      lineHeight: '24px',
    },
    '.list-size': {
      padding: '2px 8px',
      borderRadius: '16px',
      backgroundColor: theme.palette.grey.divider_grey_v1,
      fontSize: theme.fontSize.main,
      fontFamily: theme.fonts.robotoRegular,
      color: theme.palette.text.txt_default,
      lineHeight: '20px',
      letterSpacing: '0',
    },
  },
  '& .category-driven-templates': {
    '& .category-driven-switch-label': {
      lineHeight: '24px',
    },
    '& .switch-toggle-hint': {
      lineHeight: '16px',
      height: '16px',
    },
  },
  '.list-page-header-right': {
    display: 'flex',
    gap: '14px',
    alignItems: 'flex-end',
    margin: '0px',
    paddingRight: '5px',
    '.SearchField': {
      marginBottom: '0px',
      width: '276px',
    },
  },
}));
