import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.congrats.popup.title'],
    textContent: texts?.['spaces.congrats.popup.text'],
    textButton: texts?.['spaces.congrats.popup.button'],
    link: texts?.['spaces.congrats.popup.link'],
  };

  return componentTexts;
}
