import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogTitle, ThemeProvider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useToasterMessage } from 'common/utils/hooks/useToasterMessage';
import { ReactComponent as SuccessIcon } from 'images/icons/SuccessIcon.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';
import { ReactComponent as CloseIcon } from 'images/icons/icon_close_big.svg';
import { setNavigationObject } from 'store/globalSlice';
import { theme } from './theme';
import { classesStyles } from './style';
import { TICKETS_OPEN_URL } from '../../../constants';

const ToasterMessage = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { toasterMessage, handleClose } = useToasterMessage();
  if (!toasterMessage?.message) return null;
  const message = toasterMessage.message;
  const styleOverrides = toasterMessage.styleOverrides;
  const styleDialogOverrides = toasterMessage.styleDialogOverrides || [];
  const MessageIcon = toasterMessage.type === 'error' ? ErrorIcon : SuccessIcon;
  const onClick = () => {
    if (toasterMessage.onClickFunctionName) {
      switch (toasterMessage.onClickFunctionName) {
        case 'ticketCreatedToastClicked':
          dispatch(setNavigationObject({ to: TICKETS_OPEN_URL.SPACES(toasterMessage?.id) }));
          break;
        default:
          break;
      }
    }
  };
  const clicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) onClick(e);
  };

  return (
    <ThemeProvider theme={theme(styleOverrides)}>
      <Dialog open onClose={handleClose} sx={styleDialogOverrides}>
        <div
          role="button"
          tabIndex={0}
          className={classes.promptDisplay}
          style={{
            cursor: typeof onClick === 'function' ? 'pointer' : undefined,
          }}
          onKeyDown={(e) => clicked(e)}
          onClick={(e) => clicked(e)}
        >
          <div className={classes.icon}>
            <MessageIcon className={classes.svgIcon} />
          </div>
          <div className={classes.title}>
            <DialogTitle data-cy="toaster-message-title">
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </DialogTitle>
          </div>
          <DialogActions>
            <button onClick={handleClose} className={classes.okBtn}>
              <div>
                <CloseIcon />
              </div>
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default withStyles(classesStyles)(ToasterMessage);
