import { BUTTON_THEMES } from 'common/components/buttons/constants';
import ButtonBase from 'common/components/buttons/FormButton';

import useTexts from '../useTexts';

import { StyledFooter } from '../styles';

export default function MeMenuFooter({ disabled, handleSave, handleClose }) {
  const { footerSaveBtnText, footerCancelBtnText } = useTexts();

  return (
    <StyledFooter>
      <ButtonBase data-testid="close-button" text={footerCancelBtnText} btnTheme={BUTTON_THEMES.SECONDARY} onClick={handleClose} />
      <ButtonBase data-testid="save-button" text={footerSaveBtnText} btnTheme={BUTTON_THEMES.PRIMARY} disabled={disabled} onClick={handleSave} />
    </StyledFooter>
  );
}
