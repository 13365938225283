import { styled } from '@mui/material/styles';
import { Box, Chip, Menu, Stack } from '@mui/material';

export const StyledFilterRightPanel = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}));

export const StyledGridFilters = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '10px',
  paddingRight: '0px',
  borderRadius: '10px 10px 0 0',
  backgroundColor: theme.palette.grey.bt_alt_grey_light,
  '#chip_srType': {
    textTransform: 'capitalize',
  },
}));

export const StyledStack = styled(Stack)(() => ({
  '&&': {
    justifyContent: 'flex-start',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
}));

export const StyleFilterIconContiner = styled(Box)(() => ({
  margin: '10px',
  width: '16px',
  height: '16px',
  objectFit: 'contain',
}));
export const StyledChipFilter = styled('span')(({ theme }) => ({
  color: theme.palette.grey.chip_grey,
  margin: '0 1px',
}));
export const StyledChipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'setWidth',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  overflowX: 'hidden',
}));

export const StyledChip = styled(Chip)(({ theme, disableCursor, readOnly, isWorkFlowMultiList }) => ({
  '&&': {
    cursor: 'pointer',
    height: 'fit-content',
    maxWidth: '200px',
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 6px',
    borderRadius: '2px',
    border: 'none',
    backgroundColor: theme.palette.grey.bt_alt_grey_hover,
    position: 'relative',

    '&.multi-select-chip': {
      maxWidth: '13vw',
      fontSize: theme?.fontSize?.main,
      color: theme.palette.text.txt_default,
      height: '24px',
    },
    '&.queue-multi-select-visible-chip': {
      maxWidth: '120px',
      fontSize: theme?.fontSize?.main,
      height: '24px',
    },
  },

  '&& .MuiChip-label': {
    flexGrow: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.palette.text.title_txt,
    padding: 0,
  },

  '&& .MuiChip-deleteIcon': {
    cursor: (disableCursor || readOnly) && isWorkFlowMultiList ?  'not-allowed': 'pointer',
    width: '16px',
    height: '16px',
    flexGrow: 0,
    objectFit: 'contain',
    margin: 0,
    marginLeft: '8px',
    overflow: 'visible',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.grey.divider_grey_v3,
    },
  },
}));

export const StyledChipDropDown = styled(StyledChip)(({ theme }) => ({
  '&&': {
    maxWidth: '55px',
    height: 'fit-content',
    padding: '3px 8px',
    borderRadius: '2px',
    backgroundColor: theme.palette.grey.bt_alt_grey_hover,
    fontSize: '0.875rem',
    lineHeight: 1.2858,
  },
}));

export const StyledDropDown = styled(Box)(({ theme }) => ({
  padding: '12px',
  borderRadius: '10px',
  boxShadow: `0 0 8px 0 ${theme.palette.background.boxShadow}`,
  backgroundColor: theme.palette.generic.primary,
  zIndex: 10,
  position: 'absolute',
  // right: 0,
  top: '35px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  maxWidth: '224px',
}));

export const StyledDropDownItems = styled(StyledChip)(() => ({
  '&&': {
    justifyContent: 'space-between',
  },
}));

export const StyledClearAll = styled(Box)(({ theme }) => ({
  // fontFamily: theme.fonts.robotoLight,
  fontSize: '0.75rem',
  lineHeight: 1.667,
  textAlign: 'center',
  color: theme.palette.generic.sysaid,
  cursor: 'pointer',
  minWidth: '40px',
}));

export const StyledDropdownContainer = styled(Box)(() => ({}));

export const StyledAssignessFilter = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '20px 1fr 20px',
  gap: '6px',
  cursor: 'pointer',
  height: '32px',
  padding: '8px 12px',
  minWidth: '100px',
  maxWidth: '270px',
  alignItems: 'center',
  justifyContent: 'space-around',
  backgroundColor: theme.palette.generic.primary,
  borderRadius: theme.shape.border.borderRadius,
  color: theme.palette.text.title_txt,
  '&:hover': {
    backgroundColor: 'rgba(235, 237, 245, 1)',
  },

  '.MuiTypography-root': {
    fontSize: '14px',
  },
}));

export const StyledAssigneesText = styled(Box)(() => ({
  fontSize: '14px',
  marginLeft: '8px',
  marginRight: '12px',
  maxWidth: '100px',
  height: '100%',
  textOverflow: 'ellipsis',
}));
export const StyledDropDownModal = styled(Menu)(({ theme }) => ({
  '.MuiList-root': {
    padding: '0px',
    '.MuiButtonBase-root .MuiBox-root': {
      '.MuiTypography-root': {
        fontSize: '12px',
        fontFamily: theme.fonts.robotoBold,
      },
    },
    '& > div': {
      width: '300px',
    },
  },
  '& .line': {
    height: '1px',
    margin: '8px 16px',
    borderRadius: '1px',
    backgroundColor: theme.palette.grey.divider_grey_v1,
  },
}));
export const StatusListWrapper = styled(Box)(({ theme }) => ({
  height: '224px',
  width: '223px',
  marginInline: '4px',
  overflowY: 'scroll',
  overflowx: 'hidden',
  ...theme.customScrollbar,
  '.status': {
    margin: '0 8px',
  },
  '.MuiBox-root': {
    marginInline: '4px',
  },
  '.MuiButtonBase-root .MuiBox-root': {
    height: '24px',
  },
  '.rotate': {
    transform: 'rotate(180deg)',
  },
}));
export const StyledStatusButton = styled(Box)(({ theme, isOpen }) => ({
  background: !isOpen ? theme?.palette?.blueLabels?.bt_status_reg : 'rgba(0, 0, 0, 0.6)',
  maxWidth: '115px',
  padding: '4px 12px',
  color: theme.palette.generic.primary,
  borderRadius: theme.shape.border.borderRadius,
}));

export const StyledMultiSelectActions = styled(Box)(({ theme }) => ({
  fontSize: '12px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.67,
  letterSpacing: 'normal',
  textAlign: 'left',
  button: {
    all: 'unset',
    marginLeft: '16px',
    cursor: 'pointer',
  },
  '.activeButton': {
    color: theme?.palette?.generic.sysaid,
  },
  '.inactiveButton': {
    color: theme?.palette?.text?.secondary,
    cursor: 'default',
  },
}));
