import { CONSTANTS } from '../constants';

import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/file.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/char_counter.min';

const RICH_TEXT_EDITOR_TYPES = {
  TEXT: 1,
  READ_ONLY: 2,
  EMAIL: 3,
  SMS: 4,
  SIGNATURE: 5,
  TABLE: 6,
  TEMPLATE: 7,
};

const DEFAULT_CONFIG = {
  scrollableContainer: '.richtext-wrap',
  toolbarSticky: false,
  charCounterCount: true,
  defaultCharCounterMax: CONSTANTS.INPUT_CHARACTERS_LIMIT,
  fontFamilySelection: true,
  fontSizeSelection: true,
  toolbarBottom: true,
  paragraphFormatSelection: true,
  linkInsertButtons: [],
  quickInsertEnabled: false,
  listAdvancedTypes: false,
  imageUpload: true,
  imageDefaultAlign: 'left',
  imageDefaultDisplay: 'inline-block',
  imageMaxSize: 5 * 100 * 100,
  imageDefaultWidth: 120,
  imageTypes: 'image/*',
  fontSize: ['12', '14', '18', '24', '36'],
  linkList: [],
  linkAlwaysBlank: true,
  linkEditButtons: [],
  imageEditButtons: ['imageInfo', 'imageRemove', 'imageDownload'],
  directionToolPopupButtons: ['rightToLeft', 'leftToRight'],
  responsiveToolbarPopupButtons: [
    'alignRight',
    'alignCenter',
    'alignLeft',
    'rightToLeft',
    'leftToRight',
    'formatOL',
    'formatUL',
    'highlight',
  ],
  colorsStep: 7,
  colorsText: [
    '#000000',
    '#012F7B',
    '#008DA4',
    '#006645',
    '#D38301',
    '#B51A00',
    '#2C0977',
    '#ADADAD',
    '#74A7FF',
    '#00B8D7',
    '#669D34',
    '#FECB3E',
    '#FE6250',
    '#6455BD',
  ],
  pluginsEnabled: [
    'align',
    'link',
    'lists',
    'file',
    'image',
    'url',
    'colors',
    'inlineClass',
    'inlineStyle',
    'fontSize',
    'charCounter',
    'directionTool',
    'responsiveToolbar',
    'tribute',
  ],
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'fontSize',
    'textColor',
    'highlight',
    'formatOL',
    'formatUL',
    'align',
    'directionTool',
  ],
  toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontSize', 'textColor'],
};

export { DEFAULT_CONFIG, RICH_TEXT_EDITOR_TYPES };
