import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCongratsPopup = styled(Box)(({ theme }) => ({
    '.congrats-popup-container': {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 500,
        width: '100vw',
        height: '100vh',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.35)',
        backdropFilter: 'blur(2px)',
    },
    '.congrats-popup': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px',
        gap: '24px',
        position: 'absolute',
        width: '538px',
        height: '233px',
        top: '25%',
        background: theme.palette.view.main,
        boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
        borderRadius: '4px',
    },
    '.congrats-popup-content': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'start',
        padding: '0px',
        gap: '24px',
        width: '490px',
        height: '131px',
    },
    '.text-container': {
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
        paddingTop: '15px',
    },
    '.congrats-title': {
        color: theme.palette.generic.sysaid,
        flex: 'none',
        order: 0,
        flexGrow: 0,
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: 1.5,
        display: 'flex',
        alignItems: 'center',
    },
    '.congrats-text': {
        display: 'inline-block',
        flexDirection: 'column',
        alignItems: 'flex-start',
        color: theme.palette.text.secondary_txt,
        padding: '0',
        gap: '12px',
        width: 'auto',
        height: '36px',
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 1.333,
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
    },
    '.congrats-link': {
        color: 'blue',
    },
    '.congrats-popup-btn': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px 16px',
        gap: '10px',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        background: theme.palette.icons.field_used,
        border: `1px solid ${theme.palette.icons.field_used}`,
        borderRadius: '60px',
        color: theme.palette.generic.sysaid_default_hover,
    },
}));
