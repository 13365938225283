import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';
import { StyledSwitch, StyledShareSwitch, StyledSwitchLabel } from 'common/components/controls/Switch/style';
import Hint from 'features/templateBuilder/TemplateSection/HintIcon';

export const SwitchToggle = (props) => {
  const {
    switchId,
    handleSwitchChange,
    isChecked,
    isDisabled,
    switchText,
    tooltipText,
    classNames,
    editorClassName = 'editor-element',
    hintText,
    dataTestId = '',
  } = props;

  const handleChange = (event) => {
    handleSwitchChange(event);
  };

  const renderSwitch = () => (
    <StyledShareSwitch className={editorClassName} data-testid={dataTestId} data-cy={dataTestId}>
      <StyledSwitch
        disabled={isDisabled}
        checked={isChecked || false}
        onChange={handleChange}
        value={isChecked}
        id={switchId}
        inputProps={{ 'data-testid': `${dataTestId}-input-switch`, 'data-cy': `${dataTestId}-input-switch` }}
      />
      <StyledSwitchLabel className={classNames?.labelClass}>{switchText}</StyledSwitchLabel>
      {hintText && <Hint className="switch-toggle-hint" hintText={hintText} />}
    </StyledShareSwitch>
  );
  return (
    <>
      {tooltipText ? (
        <StyledTooltip placement="top" title={tooltipText} arrow>
          {renderSwitch()}
        </StyledTooltip>
      ) : (
        renderSwitch()
      )}
    </>
  );
};
