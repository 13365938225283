export const PENDO_TRACK_EVENTS = {
  REPHRASE: 'spacesAI_rephrase',
  TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED: 'spacesAI_ticket_summary_and_sentiment_displayed',
  TICKET_SUMMARY_ERROR: 'spacesAI_ticket_summary_error',
  SUGGESTED_CATEGORY_CHECKED: 'spacesAI_suggested_category_checked',
  SUGGESTED_CATEGORY_SELECTED: 'spacesAI_suggested_category_selected',
  CUSTOM_COLUMN_ADD_TO_TEMPLATE: 'CUSTOM_COLUMN_ADD_TO_TEMPLATE',
  CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE: 'CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE',
  CUSTOM_COLUMN_POPULATED: 'CUSTOM_COLUMN_POPULATED',
  ACTIVITIES_CLICKED: 'ACTIVITIES_CLICKED',
  ACTIVITY_SUBMITTED: 'ACTIVITY_SUBMITTED',
  ACTIVITY_DELETED: 'ACTIVITY_DELETED',
  TICKET_MISSING_REQUIRED_FIELDS: 'TICKET_MISSING_REQUIRED_FIELDS',
  SWITCH_TO_CLASSIC_FEEDBACK: 'SWITCH_TO_CLASSIC_FEEDBACK',
  ADD_NEW_COLUMN: 'ADD_NEW_COLUMN',
  DELETE_COLUMN: 'DELETE_COLUMN',
  EDITE_COLUMN_FILDS: 'EDITE_COLUMN_FILDS', // check if needed
  OPEN_IN_CLASSIC_WAS_CLICKED: 'OPEN_IN_CLASSIC_WAS_CLICKED',
  OPEN_SR_FROM_QUEUE: 'Open_SR_from_queue',
  DELETE_TICKT_WAS_CLICKED: 'DELETE_TICKT_WAS_CLICKED',
  SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED: 'SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED',
  DOPLICATE_TEMPLATE_WAS_CLICKED: 'DOPLICATE_TEMPLATE_WAS_CLICKED',
  DELETE_TEMPLATE_WAS_CLICKED: 'DELETE_TEMPLATE_WAS_CLICKED',
  POPUP_LINK_CLIKED: 'POPUP_LINK_CLIKED',
  POPUP_BUTTON_CLICKED: 'POPUP_BUTTON_CLICKED',
  open_classic_queue_click: 'open_classic_queue_click',
  open_in_classic_from_queue_click: 'open_in_classic_from_queue_click',
  open_in_classic_from_ticket_click: 'open_in_classic_from_ticket_click',
  RESOURCE_CENTER_MENU_CLICK: 'Resource_center_menu_click',
  convert_to_request_queue: 'Convert_to_request_queue',
  convert_to_request_ticket_page: 'Convert_to_request_ticket_page',
  convert_to_incident_queue: 'Convert_to_incident_queue',
  convert_to_incident_ticket_page: 'Convert_to_incident_ticket_page',
  go_back_button_conversion_popup_convert_to_request: 'Go_back_button_conversion_popup_convert_to_request',
  convert_button_conversion_popup_convert_to_request: 'Convert_button_conversion_popup_convert_to_request',
  go_back_button_conversion_popup_convert_to_incident: 'Go_back_button_conversion_popup_convert_to_incident',
  convert_button_conversion_popup_convert_to_incident: 'Convert_button_conversion_popup_convert_to_incident',
  conversion_succeeded: 'Conversion succeeded',
  conversion_failed: 'Conversion failed',
  EXPAND_ALL_JOURNEY_TOGGLE_ON: 'Expand_all_journey_toggle_on',
  EXPAND_ALL_JOURNEY_TOGGLE_OFF: 'Expand_all_journey_toggle_off',
  SHOW_MORE_JOURNEY_MESSAGES: 'Show_more_journey_messages',
  SHOW_MORE_JOURNEY_NOTES: 'Show_more_journey_notes',
  Populated_sr_cancel_click: 'Populated_sr_cancel_click',
  Populated_sr_create_click: 'Populated_sr_create_click',
  New_sr_i_prefer_manual_labor_click: 'New_sr_i_prefer_manual_labor_click',
  New_sr_auto_fill_for_me_click: 'New_sr_auto_fill_for_me_click',
  Warning_popup_abort_mission_click: 'Warning_popup_abort_mission_click',
  Warning_popup_auto_fill_for_me_click: 'Warning_popup_auto_fill_for_me_click',
};
